import { openDB, IDBPDatabase } from 'idb';

const DBVersion = 1;
const DBPermission = 'readwrite';

class PWAIndexedDBController {
    private dbMap: Record<string, IDBPDatabase | undefined> = {};

    constructor() {
        this.dbMap = {};
    }

    async getDbByName({ dbName, storeName }: { dbName: string; storeName: string }) {
        try {
            if (!this.dbMap[dbName]) {
                const db = await openDB(dbName, DBVersion, {
                    upgrade: (upgradeDb, oldVersion) => {
                        try {
                            // eslint-disable-next-line default-case
                            switch (oldVersion) {
                                case 0:
                                    upgradeDb.createObjectStore(storeName);
                            }
                        } catch {
                            // eslint-disable-next-line no-console
                            console.log('db upgrade error.');
                        }
                    },
                });
                this.dbMap[dbName] = db;
            }
            return this.dbMap[dbName];
        } catch {
            return undefined;
        }
    }

    /** Assigns or overwrites the record for the given key with the given value. */
    async dbSet<T extends Record<string, any>>({
        storeKey,
        dbName,
        storeName,
        value,
    }: {
        storeKey: string;
        dbName: string;
        storeName: string;
        value: T;
    }) {
        try {
            const db = await this.getDbByName({ dbName, storeName });
            if (!db) {
                return;
            }
            const tx = db.transaction(storeName, DBPermission);
            await tx.objectStore(storeName).put(value, storeKey);
            await tx.done;
            return value;
        } catch {
            return undefined;
        }
    }

    /** Gets record(s) from the objectStore that match the given key. */
    async dbGet<T extends Record<string, any>>({ storeKey, dbName, storeName }: { storeKey: string; dbName: string; storeName: string }) {
        try {
            const db = await this.getDbByName({ dbName, storeName });
            if (!db) {
                return undefined;
            }

            const tokenDetails = await db.transaction(storeName, DBPermission).objectStore(storeName).get(storeKey);
            if (tokenDetails) {
                return tokenDetails as T;
            }

            return undefined;
        } catch {
            return undefined;
        }
    }

    async dbClose({ dbName }: { dbName: string }) {
        try {
            const db = this.dbMap[dbName];

            if (db) {
                await db.close?.();
                this.dbMap[dbName] = undefined;
            }
        } catch {
            // eslint-disable-next-line no-console
            console.log('db close error.');
        }
    }
}

export const DBControllerInstance = new PWAIndexedDBController();
