
import List from 'vant/lib/list';
import { defineComponent, getCurrentInstance, onMounted, ref, PropType, computed } from 'vue';
import Card from '../card/index.vue'
import { formatUrls, checkSnack } from '@/common/utils'

export default defineComponent({
  components: {
    vanList: List,
    Card,
  },
  props: {
    feeds: {
      type: Array as PropType<Record<string, any>>,
      default: () => [],
    },
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as { proxy: any };
    const list = ref([])
    const { isSnack } = checkSnack()

    const loadingEnd = computed(() => {
      return proxy.$root.$t('k_344289').replace('${0}', isSnack ? 'Snack' : 'Kwai')
    })

    const loadingProps = computed({
      get: () => props.loading,
      set: (value: boolean) => {
        return ctx.emit('update:loading', value)
      }
    })


    const onLoad = () => {
      ctx.emit('onLoad')
    }

    return {
      proxy,
      list,
      loadingEnd,
      loadingProps,
      formatUrls,
      onLoad,
    };
  },

});

