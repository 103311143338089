
import { defineComponent, SetupContext, getCurrentInstance, watch, onMounted, ref, nextTick } from 'vue';
import { isLoadMore, repalacePath, FeedType } from '@/common/utils';
import Load from '../content/loading.vue';
import ImageCategoryCard from '../image-category-card/index.vue';

export default defineComponent({
    components: {
        Load,
        ImageCategoryCard,
    },
    props: {
        feeds: {
            type: Array,
            default: () => [],
        },
        isSearching: {
            type: Boolean,
            default: () => false,
        },
        slideIndex: {
            type: Number,
            default: 0,
        },
        playIndex: {
            type: Number,
            default: 0,
        },
    },
    setup(props, ctx: SetupContext) {
        const fmp_time = Date.now();
        const { proxy } = getCurrentInstance() as any;

        const videoList = ref<any>(props.feeds);
        const playVideo = ref<any>(props.playIndex);

        const videoRefs = ref<any>([]);

        const getVideoPlayer = (index: number) => {
            const videoComp = videoRefs.value[index] as any;
            return videoComp;
        };

        const setVideoPlayerEnter = () => {
            nextTick(() => {
                const newPlayer = getVideoPlayer(playVideo.value);
                newPlayer?.onEnter();
            });
        };

        const getData = async () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('refresh');
        };

        const handleVideoRef = (e: any, index: number) => {
            videoRefs.value[index] = e;
        };

        const getComponentType = (feed: any) => {
            // 如果FeedType === 图文大卡，动态化使用image-category-card组件，否则使用video-card组件
            return feed.type === FeedType.PICTURE_CATEGORY_CARD ? 'image-category-card' : 'video-card';
        };

        // const timeout = ref<any>(null)
        // const handleScroll = (e: Event) => {
        //     if(timeout.value) clearTimeout(timeout.value);
        //     timeout.value = setTimeout(() => {
        //         const content:HTMLElement = document.getElementById('search-content') as any;
        //         const index  = Math.round(content?.scrollTop/794)
        //         if(index === playVideo.value) return;
        //         getVideoPlayer(playVideo.value)?.onLeave()
        //         playVideo.value = index
        //         setVideoPlayerEnter()
        //     }, 300)
        // };

        const handleVideoClick = (number: number) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('videoClick', videoList.value[number], number);
        };

        const handleVideoActionClick = (type: string, number: number) => {
            if (type === 'comment') {
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('videoClick', videoList.value[number], number);
            }
        };

        const toAssignLocation = (number: number) => {
            const element = document.getElementById('search-content') as any;
            const child = document.getElementById(videoList.value[number]?.photo_id_str);
            console.log(element, child);
            element?.scrollTo(0, (child?.offsetTop as number) - 84 - (element.clientHeight - (child?.clientHeight as number)) / 2);
        };

        watch(
            () => props.feeds,
            newValue => {
                videoList.value = props.feeds;
            },
            { deep: true, immediate: true }
        );

        watch(
            () => props.slideIndex,
            newValue => {
                toAssignLocation(newValue);
            },
            { deep: true }
        );

        watch(
            () => props.playIndex,
            newValue => {
                if (newValue === playVideo.value) return;
                getVideoPlayer(playVideo.value)?.onLeave();
                playVideo.value = newValue;
                setVideoPlayerEnter();
            },
            { deep: true }
        );

        onMounted(() => {
            isLoadMore('search-content', getData);
        });

        return {
            // handleScroll,
            videoList,
            playVideo,
            getData,
            handleVideoClick,
            handleVideoActionClick,
            videoRefs,
            handleVideoRef,
            getComponentType,
        };
    },
});
