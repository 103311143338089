/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { AxiosInstance } from 'axios';

declare module 'vue/types/vue' {
    interface NuxtAxiosInstance {
        $$post: <T extends { result?: number }>(...args: Parameters<AxiosInstance['post']>) => Promise<T>;
        $$get: <T extends { result?: number }>(...args: Parameters<AxiosInstance['get']>) => Promise<T>;
    }
}

export default async (context: Context, inject: Inject) => {
    globalThis && ((globalThis as any).__PWA_NUXT_CTX__ = context);
};
