
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import { formateNum, copyToClipboard } from '@/common/utils'
import { sendLog } from '~/common/radar';

export default defineComponent({
  components: {
  },
  props: {
    head: {
        type: String,
        default: 'https://cdn-static.kwai.net/kos/s101/nlav11312/incentive/default-avatar.png'
    },
    likeCount: {
        type: Number,
        default: 0
    },
    commentCount: {
        type: Number,
        default: 0
    },
    shareCount: {
        type: Number,
        default: 0
    },
    poster: {
        type: String,
        default: ''
    },
    photoId: {
        type: String,
        default: ''
    },
    authorId: {
        type: String,
        default: ''
    },
    index: {
        type: Number,
        default: 0
    },
    isEnd: {
        type: Boolean,
        default: false
    },
    isSlideDisabled: {
        type: Boolean,
        default: false
    },
    isCommentDisabled: {
        type: Boolean,
        default: false
    },
    isDownloadEnabled: {
        type: Boolean,
        default: false
    },
    isShareEmbedDisabled: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        default: () => {}
    },
    type: {
        type: String,
        default: 'normal'
    }
  },
  setup(props, ctx: SetupContext) {
    const { proxy } = getCurrentInstance() as any;
    const showShare = ref(false);
    const timeoutOver = ref();
    const timeoutOut = ref();
    const isClickShare = ref(false);

    const handleShare = () => {
        showShare.value = !showShare.value;
        if(showShare.value) isClickShare.value = true
        else isClickShare.value = false
        clearTimeout(timeoutOut.value);
        sendLog({
            type: 'click',
            name: 'RIGHT_AREA',
            value: {
                ...(props.type === 'picture' && { picture_id: props.photoId, author_id: props.authorId }),
                button_type: 'share'
            }
        })
    };

    const handleDownload = () => {
        ctx.emit('download')
        sendLog({
            type: 'click',
            name: 'RIGHT_AREA',
            value: {
                ...(props.type === 'picture' && { picture_id: props.photoId, author_id: props.authorId }),
                button_type: 'download'
            }
        })
    };

    const handleClick = (type: string) => {
        if(type=== 'photo') ctx.emit('profile')
        else ctx.emit('showModal');
        // ctx.emit('showModal');
        sendLog({
            type: 'click',
            name: 'RIGHT_AREA',
            value: {
                ...(props.type === 'picture' && { picture_id: props.photoId, author_id: props.authorId }),
                button_type: type
            }
        })
    }

    const clickComments=()=>{
        ctx.emit('comment')
        sendLog({
            type: 'click',
            name: 'RIGHT_AREA',
            value: {
                ...(props.type === 'picture' && { picture_id: props.photoId, author_id: props.authorId }),
                button_type: 'comment'
            }
        })
    }

    const handleSlide = (direction: string) => {
        ctx.emit('slide', direction)
        sendLog({
            type: 'click',
            name: 'RIGHT_AREA',
            value: {
                ...(props.type === 'picture' && { picture_id: props.photoId, author_id: props.authorId }),
                button_type: direction === 'next' ? 'next' : 'last'
            }
        })
    }

    const onMouseOver = () => {
      clearTimeout(timeoutOut.value);
      if(showShare.value) return;
      timeoutOver.value = setTimeout(() => {
          showShare.value = true
      }, 3000)
    }

    const onMouseOut = () => {
      clearTimeout(timeoutOver.value);
      if(!showShare.value || isClickShare.value) return;
      timeoutOut.value = setTimeout(() => {
          if(isClickShare.value) return;
          showShare.value = false
      }, 3000)
    }

    return {
        formateNum,
        handleShare,
        handleDownload,
        showShare,
        clickComments,
        handleClick,
        handleSlide,
        onMouseOver,
        onMouseOut,
        isClickShare,
    }
  },
})
