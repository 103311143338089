import Listener from './listener';
import { LogData } from '@/common/radar';

export default class Show {
    listenerQueue: Listener[] = [];

    options!: {throttleTime: number; primaryKey: string | undefined};

    constructor({ throttleTime, primaryKey }: {throttleTime?: number; primaryKey?: string} = {}) {
        this.options = {
            throttleTime: throttleTime || 200,
            // 主键, 方便去重
            primaryKey,
        };
    }

    add(el: HTMLElement, value: LogData) {
        const curItem = this.listenerQueue.find(item => {
            return item.el === el;
        });

        if (curItem) {
            curItem.update(el, value);
            return;
        }

        const newListener = new Listener({
            el,
            value,
        });

        this.listenerQueue.push(newListener);
    }

    update(el: HTMLElement, value: LogData, oldValue: LogData) {
        if (!this.listenerQueue.length) {
            return;
        }
        // @ts-ignore
        if (this.options.primaryKey && value[this.options.primaryKey] === oldValue[this.options.primaryKey]) {
            return;
        }

        const curItem = this.listenerQueue.find(item => {
            return item.el === el;
        });

        if (curItem) {
            curItem.update(el, value);
        }
    }

    remove(el: HTMLElement) {
        let curItemIndex = -1;
        for (let i = 0; i < this.listenerQueue.length; i++) {
            if (this.listenerQueue[i].el === el) {
                curItemIndex = i;
                break;
            }
        }
        if (curItemIndex > -1) {
            const spliceArr = this.listenerQueue.splice(curItemIndex, 1);
            spliceArr[0].destroy();
        }
    }
}
