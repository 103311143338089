var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-video-player',{ref:"videoComp",staticClass:"container",attrs:{"id":`video-${_vm.index}`,"ref-key":`${_vm.index}`,"src":_vm.item?.main_mv_urls[0]?.url,"webkit-playsinline":"true","preload":"auto","poster":_vm.formatUrls(_vm.item?.cover_first_frame_urls?.[0].url || _vm.item?.cover_thumbnail_urls?.[0].url),"plays-inline":"","index":_vm.index,"loop":_vm.loop,"photo-id":_vm.item.photo_id_str,"autoplay":_vm.autoplay,"volume":_vm.volume,"stat-logger-params":{
        list_id: _vm.item?.list_id,
        user_id: _vm.item?.user_id,
        exp_tag: _vm.item?.exp_tag,
        show_index: 5001 + _vm.index,
    },"plugins":_vm.plugins,"controls":false,"muted":_vm.muted,"biz-data":{
        ..._vm.newBizData,
        video_index: _vm.index,
    },"manifest265":_vm.manifest265,"manifest264":_vm.manifest264,"is265":_vm.is265,"is264":_vm.is264},on:{"click":_vm.onClickVideo,"waiting":_vm.onWaiting,"playing":_vm.onPlaying,"error":_vm.onError,"ended":_vm.onEnded,"pause":_vm.onPause,"loadeddata":_vm.onloadeddata,"canplay":_vm.onCanPlay}})
}
var staticRenderFns = []

export { render, staticRenderFns }