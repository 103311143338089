
import { defineComponent, getCurrentInstance } from 'vue';

export default defineComponent({
    props: {
        page: {
            type: String,
            default: 'message',
        },
        desc: {
            type: String,
            default: '',
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };

        return {};
    },
});
