import { render, staticRenderFns } from "./_content.vue?vue&type=template&id=550620ac&scoped=true"
import script from "./_content.vue?vue&type=script&lang=ts"
export * from "./_content.vue?vue&type=script&lang=ts"
import style0 from "./_content.vue?vue&type=style&index=0&id=550620ac&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550620ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoCard: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/VideoCard/index.vue').default,VideoSwiper: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/VideoSwiper/index.vue').default})
