import { DirectiveOptions } from 'vue';
import ShowClass from './show';
import { sendLog , LogData } from '@/common/radar';

const show = new ShowClass({
    primaryKey: 'show_id',
});

function clickLog(el: HTMLElement, value: LogData) {
    sendLog(value);
}

const bindTypeMap:any = {
    pv(el: HTMLElement, value: LogData) {
        sendLog(value);
    },
    show(el: HTMLElement, value: LogData) {
        show.add.bind(show)(el, value);
    },
    click(el: HTMLElement, value: LogData) {
        el.onclick = function () {
            clickLog(el, value);
        };
    },
};

const updateTypeMap: {[key: string]: (el: HTMLElement, value: LogData, oldValue: LogData) => void} = {
    show(el: HTMLElement, value: LogData, oldValue: LogData) {
        show.update.bind(show)(el, value, oldValue);
    },
    click(el: HTMLElement, value: LogData) {
        el.onclick = function () {
            clickLog(el, value);
        };
    },
};

const unbindTypeMap: {[key: string]: (el: HTMLElement) => void} = {
    show(el: HTMLElement) {
        show.remove.bind(show)(el);
    },
    click(el: HTMLElement) {
        el.onclick = null;
    },
};

const options = {
    bind(el: HTMLElement, { value }: {value: LogData}) {
        const type = value.type;
        if (type !== 'custom' && bindTypeMap[type]) {
            bindTypeMap[type](el, value);
        }
    },
    update(el: HTMLElement, { value, oldValue }: {value: LogData, oldValue: LogData}) {
        updateTypeMap[value.type](el, value, oldValue);
    },
    unbind(el: HTMLElement, { value }: {value: LogData}) {
        const type = value.type;

        if (unbindTypeMap[type]) {
            unbindTypeMap[type](el);
        }
    },
};

export default options as DirectiveOptions;
