
import { defineComponent, ref, getCurrentInstance, onMounted, watch } from 'vue'
import { formateNum, formateTime, openNewWindow } from '@/common/utils'
import { sendLog, immediateSendLog, getKwaiLog } from '@/common/radar';
// import VideoPlayer from './video.vue'

export default defineComponent({
  name: 'search-content',
  components: {
    // VideoPlayer,
  },
  props: {
    feeds: {
      type: Array,
      default: () => []
    },
    startIndex: {
      type: Number,
      default: 0
    },
    playIndex: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: () => ''
    },
    desc1: {
      type: String,
      default: ''
    },
    desc2: {
      type: String,
      default: ''
    },
    videoNum: {
      type: Number,
      default: 14
    },
  },
  setup(props, ctx) {

    const { proxy } = getCurrentInstance() as { proxy: any }
    const playVideo = ref(0)
    const feedsInfo = ref<any>(props.feeds)
    const viewSumCount = ref(0)
    const loadimg = ref<any>([])
    const videoRefs = ref<any>([])
    const muted = ref(true)

    const mouseenter = (index: number) => {
      playVideo.value = index
      ctx.emit('videoMouseenter', index)
    }

    const mouseleave = (index: number) => {
    }

    const onEnded = () => {
      ctx.emit('ended')
    }

    const videoClick = (item: any, index: number) => {
      sendLog({
        type: 'click',
        name: 'PHOTO_COVER',
        value: {
          photo_id: item.photo_id_str,
          index,
          author_id: item.user_id
        }
      })
      // proxy.$store.commit('setSearchValue', '');
      // proxy.$router.push({
      //     path: `/@${item.kwai_id}/video/${item.photo_id_str}`,
      //     query: {
      //         ...proxy.$route.query,
      //         page_source: 'search'
      //     }
      // });
      ctx.emit('videoClick', item, index);
    }

    const handleImageOnload = (index: number) => {
      loadimg.value.push(index)
    }

    const userClick = (kwaiID: string) => {
      openNewWindow(`/@${kwaiID}?page_source=discover`)
      // proxy.$router.push({
      //     path: `/@${kwaiID}`,
      //     query: {
      //         ...proxy.$route.query,
      //         page_source: 'search'
      //     }
      // });
    }

    const handleMuted = () => {
      muted.value = !muted.value
    }

    const openTag = (name: any, photo_id: string) => {
      openNewWindow(`/discover/${name}?page_source=profile`)
    }

    watch(() => props.feeds, (newValue) => {
      feedsInfo.value = newValue;
    }, { deep: true, immediate: true })


    return {
      // item,
      mouseenter,
      mouseleave,
      onEnded,
      playVideo,
      feedsInfo,
      videoClick,
      userClick,
      viewSumCount,
      formateNum,
      formateTime,
      handleImageOnload,
      loadimg,
      videoRefs,
      handleMuted,
      muted,
      openTag
    }
  }
})
