
import Vue, { defineComponent } from "vue";
export default defineComponent({
  name: "hotSvg",
  components: {},
  props: {
    color: {
      type: String,
      default: "red",
    },
  },
  mounted() {
    console.log("props", this.color);
  },
});
