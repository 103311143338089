
import { defineComponent, getCurrentInstance, ref } from 'vue';
import { startIdentityVerification } from '@ks/identity-verification';
import { kwaiOrSnack } from '@/common/utils';
import { PhoneApiService } from '~/api/phone';
import { UserInfo, UserApiService } from '~/api/user';

// 相关定义详见：https://docs.corp.kuaishou.com/d/home/fcACNeLEWZ_DtrvpkVnAL38Wa
enum Platform {
    OUTSIDE_ANDROID_H5 = 8,
    OUTSIDE_IOS_H5 = 9,
    PC_WEB = 10,
}

enum BusinessType {
    // 直播对外工会业务
    WEB_LIVE_GUILD = 0,
    // 第三方充值
    WEB_THIRD_PARTY_RECHARGE = 1,
    WEB_STUDIO_LOGIN = 2,
    WEB_PWA = 3,
}

interface ErrorCodeMap {
    [key: number]: string;
}

const ERROR_MAP: ErrorCodeMap = {
    2: 'k_1259741', // 操作频繁
    11: 'k_1259742', // 发送失败
    110: 'k_1259743', // 用户不存在
    127: 'k_1259744', // 验证码无效
    505: 'k_1259911', // 手机号无效
    506: 'k_1259745', // 手机号未注册
    2011: 'k_1259746', // 操作有风险
    8384: 'k_1259747', // 验证失败
};

export default defineComponent({
    name: 'phone-login',
    components: {},
    props: {},
    emits: ['close'],
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const isKwaiOrSnack = kwaiOrSnack(proxy);
        const verification_code = ref<string>('');
        const isClick = ref<boolean>(false);
        const countDown = ref<number>(60);
        const timeUp = ref<boolean>(false);
        const timerId = ref<number | null>(null);
        const mode = ref<string>('code');
        const phoneNumber = ref<string>('');
        const smsApi = new PhoneApiService();
        const UserApi = new UserApiService();
        const errorMsg = ref<string>('');
        const phoneError = ref<boolean>(false);
        const verCodeError = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const platform = Platform.PC_WEB;
        const businessType = BusinessType.WEB_PWA;

        const checkVerification = () => {
            verification_code.value = verification_code.value.replace(/\D/g, '').trim();
            if (verification_code.value.length >= 6 && mode.value === 'code') {
                verification_code.value = verification_code.value.slice(0, 6);
            }
        };

        const startInterval = () => {
            if (timerId.value || phoneNumber.value.length === 0) return;
            isClick.value = true;
            timeUp.value = false;
            const id = setInterval(() => {
                countDown.value--;
                if (countDown.value === 0) {
                    timeUp.value = true;
                    countDown.value = 60;
                    clearInterval(id);
                    timerId.value = null;
                }
            }, 1000) as unknown as number;
            timerId.value = id;
        };

        const verifyErrorMsg = (code: number | undefined) => {
            if (!code) return;
            if (ERROR_MAP[code]) {
                errorMsg.value = ERROR_MAP[code];
                if ([505, 506].includes(code)) {
                    phoneError.value = true;
                } else if (code === 127) {
                    verCodeError.value = true;
                }
            } else {
                startInterval();
            }
        };

        const resetError = () => {
            errorMsg.value = '';
            phoneError.value = false;
            verCodeError.value = false;
        };

        const sendCode = async () => {
            loading.value = true;
            resetError();
            const phone = phoneNumber.value.replace(/\s/g, '');
            if (phone.length === 0 || timerId.value !== null) return;
            // 发送验证码
            const codeResult = await smsApi.getMobileCode({
                phone,
                platform,
                businessType,
            });
            loading.value = false;

            // 调用验证流程
            if (codeResult && [1190, 2001].includes(codeResult.result as number)) {
                const verifyRes = await startIdentityVerification({
                    url: codeResult?.ztUnionVerifyUrl as string,
                });

                // 验证成功
                if (verifyRes.result === 1) {
                    const { type, token } = verifyRes;
                    const res = await smsApi.getMobileCode({
                        phone,
                        platform,
                        businessType,
                        ztIdVerifyType: type,
                        ztIdVerifyCheckToken: token,
                    });

                    verifyErrorMsg(res.result);
                }
            } else {
                verifyErrorMsg(codeResult?.result);
            }
        };

        const receivePhoneNumber = (val: string) => {
            phoneNumber.value = val;
            resetError();
        };

        const login = async () => {
            const phone = phoneNumber.value.replace(/\s/g, '');
            if (phone.length === 0 || verification_code.value.length !== 6) return;
            resetError();
            loading.value = true;
            const loginRes = await smsApi.smsLogin({
                phone,
                smsCode: verification_code.value,
                businessType,
            });

            if (loginRes.result === 1) {
                // 服务端service token
                const para = {
                    authToken: loginRes.authTokenValue,
                    sid: loginRes.sid,
                    followUrl: window?.location?.href,
                };

                // 种Cookie
                await UserApi.setCookie(para);

                // 获取用户信息
                const userInfo = await UserApi.getUserInfo();
                const { user_name, headurl } = userInfo.user as UserInfo;
                proxy.$store.commit('setUserInfo', {
                    user_name,
                    headurl,
                });

                // 关闭弹窗
                proxy.$store.commit('setIsLogin', true);
                ctx.emit('close');
            } else {
                verifyErrorMsg(loginRes.result);
            }
            loading.value = false;
        };

        return {
            mode,
            timeUp,
            loading,
            isClick,
            timerId,
            errorMsg,
            countDown,
            phoneError,
            phoneNumber,
            verCodeError,
            isKwaiOrSnack,
            verification_code,
            login,
            sendCode,
            startInterval,
            checkVerification,
            receivePhoneNumber,
        };
    },
});
