/* eslint-disable import/named */
import { NuxtAxiosInstance } from "vue/types/vue";
import { AxiosRequestConfig } from 'axios';
import { Feed } from "./feed";
import { CdnWithUrl, ResponseBase } from "./base";

// Picture详情数据结构
export interface Picture {
  web_feed_type?: string
  kwai_id?: string
  user_name?: string
  headurls?: CdnWithUrl[]
  caption?: string
  pic_id_str?: string
  pic_thumb_url?: string
  pic_origin_url?: string
  origin_width?: number
  origin_height?: number
  thumb_width?: number
  thumb_height?: number
  timestamp?: number
  like_count?: number
  forward_count?: number
  comment_count?: number
  view_count?: number
}

export interface TYMLItem {
  text?: string;
  image_url?: string;
}

// TYML（Terms You May Like）数据结构
export interface TYML {
  web_feed_type?: string;
  terms?: Array<TYMLItem>;
}

export type TopicItem = Picture | Feed | TYML;

interface TopicListRequest {
  topic?: string,
  pcursor?: number | string,
  count?: number,
}

interface TopicListResponse extends ResponseBase {
  pcursor?: string;
  feeds?: Array<TopicItem>;
}

export class TopicApiService {
  private $axios?: NuxtAxiosInstance;

  constructor(axios?: NuxtAxiosInstance) {
    this.$axios = axios;
  }

  async getTopicList(data: TopicListRequest, config?: AxiosRequestConfig) {
    const res = await this.$axios?.$$post?.<TopicListResponse>('overseaAPI::/rest/o/w/pic/topic', data, config);
    return res;
  }
}

export const TopicApi = (axios?: NuxtAxiosInstance) => new TopicApiService(axios);