// Google 登陆相关
import cookies from 'js-cookie';
import { getKpn } from '@/common/common-params';
import { getLoginPara, setLoginCookie, kpnClientMap, KpnClient, setCurrentUserHeadUrl } from '@/common/login';
import { UserInfoByLogin } from '@/api/user';

const GoogleAccountScriptId = 'google-account-script';
const GoogleScriptSrc = 'https://accounts.google.com/gsi/client';

const getClientId = (proxy: any) => {
    const kpn = getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host });
    return kpnClientMap[kpn as KpnClient];
};

const login = async (para: any, hideLoginModel: () => void, proxy: any, UserApi: any) => {
    const did = cookies.get('webDid');
    const access_token = para.credential || para.access_token;
    const platform = 'google';
    proxy.$bus.emit('isLoad', true);
    // const params = getLoginPara(proxy.$store.state.startup);

    const params = getLoginPara({
        startup: proxy.$store.state.startup,
        kpn: getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host }),
    });

    const res = await UserApi.thirdPlatformLogin(access_token, platform, did, undefined, params);
    setLoginCookie(res);

    if (res?.user_id || res?.userId) {
        proxy.$store.commit('userModule/setUserInfo', res);
    }

    setCurrentUserHeadUrl(res);

    proxy.$bus.emit('isLoad', false);
    // 服务端service token
    if (res.result === 1 && res['kwaipro.pwa_st']) {
        const para = {
            authToken: res['kwaipro.pwa.at'],
            sid: 'kwaipro.pwa',
            followUrl: window?.location?.href,
        };
        await UserApi.setCookie(para);
        hideLoginModel();
        proxy.$store.commit('setIsLogin', true);
        const { user_name, headurl } = res.user;
        proxy.$store.commit('setUserInfo', {
            user_name,
            headurl,
        });
        // window.location.reload();
        console.log('login success');
    } else {
        // ctx.emit('login', false);
        console.log('login failure');
    }
    proxy.$bus.emit('sendPv');
};

export const renderGoogleLoginButton = (hideLoginModel: () => void, containerId: string, proxy: any, UserApi: any) => {
    const clientId = getClientId(proxy);
    const google = window?.google;
    if (!google || !clientId) {
        return;
    }

    google.accounts.id.initialize({
        client_id: clientId,
        callback: async (e: any) => {
            await login(e, hideLoginModel, proxy, UserApi);
        },
    });

    const parent = document.getElementById(containerId);
    if (parent) {
        const buttonWidth = parent.offsetWidth;
        google.accounts.id.renderButton(parent, {
            opacity: 0,
            width: buttonWidth,
            text: 'Continue With Google',
            cancel_on_tap_outside: true,
            locale: proxy.$root.$i18n.locale || 'en',
            size: 'large', // 按钮大小
            shape: 'rectangular', // 按钮形状
        });
    }
};

export const initGoogleAccountSDK = (hideLoginModel: () => void, containerId: string, proxy: any, UserApi: any) => {
    const isLoadedScript = !!window?.google;
    if (isLoadedScript) {
        return;
    }

    const appendScript = document.createElement('script');
    appendScript.src = GoogleScriptSrc;
    appendScript.id = GoogleAccountScriptId;
    document.head.appendChild(appendScript);

    appendScript.onload = () => {
        renderGoogleLoginButton?.(hideLoginModel, containerId, proxy, UserApi);
    };
};
