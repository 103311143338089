// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosRequestConfig } from 'axios';
import { NuxtAxiosInstance } from 'vue/types/vue';
import axiosInstance from './request';

interface GetMetaInfoRequest {
    url: string;
    pidStrList?: Array<string>;
    locale?: string;
    onlyTitle?: boolean;
}

interface GetLdJsonRequest {
    url: string;
    pidStrList?: Array<string>;
}

class SEOApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getMetaInfo(data: GetMetaInfoRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<any>('overseaAPI::/rest/o/w/seo/metaInfo/getByType', data, config);

        return res;
    }

    async getLdJson(data: GetLdJsonRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<any>('overseaAPI::/rest/o/w/seo/ldJson/getByType', data, config);

        return res;
    }
}

export const SEOApi = new SEOApiService();
