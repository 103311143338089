
import { defineComponent, computed, SetupContext, getCurrentInstance, onMounted, ref } from 'vue';
import VideoAction from '@/components/VideoCard/components/videoAction/index.vue';
import FoldContent from '@/components/VideoCard/components/foldContent/index.vue';
import { sendLog } from '~/common/radar';

export default defineComponent({
    components: {
        VideoAction,
        FoldContent,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
        playVideo: {
            type: Number,
            default: 0,
        },
        bizData: {
            type: Object,
            default: () => {
                'pc-foryou';
            },
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const cardCommonParams = computed(() => {
            return {
                card_type: 'photo_text',
                card_position: props.index,
            };
        });

        // 点击头像、昵称等，上报埋点 + 弹下载 or 登陆App弹窗
        const userClick = (kwaiID: string, type: string) => {
            sendLog({
                type: 'click',
                name: 'USER_INFORMATION',
                value: {
                    author_id: props.item.kwai_id,
                    button_type: type,
                    card_type: 'photo_text',
                },
            });
            proxy.$bus.emit('show-model', true);
        };

        // 点击关注按钮，上报埋点 + 弹下载 or 登陆App弹窗
        const handleFollowClick = () => {
            proxy.$bus.emit('show-model', true);
            sendLog({
                type: 'click',
                name: 'USER_INFORMATION',
                value: {
                    author_id: props.item.kwai_id,
                    button_type: 'follow',
                    card_type: 'photo_text',
                },
            });
        };

        // 点击右侧操作区，点赞和评论上报埋点 + 弹登陆 or 下载App弹窗，分享弹分享面板
        const handleVideoActionClick = (type: string) => {
            if (type === 'like' || type === 'comment') {
                proxy.$bus.emit('show-model', true);
            } else {
                ctx.emit('video-action', type, props.index);
            }
        };

        // 点击图片大卡本身，不发生跳转，只进行埋点上报
        const clickPictureCard = () => {
            sendLog({
                type: 'click',
                name: 'PHOTO_CARD',
                value: {
                    button_type: 'photo_text',
                    ...cardCommonParams.value,
                },
            });
        };

        const pictureWidth = computed(() => {
            const width = (props?.item?.origin_width ?? 0) <= 0 ? 376 : props?.item?.origin_width;
            const height = (props?.item?.origin_height ?? 0) <= 0 ? 376 : props?.item?.origin_height;
            const scaledWidth = (672 * width) / height;
            return scaledWidth > 3000 ? 3000 : scaledWidth;
        });

        onMounted(() => {});

        return {
            userClick,
            handleFollowClick,
            handleVideoActionClick,
            cardCommonParams,
            clickPictureCard,
            pictureWidth,
        };
    },
});
