import { onMounted, onUnmounted } from 'vue'
import { sendLog } from './radar'

const sendPageLogger = (pageName: string) => {

  let timer5s: any = null
  let timer10s: any = null

  onMounted(() => {
    timer5s = setTimeout(() => {
      reportEvent('page_enter_5s');
    }, 5000);
    timer10s = setTimeout(() => {
      reportEvent('page_enter_10s');
    }, 10000);
  })

  onUnmounted(() => {
    if (timer5s) {
      clearTimeout(timer5s);
    }
    if (timer10s) {
      clearTimeout(timer10s);
    }
  })

  const reportEvent = (duration: string) => {
    sendLog({
      type: 'show',
      name: 'PWA_PAGE_STAY_DURATION',
      value: {
        source: 'pc',
        page_name: pageName,
        stay_duration: duration
      },
    })
  }
}

export default sendPageLogger