
import { defineComponent, ref, getCurrentInstance, onMounted, computed } from 'vue';
import { roundingNum } from '@/common/utils';
import { sendLog } from '@/common/radar';

export default defineComponent({
  components: {
  },
  props: {
    imgSrc: {
      type: String,
      // required: true,
      default: 'https://ak-br-pic.kwai.net/upic/2023/05/26/08/BMjAyMzA1MjYwODAzMjhfMTUwMDAwOTEwOTI2Mzg2XzE1MDEwMzAyODAzMDM4NV8yXzM=_low_B8563b5e79148c55aa88fe4ee8c4f1f10.webp?tag=1-1685225783-w-0-vc0qtfqjnu-01bb6110759e0a01'
    },
    viewCount: {
      type: Number,
      required: true,
      defalut: 0
    },
    duration: {
      type: Number,
      required: true,
      defalut: 0
    },
    photoIdStr: {
      type: String,
      required: true,
    },
    kwaiId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    caption: {
      type: String,
      required: true,
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as { proxy: any };
    let origin = ref('')

    const titleContent = computed(() => {
      if(props.caption === '...'){
        return `${proxy.$root.$t('k_407165').replace('${0}', props.index + 1)}`
      }
      return `${proxy.$root.$t('k_407165').replace('${0}', props.index + 1)} | ${props.caption?.replace(/\(\d\)/g, '')}`
    })

    const jumpLink = computed(() => {
      return `/@${props.kwaiId}/video/${props.photoIdStr}`
    })

    const handleClick = () => {
      sendLog({
        type: 'click',
        name: 'PHOTO_COVER',
        value: {
          photo_id: props.photoIdStr
        }
      })
      proxy.$router.push({ path: jumpLink.value })
    }

    function secondsToMinutesAndSeconds(milliseconds = 0) {
      const seconds = milliseconds / 1000;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }


    onMounted(() => {

      origin.value = window.location.origin
    })

    return {
      origin,
      proxy,
      roundingNum,
      titleContent,
      handleClick,
      secondsToMinutesAndSeconds,
      // jumpLink
    };
  },

});

