import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';

import axiosInstance from './request';

export class ArticleApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    /**
     * @zh 历史逻辑，先 Record<string, any>
     */
    async getArticleDetail(data: Record<string, any>, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<Record<string, any>>(`overseaAPI::/rest/o/w/article/detail`, data, config);

        return res;
    }
}

export const ArticleApi = new ArticleApiService();
