import Vue from 'vue'
import TYMLCard from './tyml-card/index.vue'
import { Picture, TYML, TopicItem } from '~/api/topic';
import { Feed } from '~/api/feed';
import { WebFeedType } from '~/api/base';

/**
 *  确保在每一个动画帧中，函数fn只会执行一次，避免因为频繁执行导致性能问题
 */
export function rafThrottle(fn: Function) {
  let lock = false;
  return function (this: any, ...args: any[]) {
    if (lock) return;
    lock = true;
    window.requestAnimationFrame(() => {
      fn.apply(this, args);
      lock = false;
    });
  };
}

/**
 * 确保在延迟时间内，如果函数fn被多次调用，只会执行一次
 */
export function debounce(fn: Function, delay = 200) {
  let timer: any = null;
  return function (this: any, ...args: any[]) {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

/**
 * 获取TYML卡片title高度，采用临时渲染一个dom的方法
 */
function getTYMLTitleHeight(proxy?: Vue, item?: TYML, realWidth?: number) {
  const dom = document.createElement('div');
  const title = proxy?.$root.$t('topic_you_may_like') || ''

  const componentInstance = new Vue({
    render(h) {
      return h(TYMLCard, {
        props: {
          tymlItemList: item,
          title,
          width: realWidth + 'px'
        }
      });
    }
  });

  componentInstance.$mount(dom);
  document.body.appendChild(componentInstance.$el);
  const height = componentInstance.$el.clientHeight;

  document.body.removeChild(componentInstance.$el);
  componentInstance.$destroy();

  return height;
}

/**
 * 瀑布流元素高度的计算函数
 */
export const calcItemHeight = (proxy?: Vue, item?: TopicItem, itemWidth?: number) => {
  let height = 0;

  if (!item || !itemWidth || itemWidth <= 0) {
    console.warn('item is null or itemWidth is zero');
    return height;
  }

  if (item?.web_feed_type === WebFeedType.TYML_CARD) {
    const TYMLItem = item as TYML
    if (!proxy || !TYMLItem?.terms?.length) {
      return height;
    }
    return getTYMLTitleHeight(proxy, TYMLItem, itemWidth);
  }

  if (item?.web_feed_type ===  WebFeedType.PHOTO_CARD) {
    const photItem = item as Feed
    if (!photItem?.ext_params?.h || !photItem?.ext_params?.w || photItem?.ext_params?.h <= 0 || photItem?.ext_params?.w <= 0) {
      return height;
    }
    return photItem.ext_params.h * (itemWidth / photItem.ext_params.w);
  }

  if (item?.web_feed_type === WebFeedType.PICTURE_CARD) {
    const pictureItem = item as Picture

    if (!pictureItem?.thumb_height || !pictureItem?.thumb_width || pictureItem?.thumb_height <= 0 || pictureItem?.thumb_width <= 0) {
      return height;
    }

    return pictureItem.thumb_height * (itemWidth / pictureItem.thumb_width);
  }

  return height;
}
