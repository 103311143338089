export const ContentConvert = () => import('../../components/ContentConvert/index.vue' /* webpackChunkName: "components/content-convert" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading/index.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoginModalFacebook = () => import('../../components/LoginModal/facebook.ts' /* webpackChunkName: "components/login-modal-facebook" */).then(c => wrapFunctional(c.default || c))
export const LoginModalGoogle = () => import('../../components/LoginModal/google.ts' /* webpackChunkName: "components/login-modal-google" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/LoginModal/index.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const LoginModalPrivateKey = () => import('../../components/LoginModal/privateKey.ts' /* webpackChunkName: "components/login-modal-private-key" */).then(c => wrapFunctional(c.default || c))
export const NoNetwork = () => import('../../components/NoNetwork/index.vue' /* webpackChunkName: "components/no-network" */).then(c => wrapFunctional(c.default || c))
export const DownLoadModal = () => import('../../components/DownLoadModal/index.vue' /* webpackChunkName: "components/down-load-modal" */).then(c => wrapFunctional(c.default || c))
export const NoPublicAccount = () => import('../../components/NoPublicAccount/index.vue' /* webpackChunkName: "components/no-public-account" */).then(c => wrapFunctional(c.default || c))
export const NoWorks = () => import('../../components/NoWorks/index.vue' /* webpackChunkName: "components/no-works" */).then(c => wrapFunctional(c.default || c))
export const PhoneLogin = () => import('../../components/PhoneLogin/index.vue' /* webpackChunkName: "components/phone-login" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumberInputCountrycode = () => import('../../components/PhoneNumberInput/countrycode.js' /* webpackChunkName: "components/phone-number-input-countrycode" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumberInput = () => import('../../components/PhoneNumberInput/index.vue' /* webpackChunkName: "components/phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const PictureDetail = () => import('../../components/PictureDetail/index.vue' /* webpackChunkName: "components/picture-detail" */).then(c => wrapFunctional(c.default || c))
export const PlcInfo = () => import('../../components/PlcInfo/index.vue' /* webpackChunkName: "components/plc-info" */).then(c => wrapFunctional(c.default || c))
export const QRCodeLogin = () => import('../../components/QRCodeLogin/index.vue' /* webpackChunkName: "components/q-r-code-login" */).then(c => wrapFunctional(c.default || c))
export const QRCodeLoginPrivateKey = () => import('../../components/QRCodeLogin/privateKey.ts' /* webpackChunkName: "components/q-r-code-login-private-key" */).then(c => wrapFunctional(c.default || c))
export const ShareEmbedModal = () => import('../../components/Share/embedModal.vue' /* webpackChunkName: "components/share-embed-modal" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/Share/index.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const Slide = () => import('../../components/Slide/index.vue' /* webpackChunkName: "components/slide" */).then(c => wrapFunctional(c.default || c))
export const TopBarVideoDetailBar = () => import('../../components/TopBar/VideoDetailBar.vue' /* webpackChunkName: "components/top-bar-video-detail-bar" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar/index.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const UserInfo = () => import('../../components/UserInfo/index.vue' /* webpackChunkName: "components/user-info" */).then(c => wrapFunctional(c.default || c))
export const VideoCard = () => import('../../components/VideoCard/index.vue' /* webpackChunkName: "components/video-card" */).then(c => wrapFunctional(c.default || c))
export const VideoControl = () => import('../../components/VideoControl/index.vue' /* webpackChunkName: "components/video-control" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/VideoPlayer/index.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoSwiper = () => import('../../components/VideoSwiper/index.vue' /* webpackChunkName: "components/video-swiper" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/empty/index.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const NewEmpty = () => import('../../components/new-empty/index.vue' /* webpackChunkName: "components/new-empty" */).then(c => wrapFunctional(c.default || c))
export const DetailSideInfoComponentsComponentsAuthorInfo = () => import('../../components/DetailSideInfoComponents/components/authorInfo.vue' /* webpackChunkName: "components/detail-side-info-components-components-author-info" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsComedySvg = () => import('../../components/Slide/components/comedySvg.vue' /* webpackChunkName: "components/slide-components-comedy-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsFilmSvg = () => import('../../components/Slide/components/filmSvg.vue' /* webpackChunkName: "components/slide-components-film-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsForYou = () => import('../../components/Slide/components/forYou.vue' /* webpackChunkName: "components/slide-components-for-you" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsHotSvg = () => import('../../components/Slide/components/hotSvg.vue' /* webpackChunkName: "components/slide-components-hot-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsLiferecordSvg = () => import('../../components/Slide/components/liferecordSvg.vue' /* webpackChunkName: "components/slide-components-liferecord-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsMusicSvg = () => import('../../components/Slide/components/musicSvg.vue' /* webpackChunkName: "components/slide-components-music-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsNewsSvg = () => import('../../components/Slide/components/newsSvg.vue' /* webpackChunkName: "components/slide-components-news-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsSportsSvg = () => import('../../components/Slide/components/sportsSvg.vue' /* webpackChunkName: "components/slide-components-sports-svg" */).then(c => wrapFunctional(c.default || c))
export const SlideComponentsTrendingStylesSvg = () => import('../../components/Slide/components/trendingStylesSvg.vue' /* webpackChunkName: "components/slide-components-trending-styles-svg" */).then(c => wrapFunctional(c.default || c))
export const TopBarComponentsInput = () => import('../../components/TopBar/components/input.vue' /* webpackChunkName: "components/top-bar-components-input" */).then(c => wrapFunctional(c.default || c))
export const DetailSideInfoComponentsComponentsComment = () => import('../../components/DetailSideInfoComponents/components/comment/index.vue' /* webpackChunkName: "components/detail-side-info-components-components-comment" */).then(c => wrapFunctional(c.default || c))
export const DetailSideInfoComponentsComponentsCommentLoading = () => import('../../components/DetailSideInfoComponents/components/comment/loading.vue' /* webpackChunkName: "components/detail-side-info-components-components-comment-loading" */).then(c => wrapFunctional(c.default || c))
export const DetailSideInfoComponentsComponentsCommentRootComment = () => import('../../components/DetailSideInfoComponents/components/comment/rootComment.vue' /* webpackChunkName: "components/detail-side-info-components-components-comment-root-comment" */).then(c => wrapFunctional(c.default || c))
export const DetailSideInfoComponentsComponentsVideoAction = () => import('../../components/DetailSideInfoComponents/components/videoAction/index.vue' /* webpackChunkName: "components/detail-side-info-components-components-video-action" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsCard = () => import('../../components/VideoCard/components/card/index.vue' /* webpackChunkName: "components/video-card-components-card" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsFoldContent = () => import('../../components/VideoCard/components/foldContent/index.vue' /* webpackChunkName: "components/video-card-components-fold-content" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsLoad = () => import('../../components/VideoCard/components/load/index.vue' /* webpackChunkName: "components/video-card-components-load" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsSvgsComment = () => import('../../components/VideoCard/components/svgs/comment.vue' /* webpackChunkName: "components/video-card-components-svgs-comment" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsSvgsLike = () => import('../../components/VideoCard/components/svgs/like.vue' /* webpackChunkName: "components/video-card-components-svgs-like" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsSvgsShare = () => import('../../components/VideoCard/components/svgs/share.vue' /* webpackChunkName: "components/video-card-components-svgs-share" */).then(c => wrapFunctional(c.default || c))
export const VideoCardComponentsVideoAction = () => import('../../components/VideoCard/components/videoAction/index.vue' /* webpackChunkName: "components/video-card-components-video-action" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
