
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        // KwaiPlayerWeb
    },
    props: {},
    setup(props, ctx) {
        return {};
    },
});
