class Timer {
    running?: 'running' | 'paused' | 'expired' 
    timerID?: NodeJS.Timeout
    remaining: number
    startTime?: number
    name?: string
    timeoutCallback?: () => void
    constructor(remaining: number, name?:string, timeout?: ()=> void) {
        this.remaining = remaining
        this.timeoutCallback = timeout
        this.name = name
    }

    startTimer() {
        if (this.running === 'running' || this.running === 'expired') {
            return
        }
        this.running = 'running'
        this.startTime = Date.now()
        this.timerID = setTimeout(() => {
          this.running = 'expired'
          if (this.timeoutCallback) {
            this.timeoutCallback()
          }
        }, this.remaining);
        console.log('TOP', 'TimerUtil', 'startTimer', this.name)
    }
       
    pauseTimer() {
        if (this.running === 'paused' || this.running === 'expired') {
            return
        }
        const elapsedTime = Date.now() - this.startTime!;
        this.remaining -= elapsedTime
        clearTimeout(this.timerID)
        this.running = 'paused'
        console.log('TOP', 'TimerUtil', 'pauseTimer',  this.name)
    }

    resumeTimer() {
        if (this.running === 'running' || this.running === 'expired') {
            return
        }
        console.log('TOP', 'TimerUtil', 'resumeTimer',  this.name)
        this.startTime = Date.now()
        this.startTimer()
    }

    clearTimer() {
        if (this.running === 'expired') {
            return
        }
        clearTimeout(this.timerID)
        this.startTime = undefined
        this.timerID = undefined
        this.running = 'expired'
        console.log('TOP', 'TimerUtil', 'clearTimer',  this.name)
    }
}

export default Timer