import { VueConstructor } from 'vue';
import log from './log/index';

function install(Vue: VueConstructor<Vue>) {
    Vue.directive('log', log);
}

export default {
    install,
};
