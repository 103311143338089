
import { defineComponent, getCurrentInstance, PropType } from 'vue'
import { TYML, TYMLItem } from '~/api/topic';
import { sendLog } from '~/common/radar';

const __default__ = defineComponent({
  props: {
    tymlItemList: {
      type: Object as PropType<TYML>,
      default: () => ({}),
    },
    title: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => '100%'
    }
  },
  setup(props, ctx) {
    const { proxy }  = getCurrentInstance() || {}
    const origin = window?.location?.origin?? ''

    const jumpLink = (item?: TYMLItem) => {
      return `/topic/${item?.text}`
    }

    const handleClickItem = (item?: TYMLItem) => {
      proxy?.$router?.push({ path: `/topic/${item?.text}` })

      sendLog({
        type: 'click',
        name: 'RELATED_SEARCH',
        value: {
          source: 'pc',
          keyword: item?.text
        }
      })
    }

    return {
      handleClickItem,
      origin,
      jumpLink
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "6633863f": (_vm.width)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__