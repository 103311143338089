
import { defineComponent, ref, getCurrentInstance, onMounted, nextTick, computed } from 'vue';

export default defineComponent({
    components: {},
    props: {
        icon: {
            type: String,
            default: '',
        },
        desc: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            default: '',
        },
        showPlc: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };

        const isShow = computed(() => {
            return props.showPlc && !!props.icon && !!props.desc;
        });

        const handlePlcClick = () => {
            if (props.link) {
                window.open(props.link, '_blank');
            }
        };

        onMounted(() => {});

        return {
            handlePlcClick,
            isShow,
        };
    },
});
