
import Vue, { defineComponent } from "vue";
export default defineComponent({
  name: "forYouSvg",
  components: {},
  props: {
    color: {
      type: String,
      default: "red",
    },
  },
});
