export interface ResponseBase {
    result?: number;
    error_msg?: string;
}

export const RESULT_OK = 1;

export enum RequestSource {
    REQUEST_SOURCE_UNKNOWN = 0,
    REQUEST_SOURCE_PYMK = 1, // 搜索主页
    REQUEST_SOURCE_PROFILE = 2,
    REQUEST_SOURCE_INTERESTED = 3, // 感兴趣的人推荐(其他无结果)
    REQUEST_SOURCE_FOLLOW = 4, // 关注页面推荐
    REQUEST_SOURCE_RECO_HOT = 5, // 从reco组热门页发起的调用
    REQUEST_SOURCE_RECO_FOLLOW = 6, // 从reco组关注页发起的调用
    REQUEST_SOURCE_RECO_NEARBY = 7, // 从reco组同城页发起的调用
    REQUEST_SOURCE_OFFLINE = 8, // 离线调用
    REQUEST_SOURCE_PROFILE_NOTIFY = 9, // 个人中心页的通知跳转而来
    REQUEST_SOURCE_SEARCH_NO_RESULT = 10, // 搜索无结果推荐
    REQUEST_SOURCE_OVERSEA_PUSH = 11, // 海外push
    REQUEST_SOURCE_OVERSEA_EXPLORE = 12, // 海外发现页
    REQUEST_SOURCE_OVERSEA_FIRST_LOGIN = 13, // 海外首次登录推荐

    // ---- 以上 Copy 自 user_reco_base.proto:RecoPortal ----

    REQUEST_SOURCE_WEB_PUSH = 14, // 海外H5 Push页推荐
    REQUEST_SOURCE_NEARBY = 16, // 同城推荐好友
    REQUEST_SOURCE_NEARBY_LIST = 17, // 同城推荐好友更多列表页
    REQUEST_SOURCE_SEARCH_FRIENDS = 18, // "查找好友" 页面
    REQUEST_SOURCE_USER_FOLLOWER = 19, // 海外粉丝列表空状态PYMK
    REQUEST_SOURCE_HOME_FOLLOW = 20, // 海外首页关注页空状态PYMK
    REQUEST_SOURCE_USER_FOLLOWING = 21, // 海外关注列表空状态PYMK
    REQUEST_SOURCE_NOTICE = 22, // 海外消息列表空状态PYMK
    REQUEST_SOURCE_NEWS = 23, // 海外动态列表空状态PYMK
    REQUEST_SOURCE_MESSAGE = 24, // 海外私信列表空状态PYMK
    REQUEST_SOURCE_USER_FOLLOWER_MORE = 25, // 海外粉丝列表非空状态PYMK
    REQUEST_SOURCE_USER_FOLLOWING_MORE = 26, // 海外关注列表空状态PYMK
    REQUEST_SOURCE_NOTICE_MORE = 27, // 海外动态列表非空状态PYMK
    REQUEST_SOURCE_NEWS_MORE = 28, // 海外消息列表非空状态PYMK
    REQUEST_SOURCE_MESSAGE_MORE = 29, // 海外私信列表非空状态PYMK
    REQUEST_SOURCE_SEARCH_RECOMMEND = 30, // 海外搜索推荐页
    REQUEST_SOURCE_PROFILE_RECOMMEND = 31, // 海外主态profile页pymk
    REQUEST_SOURCE_UNLOGIN_FOLLOW = 32, // 海外未登录关注页PYMK
    REQUEST_SOURCE_PROMOTION_ACTIVITY = 33, // 海外运营活动支持PYMK
    REQUEST_SOURCE_PROFILE_NOTIFY_GUIDE = 34, // 海外个人主页小红点引导PYMK
    REQUEST_SOURCE_GUEST_PROFILE_NO_PHOTO = 35, // 海外用户主页无可显示作品PYMK
    REQUEST_SOURCE_GROWTH_ACTIVITY_PAGE = 36, // 海外裂变拉新关注任务PYMK
    REQUEST_SOURCE_SELECTED_BUTTON_FOLLOW = 37, // 海外单列关注引导按钮样式PYMK
    REQUEST_SOURCE_GUEST_PROFILE_MORE = 38, // 海外用户主页有可显示作品PYMK
    REQUEST_SOURCE_SELECTED_POPUP_FOLLOW = 39, // 海外单列关注引导弹窗样式PYMK
    REQUEST_SOURCE_SELECTED_CARD_FOLLOW = 40, // 海外单列关注引导卡片样式PYMK
    REQUEST_SOURCE_SEARCH_AGGREGATION_RECOMMEND = 41, // 海外搜索聚合页找朋友PYMK
    REQUEST_SOURCE_SELECTED_LOGIN_POPUP = 42, // 海外单列精选页登录弹窗PYMK
    REQUEST_SOURCE_FOLLOW_TOP_CARD = 43, // 海外关注页置顶卡片的PYMK
    REQUEST_SOURCE_FOLLOW_NOT_LOGIN_SLIDE_CARD = 44, // 海外关注页未登录上下滑大卡片
    REQUEST_SOURCE_FOLLOW_CONTENT_FEED_CARD = 45, // 海外关注页内容流feed卡片
    REQUEST_SOURCE_NOTIFICATIONS_LIST = 46, // 海外通知页pymk通知
    REQUEST_SOURCE_FACEBOOK_THIRD_FIND_FRIENDS_LIST = 47, // 海外fb渠道发现好友页列表PYMK
    REQUEST_SOURCE_TWITTER_THIRD_FIND_FRIENDS_LIST = 48, // 海外twitter渠道发现好友页三方渠道好友列表PYMK
    REQUEST_SOURCE_FIND_CONTACT_LIST = 49, // 海外发现好友通讯录联系人列表PYMK
    REQUEST_SOURCE_PROFILE_RELATION_FRIENDS = 50, // 海外客态profile页关系好友pymk
    REQUEST_SOURCE_PROFILE_SHARE_REFLOW_MINI_POPUP = 51, // 海外客态pofile页分享回流小弹窗
    REQUEST_SOURCE_FIND_FRIENDS = 52, // 海外找朋友页pymk
    REQUEST_SOURCE_HOME_FOLLOW_MORE_PHOTO = 53, // 海外关注页有作品pymk
    REQUEST_SOURCE_FORU_FOLLOWALL = 54, // for u 页面一键关注
    REQUEST_SOURCE_SOCIAL_NOTIFICATION = 55, // 通知页聚合消息pymk
    REQUEST_SOURCE_GUEST_PROFILE_SUGGESTED = 56, // 客态P页推荐
    REQUEST_SOURCE_NOTIFICATIONS_SOCIAL_FINDFRIENDS = 57, // 通知页notification顶部新增【get more friends】落地页
    REQUEST_SOURCE_FOLLOW_FOLLOWALL = 58, // follow 页面一键关注 pymk弹窗
    REQUEST_SOURCE_FORU_PYMK_CARD = 59, // for u 页面pymk推人大卡
    REQUEST_SOURCE_PROFILE_RECOMMEND_REDNUM = 60, // 主态profile页招朋友按钮（透出新增潜在关系数字）
    REQUEST_SOURCE_PROFILE_RECOMMEND_REDNUM_RECO = 61, // 用于用于查红点数量

    // PWA 场景
    REQUEST_SOURCE_PWA_SHARE_SLIDE = 1001, // 用于pwa的分享上下滑内流
    REQUEST_SOURCE_PWA_DISCOVER_SEARCH = 1106, // 用于新专题页的上下滑
}

/**
 *  Web体裁枚举
 */
export enum WebFeedType {
    UNKNOWN = 'UNKNOWN',
    PHOTO_CARD = 'PHOTO_CARD', // 视频卡片
    PICTURE_CARD = 'PICTURE_CARD', // 图片卡片
    TYML_CARD = 'TYML_CARD', // TYML（terms you may like）卡片
    ARTICLE_CARD = 'ARTICLE_CARD', // 文章卡片
}

export interface CdnWithUrl {
    cdn?: string;
    url?: string;
}

/**
 * @zh 后端下发的人虫分离枚举
 */
export enum PWACrawlersReferer {
    Googlebot = 'Googlebot',
    SpecialCaseCrawlers = 'Special-case crawlers',
    unidentified = 'unidentified',
}
