import { sendLog } from '@/common/radar'
import Timer from '@/common/timer'

/*
* logger
*/ 
let timers: any = []
const keyFor5sTimerEvent = 'page_enter_5s'
const keyFor10sTimerEvent = 'page_enter_10s'

// route name that needs to be recorded TOP(time on page)
// const pageTOPConfig = {"pages": ["foryou", "profile", "discover", "messsage", "picture-photoId", "user-userId", "video-photoId",  "topic-content", "article-content"]}

export const tpLogger = () => {
    // events
  const registerPageVisibleChange = (page: any) => {
    // window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }
  
  const unregisterPageVisibleChange = (page: any) => {
    // window.removeEventListener('beforeunload', handleBeforeUnload);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
  
  const handleBeforeUnload = () => {
    if (document.readyState !== 'complete') {
      return
    }
    timers.forEach((timer: Timer) => {
      timer.clearTimer()
    })
    timers = []
    console.log('TOP', 'before-unload')
  }

  const handleVisibilityChange = () => {
    console.log('TOP', 'visibility-change', document.visibilityState)
    if (document.visibilityState  === 'hidden') {
      timers.forEach((timer: Timer) => {
        timer.pauseTimer()
      })
     
    }
    if (document.visibilityState  === 'visible') {
      timers.forEach((timer: Timer) => {
        timer.resumeTimer()
      })
    }
  }
  
  // timer
  const timerIdentifier = (page: string, type: string) => {
    return page + '-' + type
  }
  
  const start5sTimer = (page: string) => {
    // already has timer for the page, then resume
    let containPage = false
    timers.forEach((timer: Timer) => {
      if (timer.name === timerIdentifier(page, '5s')) {
        containPage = true
        timer.resumeTimer()
      }
    })
    if (containPage) {
      return
    }
    const timer = new Timer(5000, timerIdentifier(page, '5s'), () => {
      reportEvent(keyFor5sTimerEvent, page);
      const index = timers.indexOf(timer)
      if (index > -1) {
        timers.splice(index, 1)
      }
      timer.clearTimer()
      console.log('TOP', 'Timers:', timers)
    })
    timer.startTimer()
    timers.push(timer)
    console.log('TOP', 'start5sTimer', timer)
  }
  
  const start10sTimer = (page: string) => {
    // already has timer for the page, then resume
    let containpage = false
    timers.forEach((timer: Timer) => {
      if (timer.name === timerIdentifier(page, '10s')) {
        containpage = true
        timer.resumeTimer()
      }
    })
    if (containpage) {
      return
    }
  
    const timer = new Timer(10000, timerIdentifier(page, '10s'), () => {
      reportEvent(keyFor10sTimerEvent, page);
      const index = timers.indexOf(timer)
      if (index > -1) {
        timers.splice(index, 1)
      }
     
      timer.clearTimer()
      console.log('TOP', 'Timers:', timers)
    })
    timer.startTimer()
    timers.push(timer)
    console.log('TOP', 'start10sTimer', timer)
  }
  
  const pauseTimers = (page: string) => {
    timers.forEach((timer: Timer) => {
      if (timer.name?.includes(page)) {

        timer.pauseTimer()
      }
    })
    console.log('TOP', 'pauseTimers')
  }
  
  const clearTimers = (page: string) => {
    timers.forEach((timer: Timer) => {
      if (timer.name?.includes(page)) {

        timer.clearTimer()
      }
    })
    console.log('TOP', 'clearTimers')
  }
  
  const resumeTimers = (page: string) => {
    timers.forEach((timer: Timer) => {
      if (timer.name?.includes(page)) {

        timer.resumeTimer()
      }
    })
    console.log('TOP', 'resumeTimers')
  }

  // log
  const reportEvent = (duration: any, pageName: string) => {
    sendLog({
      type: 'show',
      name: 'PWA_PAGE_STAY_DURATION',
      value: {
        source: 'pc',
        page_name: pageName,
        stay_duration: duration
      },
    })
    console.log('TOP', 'Report Event', duration, pageName)
  }

  return {
    registerPageVisibleChange,
    unregisterPageVisibleChange,
    handleBeforeUnload,
    start5sTimer,
    start10sTimer,
    pauseTimers,
    resumeTimers,
    clearTimers,
  }
}
