var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
        type: 'show',
        name: 'PHOTO_CARD',
        value: {
            ..._vm.cardCommonParams,
        },
    }),expression:"{\n        type: 'show',\n        name: 'PHOTO_CARD',\n        value: {\n            ...cardCommonParams,\n        },\n    }"}],staticClass:"picture-card-container"},[_c('div',{staticClass:"header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.item.headurls[0].url),expression:"item.headurls[0].url"}],staticClass:"avatar",attrs:{"alt":""},on:{"click":function($event){return _vm.userClick(_vm.item.kwai_id, 'photo')}}}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.userClick(_vm.item.kwai_id, 'name')}}},[_vm._v(_vm._s(_vm.item.user_name))]),_vm._v(" "),_c('FoldContent',{staticClass:"descript"},[_c('ContentConvert',{attrs:{"content":_vm.item.caption,"page-source":'foryou'}})],1)],1),_vm._v(" "),_c('button',{staticClass:"follow-btn",on:{"click":_vm.handleFollowClick}},[_vm._v("\n                "+_vm._s(_vm.$t('Follow'))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"picture-card-warp"},[_c('div',{staticClass:"image-warp",style:({ width: `${_vm.pictureWidth}px` }),on:{"click":_vm.clickPictureCard}},[_c('img',{staticClass:"single-image",attrs:{"src":_vm.item?.pic_origin_url,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"image-icon"})]),_vm._v(" "),_c('VideoAction',{staticClass:"action",attrs:{"item":_vm.item},on:{"action":_vm.handleVideoActionClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }