
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import { initDeviceFingerprint } from '~/common/utils';

export default defineComponent({
    components: {},

    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        return {};
    },
    head() {
        const host = (this as any).$host || location?.host;
        const languagemap = (this as any).$i18n.localeCodes.map((el: string) => {
            return {
                rel: 'alternate',
                hreflang: el,
                href: `https://${host + decodeURI(this.$route.path)}?lang=${el}`,
            };
        });

        return {
            link: [
                {
                    rel: 'canonical',
                    href: `https://${host + decodeURI(this.$route.path)}`,
                },
                ...languagemap,
            ],
        };
    },
    mounted: () => {
        initDeviceFingerprint();
    },
});
