
import { defineComponent, ref, getCurrentInstance, PropType, computed } from 'vue';
import dayjs from 'dayjs';
import AritcleAction from '../article-action/index.vue';
import { ArticleActionType, ArticleInfo } from '../../models/main';
import { copyToClipboard, displayToastMessage } from '~/common/utils';
import { radarLog } from '~/common/radar';

export default defineComponent({
    components: {
        AritcleAction,
    },
    props: {
        articleInfo: {
            type: Object as PropType<ArticleInfo>,
            default: () => ({}),
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const showScrollTopIcon = ref(false);
        const articleContainerRef = ref();
        const hasReportedScrollAction = ref(false);

        const handleItemClick = (item: ArticleActionType) => {
            if (item === ArticleActionType.SHARE) {
                const copyUrl = window.location.href;
                copyToClipboard(copyUrl);
                displayToastMessage(proxy, proxy.$root.$t('k_446636'));
            } else {
                proxy.$bus.emit('show-model', true);
            }
        };

        const userHeadIcon = computed(() => {
            return props.articleInfo?.headurls?.[0]?.url || '';
        });

        const userName = computed(() => {
            return props.articleInfo?.user_name || '';
        });

        const publishTime = computed(() => {
            if (!props.articleInfo?.publish_timestamp) {
                return '';
            }
            return ` · ${dayjs.unix(props.articleInfo?.publish_timestamp).format('YYYY-MM-DD HH:mm')}`;
        });

        const handleScrollPosition = () => {
            const scrollTop = articleContainerRef?.value?.scrollTop || 0;
            showScrollTopIcon.value = scrollTop > 100;

            if (!hasReportedScrollAction.value) {
                hasReportedScrollAction.value = true;
                radarLog({
                    event_type: 'click',
                    category: 'KWAI_SHARE',
                    src: window.location.pathname,
                    name: 'PWA_PC_ARTICLE_SCROLL',
                });
            }
        };

        const handleScrollToTop = () => {
            articleContainerRef?.value?.scrollTo({ behavior: 'smooth', top: 0 });
        };

        const content = computed(() => {
            return props.articleInfo?.content ?? '';
        });

        return {
            userHeadIcon,
            userName,
            publishTime,
            handleItemClick,
            handleScrollToTop,
            articleContainerRef,
            handleScrollPosition,
            showScrollTopIcon,
            content,
        };
    },
});
