
import { defineComponent, computed, ref, PropType, nextTick, onActivated, onDeactivated, watch } from 'vue'
import { sendLog } from '@/common/radar';
import { Feed } from '~/api/feed';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Feed>,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const origin = window?.location?.origin
    const videoRef = ref()
    const isPlayVideo = ref(false)
    const isHovered = ref(false)

    const jumpLink = computed(() => {
      return `/@${props.item?.kwai_id}/video/${props.item?.photo_id_str}`
    })
    
    const pictureUrl = computed(() => {
      return props?.item?.cover_thumbnail_urls?.[0]?.url ?? ''
    })

    const videoDuration = computed(() => {
      const milliseconds = props?.item?.ext_params?.video || 0
      const seconds = milliseconds / 1000;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`; 
    })

    const handleCardClick = () => {
      sendLog({
        type: 'click',
        name: 'PHOTO_TEXT_CARD',
        value: {
          ...cardCommonParams.value,
          button_type: 'card'
        }
      })

      ctx.emit('handleVideoCardClick', props.item)
    }

    const cardCommonParams = computed(() => {
      return {
        pictrue_id: props?.item?.photo_id_str ?? '',
        card_position: props.index,
        type: 'photo',
        isMobile: 'false'
      }
    })

    watch(() => isHovered.value, async (val: boolean) => {
      await nextTick();

      const videoComp = videoRef.value?.videoComp;
      if (!videoComp) return;

      if (val) {
        if (videoComp.status?.canPlay) {
          videoComp.onEnter();
          videoComp.play();
        }
      } else {
        videoComp.pause();
        videoComp.onLeave();
      }
    });

    onActivated(() => {
      isHovered.value = false
    })

    onDeactivated(() => {
      isHovered.value = false
    })

    return { 
      pictureUrl, 
      handleCardClick, 
      cardCommonParams,
      origin,
      jumpLink,
      videoDuration,
      videoRef,
      isPlayVideo,
      isHovered
    }
  }
})
