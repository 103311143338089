import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0c6ee4cf&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=0c6ee4cf&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c6ee4cf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Share: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/Share/index.vue').default})
