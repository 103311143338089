import { IKwaiPlayer, KwaiPlayerEvent, PluginModule } from '@ks-video/kwai-player-web/vue2-mini';
import Control from '@/components/VideoControl/index.vue'
import Vue from 'vue'

// 新插件替换已有的logger插件
// export default class ExamplePlugin extends LoggerPluginModule {

// 新插件与已有插件共存
export default class ContorlPlugin extends PluginModule {

    private _player: IKwaiPlayer | undefined;
    private container: HTMLDivElement | undefined;
    private options: any;
    public volumeNumber = 0.5;

    public mouseDown = false;

    public mouseIsInVideo = false;

    public vueComponent = '';

    public static label = 'ContorlPlugin';

    /**
     * 播放器实例化前钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public beforeInstall(player: IKwaiPlayer, options?: any): void {
        this._player = player;
        this.container = this._player.createSubContainer();
        this.options = options
        this.vueComponent = new Vue({
            el: this.container,
            render: (h)=> h(Control, {
                props: this.options,
                on: {
                    mute: this.handleMuted,
                    play: this.handlePlay,
                    progressClick: this.handleProgressClick,
                    volumeClick: this.handleVolumeClick,
                },
            }),
        })

        console.log('debug beforeInstall', options, player, KwaiPlayerEvent, this.vueComponent);

        (this._player as any)._mainDom?.addEventListener('mouseover', this.handleControlShow.bind(this, true));
        (this._player as any)._mainDom?.addEventListener('mousedown', this.handleMouseOpera);
        
        (this._player as any)._mainDom?.addEventListener('mouseleave', this.handleControlShow.bind(this, false));

        //  更新current duration
        this._player.on(KwaiPlayerEvent.TIMEUPDATE, this._onTimeUpdate)

        //  更新 duration
        this._player.on(KwaiPlayerEvent.DURATIONCHANGE, this._onCurrentTimeChange)

        //  播放暂停处理
        this._player.on(KwaiPlayerEvent.PAUSE, this._onPauseHandle)
        this._player.on(KwaiPlayerEvent.PLAY, this._onPauseHandle)

        this._player.on(KwaiPlayerEvent.ATTRIBUTE_CHANGE_MUTED, this._handleMuted)

        //  音量调节
        this._player.on(KwaiPlayerEvent.ATTRIBUTE_CHANGE_VOLUME, this._handleVolume)


    }

    /**
     * 播放器实例化后钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public afterInstall(_player: IKwaiPlayer, _options?: any): void {
        // console.log('debug afterInstall');
        this.options.duration = _player.duration || 0

        _player.mainContainer?.addEventListener('click', (e) => {
            if((e.target as HTMLElement)?.getAttribute('class') !== '_kwai-player-video-interactive_uxc1a_11') return;
            this.handlePlay()
        })
    }

    /**
     * 播放器销毁前钩子
     * @param player 播放器实例
     */
    public beforeUninstall(_player: IKwaiPlayer): void {
        // console.log('debug beforeUninstall');
    }

    /**
     * 播放器销毁后钩子
     * @param player 播放器实例
     */
    public afterUninstall(_player: IKwaiPlayer): void {
        // console.log('debug afterUninstall');
        (_player as any)._mainDom?.removeEventListener('mouseover', this.handleControlShow.bind(this, true));
        (_player as any)._mainDom?.removeEventListener('mousedown', this.handleMouseOpera);
        
        (_player as any)._mainDom?.removeEventListener('mouseleave', this.handleControlShow.bind(this, false));
        //  更新current duration
        _player.off(KwaiPlayerEvent.TIMEUPDATE, this._onTimeUpdate)

        //  更新 duration
        _player.off(KwaiPlayerEvent.DURATIONCHANGE, this._onCurrentTimeChange)

        //  播放暂停处理
        _player.off(KwaiPlayerEvent.PAUSE, this._onPauseHandle)
        _player.off(KwaiPlayerEvent.PLAY, this._onPauseHandle)

        _player.off(KwaiPlayerEvent.ATTRIBUTE_CHANGE_MUTED, this._handleMuted)

        //  音量调节
        _player.off(KwaiPlayerEvent.ATTRIBUTE_CHANGE_VOLUME, this._handleVolume)
    }

    /**
     * 插件id，获取插件，传入参数等操作的标识
     * 同名插件只会同时存在一个
     */
    public getLabel(): string {
        return ContorlPlugin.label;
    }

    private _onCurrentTimeChange = (): void => {
        this.options.duration = this._player?.duration || 0
    }

    private _onTimeUpdate = (): void => {
        // console.log('_onTimeUpdate', this._player?.currentTime)
        this.options.playTime = this._player?.currentTime
    }

    private _onPauseHandle = (): void => {
        this.options.paused = this._player?.paused
    }

    private handleMuted = (e: boolean, volume?: number): void => {
        this.options.muted = e;
        (this._player as IKwaiPlayer).muted = e;
        if(e) this.volumeNumber = volume || 1;
    }

    private _handleMuted = (): void => {
        this.options.muted = (this._player as IKwaiPlayer).muted;
        
        if((this._player as IKwaiPlayer).muted) {
            (this._player as IKwaiPlayer).volume = 0
        }
        else (this._player as IKwaiPlayer).volume = this.volumeNumber || 1;        
    }

    private _handleVolume = (): void => {
        this.options.volume = (this._player as IKwaiPlayer).volume;
    }


    private handlePlay = (): void => {
        if(this._player?.paused) {
            this._player?.play()
        } else {
            this._player?.pause();
        }
    }
    private handleProgressClick = (percent: number): void => {
        const time = this.options.duration * percent;
        (this._player as IKwaiPlayer).currentTime = time
    }

    private handleVolumeClick = (percent: number): void => {
        if(percent !== 0 && (this._player as IKwaiPlayer).muted !== false) {
            this.handleMuted(false);
        } else if(percent === 0 && (this._player as IKwaiPlayer).muted !== true) {
            this.handleMuted(true);
        };
        // console.log('plugin', percent);
        (this._player as IKwaiPlayer).volume = percent
        // this.options.volume = percent
    }

    private handleControlShow = (isShow: boolean, e: any): void => {
        this.mouseIsInVideo = isShow;
        if(this.mouseDown) return
        this.options.showControl = isShow;
        (this.vueComponent as any)?.$forceUpdate();
    }

    private handleMouseOpera = (e: any): void => {
        this.mouseDown = true;
        const mouseup = (e: any) => {
            this.mouseDown = false;
            setTimeout(() => {
                if(this.mouseIsInVideo !== this.options.showControl) this.handleControlShow(this.mouseIsInVideo, e);
            }, 100)
            document.body?.removeEventListener('mouseup', mouseup)
        }
        document.body?.addEventListener('mouseup', mouseup);
    }
}
