
import { defineComponent, onMounted, ref, computed } from 'vue';

export default defineComponent({
    components: {},
    props: {
        showAd: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const showFourLine = ref(true);
        const textRef = ref();
        const showAction = ref(false);

        const clickBtn = () => {
            showFourLine.value = !showFourLine.value;
            // showAction.value = false
        };

        onMounted(() => {
            textRef.value.addEventListener('click', clickBtn);
            const height = textRef.value.scrollHeight;
            const lineHeight = Number(window.getComputedStyle(textRef.value).lineHeight.match(/\d+\.*\d+/g)?.[0]);
            showAction.value = height / lineHeight >= 3;
            showFourLine.value = showAction.value;
        });

        return {
            showFourLine,
            textRef,
            showAction,
            clickBtn,
        };
    },
});
