import cookies from 'js-cookie';
import { getKpn } from '@/common/common-params';
import { getLoginPara, setLoginCookie, kpnClientMap, KpnClient, setCurrentUserHeadUrl } from '@/common/login';
import { UserInfoByLogin } from '@/api/user';
import { kwaiOrSnack } from '@/common/utils';

const facebookParams = {
    appId: 808824071397026,
    xfbml: false,
    version: 'v12.0',
    cookie: true,
};
const FBAccountScriptId = 'facebook-account-script';
const FBScriptSrc = 'https://connect.facebook.net/zh_CN/sdk.js#xfbml=1&version=v20.0';

export const initFBAccountSDK = () => {
    const isLoadedScript = !!window?.FB;
    if (isLoadedScript) {
        return;
    }

    const appendScript = document.createElement('script');
    appendScript.src = FBScriptSrc;
    appendScript.id = FBAccountScriptId;
    document.body.appendChild(appendScript);

    appendScript.onload = () => {
        (window as any).fbAsyncInit = function fbAsyncInit() {
            window.FB.init(facebookParams);
            window.FB.AppEvents.logPageView();
        };
    };
};

export const onFacebookSign = (proxy: any, hideLoginModel: () => void, UserApi: any) => {
    window.FB.login(async (response: any) => {
        // handle the response
        const { status, authResponse } = response;
        if (status === 'connected') {
            const { accessToken } = authResponse;
            const did = cookies.get('webDid');
            const platform = 'facebook_kwai';
            const isKwaiOrSnack = kwaiOrSnack(proxy);
            const appId = isKwaiOrSnack === 'Snack' ? 'snackPcWeb' : 'kwaiPcWeb';
            proxy.$bus.emit('isLoad', true);

            const params = getLoginPara({
                startup: proxy.$store.state.startup,
                kpn: getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host }),
            });
            const res = await UserApi.thirdPlatformLogin(accessToken, platform, did, appId, params);
            setLoginCookie(res);

            if (res?.user_id || res?.userId) {
                proxy.$store.commit('userModule/setUserInfo', res);
            }

            setCurrentUserHeadUrl(res);

            proxy.$bus.emit('isLoad', false);
            // 服务端service token
            if (res.result === 1 && res['kwaipro.pwa_st']) {
                const para = {
                    authToken: res['kwaipro.pwa.at'],
                    sid: 'kwaipro.pwa',
                    followUrl: window?.location?.href,
                };
                await UserApi.setCookie(para);
                hideLoginModel();
                proxy.$store.commit('setIsLogin', true);
                const { user_name, headurl } = res.user;
                proxy.$store.commit('setUserInfo', {
                    user_name,
                    headurl,
                });
                console.log('login success by facebook');
            } else {
                // ctx.emit('login', false);
                console.log('login failure by facebook');
            }
            proxy.$bus.emit('sendPv');
        }
    });
};
