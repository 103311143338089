var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"users"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('user')))]),_vm._v(" "),_vm._l((_vm.users),function(item,index){return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
            type: 'show',
            name: 'USER_INFORMATION',
            value: {
                user_id: item?.kwaiId,
            },
        }),expression:"{\n            type: 'show',\n            name: 'USER_INFORMATION',\n            value: {\n                user_id: item?.kwaiId,\n            },\n        }"}],key:index,staticClass:"user-box",on:{"click":function($event){return _vm.userClick(item?.kwaiId)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item?.authorHeadUrl),expression:"item?.authorHeadUrl"}],staticClass:"avatar",attrs:{"alt":""}}),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item?.authorName))]),_vm._v(" "),_c('div',{staticClass:"id"},[_vm._v("ID: "+_vm._s(item?.kwaiId))]),_vm._v(" "),_c('div',{staticClass:"others"},[_vm._v(_vm._s(_vm.formateNum(item?.followers))+" "+_vm._s(_vm.$t('k_335876'))+" · "+_vm._s(_vm.formateNum(item?.videos))+" videos")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }