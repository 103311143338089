
import { defineComponent, getCurrentInstance, ref, PropType } from 'vue';
import { sendLog } from '@/common/radar';
import AuthorInfo from '@/components/DetailSideInfoComponents/components/authorInfo.vue';
import VideoAction from '@/components/DetailSideInfoComponents/components/videoAction/index.vue';
import TopBar from '@/components/TopBar/VideoDetailBar.vue';
import { PictureDetailInfo } from '@/common/type';

export default defineComponent({
    components: {
        VideoAction,
        AuthorInfo,
        TopBar,
    },
    props: {
        pictureDetailInfo: {
            type: Object as PropType<PictureDetailInfo>,
            default: undefined,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;

        const downloadModel = ref(false);
        const embedModalVisible = ref(false);
        const isError = ref(false);
        const isShowComments = ref<boolean>(false);

        const handleReturn = () => {
            window.history.back();
            sendLog({
                type: 'click',
                name: 'TOP_AREA',
                value: {
                    picture_id: props.pictureDetailInfo.pic_id_str,
                    author_id: props.pictureDetailInfo.kwai_id,
                    button_type: 'return',
                },
            });
        };

        const handleComment = () => {
            isShowComments.value = !isShowComments.value;
        };

        const handleShowModal = () => {
            proxy.$bus.emit('show-model', true);
            sendLog({
                type: 'show',
                name: 'DOWNLOAD_POP',
                value: {},
            });
        };

        async function toDataURL(url: string) {
            const blob = await fetch(url).then(res => res.blob());
            return URL.createObjectURL(blob);
        }

        async function downloadImage() {
            if (props.pictureDetailInfo?.pic_origin_url) {
                const a = document.createElement('a');
                a.href = await toDataURL(props.pictureDetailInfo.pic_origin_url);
                console.log('download clicked', props.pictureDetailInfo.pic_origin_url);
                a.download = `image_${props.pictureDetailInfo?.pic_id_str}.png`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }

        const handleDownload = () => {
            downloadImage();
        };

        const handleProfile = () => {
            // 影子账号不进入profile页
            // openNewWindow(`/@${props.pictureDetailInfo.kwai_id}?page_source=detail`)
        };

        return {
            downloadModel,
            handleReturn,
            isShowComments,
            handleComment,
            handleDownload,
            handleShowModal,
            handleProfile,
            embedModalVisible,
            isError,
        };
    },
});
