
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref, onDeactivated, onActivated, nextTick } from 'vue';
import cookies from 'js-cookie';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { immediateSendLog, getKwaiLog, sendLog } from '@/common/radar';
import { header } from '@/common/seo';
import { getQuery, isLoadMore, repalacePath, commonPvParams, checkSnack } from '@/common/utils';
import Load from './components/load/index.vue';
import { FeedApi } from '@/api/feed';

export default defineComponent({
    components: {
        Load,
    },

    setup(props, ctx: SetupContext) {
        const fmp_time = Date.now();
        const { proxy } = getCurrentInstance() as any;

        const videoList = ref<any>([]);
        const isSearching = ref(false);
        const playVideo = ref<any>(0);
        const isShow = ref(false);
        const slideIndex = ref(0);
        const cursorIndex = ref('');

        const videoRefs = ref<any>([]);

        const getVideoPlayer = (index: number) => {
            const videoComp = videoRefs.value[index] as any;
            return videoComp;
        };

        const setVideoPlayerEnter = () => {
            nextTick(() => {
                const newPlayer = getVideoPlayer(playVideo.value);
                newPlayer?.onEnter();
            });
        };

        const handleMenuClick = (key: string) => {
            if (!key) return;
            proxy.$router.push({ path: `/discover`, query: { ...getQuery(), page_source: 'foryou', tabName: key } });
        };

        const getData = async () => {
            if (isSearching.value) return;
            isSearching.value = true;
            const res = await FeedApi.getPCFeedForyou({
                tabName: '',
                searchWord: '',
                pcursor: cursorIndex.value,
                count: 8,
                fromUser: false,
            });
            const { feeds = [], result } = res || {};
            if (result === 1) videoList.value.push(...feeds);
            isSearching.value = false;
        };

        const handleVideoRef = (e: any, index: number) => {
            videoRefs.value[index] = e;
        };

        const timeout = ref<any>(null);
        const handleScroll = (e: Event) => {
            if (timeout.value) clearTimeout(timeout.value);
            timeout.value = setTimeout(() => {
                const content: HTMLElement = document.getElementById('foryou-main') as any;
                const index = Math.round(content?.scrollTop / 794);
                if (index === playVideo.value) return;
                getVideoPlayer(playVideo.value)?.onLeave();
                playVideo.value = index;
                setVideoPlayerEnter();
            }, 300);
        };

        const handleReturn = (number: number) => {
            repalacePath('/foryou');
            const element = document.getElementById('foryou-main') as any;
            const child = document.getElementById(videoList.value[number]?.photo_id_str);
            element?.scrollTo(0, (child?.offsetTop as number) - 84 - (element.clientHeight - (child?.clientHeight as number)) / 2);
            isShow.value = false;
        };

        const handleVideoClick = (number: number) => {
            slideIndex.value = number;
            repalacePath(`/@${videoList.value[number].kwai_id}/video/${videoList.value[number].photo_id_str}`);
            isShow.value = true;
        };

        const handleVideoActionClick = (type: string, number: number) => {
            if (type === 'comment') {
                slideIndex.value = number;
                repalacePath(`/@${videoList.value[number].kwai_id}/video/${videoList.value[number].photo_id_str}`);
                isShow.value = true;
            }
        };

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'search' });
            // 获取启动config
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');
            const referrer = document?.referrer;
            immediateSendLog({
                type: 'pv',
                name: 'PC_FOR_YOU_PAGE',
                value: {
                    country,
                    user_id,
                    ...params,
                },
            });
        };

        onMounted(() => {
            const webLogger = getKwaiLog();
            try {
                webLogger?.plugins?.radar?.fmp(fmp_time);
            } catch (error) {
                console.error(error);
            }

            if (proxy?.photoList?.length) {
                videoList.value = proxy?.photoList;
            }
            sendPv();

            if (proxy?.cursor) {
                cursorIndex.value = proxy?.cursor;
            }

            if (!proxy.isServerFetched) {
                getData();
            }

            isLoadMore('foryou-main', getData);
        });

        const scrollHeight = ref(0);

        onDeactivated(() => {
            const searchContent: HTMLElement = document.getElementById('foryou-main') as any;
            scrollHeight.value = searchContent.scrollTop;
        });

        onActivated(() => {
            const searchContent: HTMLElement = document.getElementById('foryou-main') as any;
            searchContent.scrollTo(0, scrollHeight.value);
        });

        return {
            handleMenuClick,
            isSearching,
            handleScroll,
            videoList,
            playVideo,
            isShow,
            getData,
            handleReturn,
            handleVideoClick,
            handleVideoActionClick,
            slideIndex,
            videoRefs,
            handleVideoRef,
        };
    },
    async asyncData(ctx: any) {
        let photoList = [];
        let cursor = [];
        let isServerFetched = false;

        if (process.server) {
            const { photoId, share_device_id, shareBucket } = ctx.route.query;
            setCommonParamsCookiesInServer({
                bucket: ctx.store.state.startup.bucket,
                host: ctx.req.headers.host,
                ctx,
                countryInfo: ctx.store.state.startup.countryInfo,
            });

            try {
                const res = await FeedApi.getPCFeedForyou({
                    tabName: '',
                    searchWord: '',
                    pcursor: '',
                    count: 8,
                    fromUser: false,
                });

                if (res?.result === 1) {
                    photoList = res.feeds;
                    cursor = res.cursor;
                    isServerFetched = true;
                }
            } catch (error) {
                console.log(error);
            }
        }
        return {
            photoList,
            metaInfoData: [],
            cursor,
            isServerFetched,
        };
    },
    head() {
        const head = header(this);

        const { isSnack } = checkSnack();
        const meta = head?.meta;

        const existingMeta: { [key: string]: boolean } = {
            'og:title': false,
            'og:description': false,
            keywords: false,
            description: false,
            'og:site_name': false,
        };

        meta?.forEach((item: any) => {
            if (item?.property in existingMeta) {
                existingMeta[item.property] = true;
            } else if (item?.name in existingMeta) {
                existingMeta[item.name] = true;
            }
        });

        if (!existingMeta['og:title']) {
            meta?.push({
                property: 'og:title',
                hid: 'og:title',
                content: isSnack ? 'Snack' : 'Kwai',
            });
        }

        if (!existingMeta['og:description']) {
            meta?.push({
                property: 'og:description',
                hid: 'og:description',
                content: isSnack ? 'Snack' : 'Kwai',
            });
        }

        if (!existingMeta.keywords) {
            meta?.push({
                name: 'keywords',
                content: isSnack ? 'Snack' : 'Kwai',
            });
        }

        if (!existingMeta.description) {
            meta?.push({
                name: 'description',
                hid: 'description',
                content: isSnack ? 'Snack' : 'Kwai',
            });
        }

        if (!existingMeta['og:site_name']) {
            meta?.push({
                hid: 'og:site_name',
                property: 'og:site_name',
                content: isSnack ? 'Snack' : 'Kwai',
            });
        }

        return {
            ...head,
        };
    },
});
