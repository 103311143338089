
import { ref, getCurrentInstance, computed, Ref, nextTick } from 'vue';

export const useList = (
  {
    feeds,
    albumInfo,
  }:
    {
      feeds: Ref<Array<Record<string, any>>>,
      albumInfo: any,
    }
) => {
  const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }
  const loading = ref(false)
  const cursor = ref('')
  const finished = computed(() => {
    return cursor.value === 'no_more'
  })

  const onLoad = async () => {
    const feedRes = await proxy.$axios.$$post('overseaAPI::/rest/o/w/pwa/feed/album', {
      albumId: proxy.$route?.params?.albumId,
      pcursor: cursor.value,
      count: 20 // 单次请求的post数量
    })
    if (feedRes.result === 1) {
      cursor.value = feedRes.cursor
      feeds.value = [...feeds.value, ...feedRes.feeds]
      // debugger
      nextTick(() => {
        albumInfo.value = feedRes.album
      })
      console.log(albumInfo)
      // debugger
    } else {
      cursor.value = 'no_more'
    }

    loading.value = false
  }

  const loadMorePost = () => {
    loading.value = true;
    onLoad();
  }

  const getAlbumFeed = async () => {
    return await proxy.$axios.$$post('overseaAPI::/rest/o/w/pwa/feed/profile', {
      albumId: proxy.$route?.params?.albumId,
      pcursor: cursor.value,
      count: 20
    })
  }

  return {
    cursor,
    finished,
    loading,
    loadMorePost,
    onLoad,
    getAlbumFeed,
    feeds,
  };
};

