var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"contentRef",style:({ 
    position: 'relative',
    willChange: 'height',
    padding: `${_vm.isNumber(_vm.padding) ? _vm.padding + 'px' : _vm.padding}`,
    height: `${Math.max(..._vm.columnsTop)}px`,
  })},_vm._l((_vm.itemSpaces),function(data,index){return _c('div',{key:`${data?.item?.pic_id_str ?? ''}${index}`,style:({
      position: 'absolute',
      contentVisibility: 'auto',
      width: `${_vm.itemWidth}px`,
      height: `${data?.height}px`,
      transform: `translate(${data?.left}px, ${data?.top}px)`,
      containIntrinsicSize: `${_vm.itemWidth}px ${data?.height}px`
    }),attrs:{"data-index":data?.index}},[_vm._t("default",null,{"item":data?.item,"index":data?.index})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }