
import { defineComponent, getCurrentInstance, onUnmounted, onMounted, ref, computed, watch } from 'vue';
import InputSearch from './components/input.vue';
import { sendLog, getKwaiLog, radarLog } from '@/common/radar';
import { openNewWindow } from '~/common/utils';
import { UserInfo, UserApiService } from '~/api/user';

export default defineComponent({
    components: {
        InputSearch,
    },
    props: {
        pageType: {
            type: String,
            default: 'normal',
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;
        const iconShow = ref<string>(proxy.$store.state.isSnack ? 'snack' : 'kwai');
        const loading = ref(false);
        const showInstallWebAppIcon = ref(false);
        const appId = proxy.$store.state.isSnack ? 'snackPcWeb' : 'kwaiPcWeb';
        const UserApi = new UserApiService();

        const showDownloadModel = () => {
            sendLog({
                type: 'click',
                name: 'TOP_CLICK_AREA',
                value: {
                    button_type: 'download',
                },
            });
            proxy.$bus.emit('show-download', true);
        };

        const showLoginModel = () => {
            // sendLog({
            //     type: 'click',
            //     name: 'TOP_CLICK_AREA',
            //     value: {
            //         button_type: 'download',
            //     },
            // });
            proxy.$bus.emit('show-model', true);
        };

        const clickInstallWebAppIcon = () => {
            sendLog({
                type: 'click',
                name: 'TOP_CLICK_AREA',
                value: {
                    button_type: 'install_web_app',
                },
            });
            // 如果用户已经安装web app/浏览器不支持，弹出toast；否则安装web app
            const promptEvent = window?.deferredPrompt;
            if (promptEvent) {
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('clickInstallWebAppIcon');
            } else {
                radarLog({
                    name: 'PWA_PC_CLICK_INSTALL_WEB_APP',
                    event_type: 'click',
                    category: 'KWAI_SHARE',
                    message: `web_app_not_support`,
                    src: window.location.href,
                });
                proxy.$message.open({
                    icon: null,
                    duration: 1,
                    content: (h: any) =>
                        h(
                            'div',
                            {
                                style: {
                                    background: 'rgba(44, 44, 44, 0.72)',
                                    width: '880px',
                                    height: '44px',
                                    borderRadius: '8px',
                                    lineHeight: '44px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    color: '#FFF',
                                },
                            },
                            proxy.$root
                                .$t('k_1216901')
                                .replace('${0}', iconShow.value === 'snack' ? 'Snack' : 'Kwai')
                                .replace('${0}', iconShow.value === 'snack' ? 'Snack' : 'Kwai')
                        ),
                });
            }
        };

        const inputValue = ref<string>('');

        const setLoading = (isLoading: boolean) => {
            loading.value = isLoading;
        };

        const handleLogoClick = () => {
            sendLog({
                type: 'click',
                name: 'TOP_CLICK_AREA',
                value: {
                    button_type: 'logo',
                },
            });
            openNewWindow('/');
        };

        const handleInputClick = () => {
            sendLog({
                type: 'click',
                name: 'TOP_CLICK_AREA',
                value: {
                    button_type: 'search_input',
                },
            });
        };

        const isChromeBrowser = () => {
            try {
                const inBrowser = typeof window !== 'undefined';
                const UA = inBrowser && window.navigator.userAgent.toLowerCase();
                const isEdge = UA && UA.indexOf('edge/') > 0;
                const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;
                return Boolean(isChrome);
            } catch (error) {
                return false;
            }
        };

        onMounted(async () => {
            proxy.$bus.on('isLoad', setLoading);
            inputValue.value = proxy.$store.state.searchValue;

            showInstallWebAppIcon.value = isChromeBrowser();
        });

        onUnmounted(() => {
            proxy.$bus.off('isLoad', setLoading);
        });

        return {
            loading,
            inputValue,
            showDownloadModel,
            showLoginModel,
            iconShow,
            handleLogoClick,
            handleInputClick,
            showInstallWebAppIcon,
            clickInstallWebAppIcon,
        };
    },
});
