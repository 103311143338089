
import { defineComponent, getCurrentInstance, ref, onMounted, watch } from 'vue';
import { KJUR, KEYUTIL, hextob64 } from 'jsrsasign';
import { kwaiOrSnack, generateUUID } from '@/common/utils';
import { sendLog } from '@/common/radar';
import { ScanApiService } from '~/api/scan';
import { key } from './privateKey';

export default defineComponent({
    name: 'qrcode-login',
    components: {},
    props: {
        mode: {
            type: String,
            default: 'default',
        },
    },
    emits: ['back', 'close', 'interval'],
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const isKwaiOrSnack = kwaiOrSnack(proxy);
        const qrCode = ref<string>('');
        const loading = ref<boolean>(false);
        const error = ref<boolean>(false);
        const qrCodePassport = ref<string | null>(null);
        const expireTime = ref<number | null>(null);
        const timer = ref<number | null>(null);
        const success = ref<boolean>(false);
        const failed = ref<boolean>(false);
        const ScanApi = new ScanApiService();

        // 扫码登陆相关参数
        const appId = isKwaiOrSnack === 'Snack' ? 'snackPcWeb' : 'kwaiPcWeb';
        const scope = 'all';

        const encryptWithPrivateKey = (data: string) => {
            const rsaKey = KEYUTIL.getKey(key);
            const sig = new KJUR.crypto.Signature({ alg: 'SHA256withRSA' });
            sig.init(rsaKey);
            sig.updateString(data);
            const signatureHex = sig.sign();
            return hextob64(signatureHex);
        };

        // 获取二维码
        const getQrCode = async () => {
            const uuid = generateUUID();
            const time = new Date().getTime();
            const signature = encryptWithPrivateKey(`appId=${appId}&scope=${scope}&nonceStr=${uuid}&timeStamp=${time}`);
            loading.value = true;
            const res = await ScanApi.getQrCode({
                appId,
                scope,
                nonceStr: uuid,
                timeStamp: time,
                signature,
            });
            loading.value = false;
            if (res && res.result === 1) {
                qrCode.value = res.qrImageUrl as string;
                qrCodePassport.value = res.qrCodePassport as string;
                expireTime.value = res.expireTime as number;
            } else {
                error.value = true;
            }
        };

        // 轮询状态
        const getStatus = async () => {
            if (qrCodePassport.value && expireTime.value) {
                const uuid = generateUUID() as string;
                const time = new Date().getTime();
                const signature = encryptWithPrivateKey(
                    `appId=${appId}&nonceStr=${uuid}&timeStamp=${time}&qrCodePassport=${qrCodePassport.value}&expireTime=${expireTime.value}`
                );
                const res = await ScanApi.checkState({
                    appId,
                    nonceStr: uuid,
                    timeStamp: time,
                    signature,
                    qrCodePassport: qrCodePassport.value,
                    expireTime: expireTime.value,
                });

                // 登陆成功
                if (res && res.result === 1 && res.authTokenValue) {
                    timer.value && clearInterval(timer.value);
                    success.value = true;
                    await proxy.$axios.$$post(`loginApi::/rest/o/w/user/login/setCookie`, {
                        authToken: res.authTokenValue,
                        sid: 'kwaipro.pwa',
                        followUrl: window?.location?.href,
                    });
                    proxy.$store.commit('setIsLogin', true);
                    const loginRes = await proxy.$axios.$$post('overseaAPI::/rest/o/w/user/login/visitor/info');
                    if (loginRes.result === 1) {
                        const { user_name, headurl } = loginRes.user;
                        proxy.$store.commit('setUserInfo', {
                            user_name,
                            headurl,
                        });
                    }
                    setTimeout(() => ctx.emit('close'), 2000);
                }
            }
        };

        // 重试
        const retry = () => {
            failed.value = false;
            getQrCode();
            if (timer.value) clearInterval(timer.value);
            timer.value = setInterval(() => {
                const now = new Date().getTime();
                if (expireTime.value && now > expireTime.value) {
                    failed.value = true;
                    timer.value && clearInterval(timer.value);
                } else getStatus();
            }, 2000) as unknown as number;
            ctx.emit('interval', timer.value);
        };

        watch(
            () => props.mode,
            async () => {
                // 过期或超时重新获取 QrCode，实现预加载
                const now = new Date().getTime();
                if (!qrCode.value || (expireTime.value && now > expireTime.value)) {
                    await getQrCode();
                }
                if (props.mode === 'scan') {
                    // 开启定时器轮询
                    if (expireTime.value !== null && !timer.value) {
                        timer.value = setInterval(() => {
                            const now = new Date().getTime();
                            if (expireTime.value && now > expireTime.value) {
                                failed.value = true;
                                timer.value && clearInterval(timer.value);
                            } else getStatus();
                        }, 2000) as unknown as number;
                        ctx.emit('interval', timer.value);
                    }
                }
            },
            {
                immediate: true,
            }
        );

        // 返回到前一页，取消轮询
        const back = () => {
            if (timer.value) {
                clearInterval(timer.value);
            }
            timer.value = null;
            error.value = false;
            ctx.emit('back');
        };

        return {
            proxy,
            qrCode,
            loading,
            isKwaiOrSnack,
            success,
            failed,
            error,
            back,
            retry,
        };
    },
});
