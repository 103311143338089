import { render, staticRenderFns } from "./_photoId.vue?vue&type=template&id=419b4b19"
import script from "./_photoId.vue?vue&type=script&lang=ts"
export * from "./_photoId.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureDetail: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/PictureDetail/index.vue').default})
