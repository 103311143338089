
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  Ref,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  watch,
  nextTick,
  PropType,
} from 'vue'
import cookies from 'js-cookie'
import KwaiPlayerVue2, { KwaiPlayer } from '@ks-video/kwai-player-web/vue2-mini'
import { getQuery } from '@/common/utils';
import Plugin from '@/common/videoLoadingPlugin';
import ContorlPlugin from '@/common/videoControlPlugin';
import PlcPlugin from '@/common/videoPlcPlugin';

import { sendLog } from '~/common/radar';

KwaiPlayer.register(Plugin)
KwaiPlayer.register(ContorlPlugin)
KwaiPlayer.register(PlcPlugin)

export default defineComponent({
  components: {
    KwaiPlayerVue2,
  },
  props: {
    src: {
      type: [Object, String],
      default: '',
    },
    preload: {
      type: String || undefined,
      default: undefined,
    },
    loop: {
      type: [Boolean],
      default: false,
    },
    playsinline: {
      type: [Boolean],
      default: false,
    },
    poster: {
      type: [String],
      default: '',
    },
    autoplay: {
      type: [Boolean],
      default: false,
    },
    muted: {
      type: [Boolean],
      default: false,
    },
    volume: {
      type: [Number],
      default: 1,
    },
    refKey: {
      type: [String, Number],
      default: '',
    },
    photoId: {
      type: [String, Number],
      default: '',
    },
    showInfoPanel: {
      type: [Boolean],
      default: getQuery()?.showInfoPanel === "true" || false,
    },
    bizData: {
      type: Object,
      default: () => { },
    },
    index: {
      type: Number,
      default: 0,
    },
    statLoggerParams: {
      type: Object,
      default: () => { },
    },
    controls: {
      type: Boolean,
      default: () => false,
    },
    plugins: {
      type: Object,
      default: () => { },
    },
    is264: {
      type: Boolean,
      default: false,
    },
    is265: {
      type: Boolean,
      default: false,
    },
    manifest264: {
      type: Object,
      default: () => { },
    },
    manifest265: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, context) {
    // const propsData = useRef(props)
    const { proxy } = getCurrentInstance() as { proxy: any }
    const videoRef = ref(null)
    const key = ref(`render${props.index}`)
    const status = reactive({ enterTime: 0, leaveTime: 0, canPlay: false, shouldPlay: props.autoplay, hasResume: false })
    const pauseStart = ref(0)
    const pauseDuartion = ref(0)
    const playSrc = ref(props.src)
    const pluginsOption = ref({
      LoggerPlugin: {
        // 日志上报位置
        logUrl: 'https://logsdk.kwai-pro.com/rest/wd/common/log/collect/misc2?kpn=KWAI&v=3.10.16',
        productName: 'KWAI',
        env: 'oversea',
        forceSendBeacon: false,
      },
      ContorlPlugin: false,
      PlcPlugin: false,
      PlayInfoPlugin: getQuery()?.showInfoPanel === "true" || false,
      ...props.plugins
    })

    const originSrc = computed(() => {
      let defaultSrc = props.src
      // if (props.is264 && props.manifest264) {
      //   defaultSrc = props.manifest264
      // }
      if (props.is265 && props.manifest265) {
        defaultSrc = props.manifest265
      }
      return defaultSrc
    })

    const bizDataAndUserId = computed(() => {
      return {
        ...props.bizData,
        userId: cookies.get('userId')
      }
    })
    const onEnter = () => {
      console.log('onEnter')
      status.enterTime = Date.now()
    }
    const onLeave = () => {
      console.log('onLeave')
      status.leaveTime = Date.now()
      sendVideoStatLog({})
    }
    // 业务主动终止网络请求
    const abortRequest = () => {
      playSrc.value = ''
    }
    const assignRequest = () => {
      playSrc.value = props.src
    }

    const sendVideoStatLog = (logParams: any) => {
      const instance = videoRef.value as any
      const videoStatus = instance?.getAudienceStat() ?? {}
      const playedDuration = status.leaveTime - status.enterTime - pauseDuartion.value
      // 字段来源与含义：https://docs.corp.kuaishou.com/k/home/VCruMNt_APag/fcAC66-Y5rQIuqzzozDmyQGDI
      const { creation_time, stream_duration: durationTime,
        //  play_duration: playedDuration,
        metadata_time: prepareTime, playing_start_time: beginPlayTime } = videoStatus
      if (status.enterTime === 0) return
      sendLog({
        type: 'click',
        name: 'PLAY_TIME',
        value: {
          // durationTime,
          // playedDuration,
          // prepareTime,
          // beginPlayTime,
          // enterTime: status.enterTime,
          // leaveTime: status.leaveTime,
          photo_id: props.photoId,
          author_id: props.statLoggerParams.user_id,
          play_time: playedDuration,
          play_duration: playedDuration,
          if_error: 0,
          type: 'BACKGROUND_TASK_EVENT'
        }
      })
      sendLog({
        type: 'click',
        name: 'PHOTO_PLAY',
        value: {
          start_time: status.enterTime,
          end_time: status.leaveTime,
          duration_time: playedDuration,
          play_duration: playedDuration,
          photo_id: props.photoId,
          is_silence: props.muted ? 1 : 0
        }
      })
      status.leaveTime = 0
      status.enterTime = 0
    }

    function resumePlayListener() {
      console.log('resumePlayListener: ');
      console.log('status.shouldPlay: ', status.shouldPlay);
      status.hasResume = true
    }
    // window.addEventListener('visibilitychange', () => {
    //   console.log('visibilitychange: ');
    // });
    onMounted(() => {
    })
    // 实测这里终止请求无效
    // onBeforeUnmount(()=>{
    //   playSrc.value = ''
    // })
    onUnmounted(() => {
      try {
      } catch (error) {
        console.log('kwai-player', error)
      }
    })
    const onClick = (e: Event) => {
      context.emit('click', e, props.refKey)
    }

    const onPlaying = (e: Event) => {
      context.emit('playing', e, props.refKey)
    }
    const onPlay = (e: Event) => {
      if (pauseStart.value) {
        pauseDuartion.value += (Date.now() - pauseStart.value)
        pauseStart.value = 0
      }
      context.emit('play', e, props.refKey)
    }
    const onWaiting = (e: Event) => {
      console.log('onWaiting', e)
      context.emit('waiting', e, props.refKey)
    }
    const onError = (e: any) => {
      console.log('player-error', e);
      const playedDuration = status.leaveTime - status.enterTime - pauseDuartion.value
      if (playedDuration <= 0) sendLog({
        type: 'click',
        name: 'PLAY_PHOTO',
        value: {
          photo_id: props.photoId,
          author_id: props.statLoggerParams.user_id,
          if_success: 0,
          type: 'BACKGROUND_TASK_EVENT'
        }
      })
      else sendLog({
        type: 'click',
        name: 'PLAY_TIME',
        value: {
          photo_id: props.photoId,
          author_id: props.statLoggerParams.user_id,
          play_time: playedDuration,
          if_error: 1,
          type: 'BACKGROUND_TASK_EVENT'
        },
      })
      if (status.canPlay === false) {
      }
      context.emit('error', e, props.refKey)

    }
    const onEnded = (e: Event) => {
      context.emit('ended', e, props.refKey)
      onLeave()
    }

    const onPause = (e: Event) => {
      pauseStart.value = Date.now()

      context.emit('pause', e, props.refKey)
      if (status.shouldPlay && status.hasResume) {
        retryPlay()
      }
    }
    const onCanPlay = (e: Event) => {
      status.canPlay = true
      status.enterTime = Date.now()
      context.emit('canplay', e, props.refKey)
    }
    const onLoaded = (e: Event) => {
      console.log('loadeddata', e)
      sendLog({
        type: 'click',
        name: 'PLAY_PHOTO',
        value: {
          photo_id: props.photoId,
          author_id: props.statLoggerParams.user_id,
          if_success: 1,
          type: 'BACKGROUND_TASK_EVENT'
        }
      })
      context.emit('loadeddata', e, props.refKey)
    }
    const retryPlay = () => {
      status.hasResume = false;
      const video = videoRef.value! as KwaiPlayerVue2;
      console.log('retry-video?.play(): ', video?.play);
      video?.play()?.catch(() => { })
    }
    const play = () => {
      status.shouldPlay = true;
      const video = videoRef.value! as KwaiPlayerVue2;
      console.log('api--video?.play(): ', video?.play);
      video?.play()?.catch(() => { })
    }
    const pause = () => {
      console.log('api--pause: ');
      status.shouldPlay = false;
      status.hasResume = false;
      const video = videoRef.value! as KwaiPlayerVue2;
      video?.pause()?.catch(() => { })
    }
    const getPaused = () => {
      const video = videoRef.value! as KwaiPlayerVue2;
      return video?.getPaused()
    }


    return {
      status,
      pluginsOption,
      videoRef,
      playSrc,
      bizDataAndUserId,
      originSrc,
      play,
      pause,
      onEnter,
      onLeave,
      sendVideoStatLog,
      onClick,
      onWaiting,
      onPlay,
      onPlaying,
      onError,
      onEnded,
      onPause,
      onLoaded,
      onCanPlay,
      getPaused,
      abortRequest,
      assignRequest,
      key,
    }

  },

})
