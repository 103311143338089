// development staging production
const MY_NODE_ENV = process.env.MY_NODE_ENV || process.env.NODE_ENV || 'development';

exports.MY_NODE_ENV = MY_NODE_ENV;

const isProduction = MY_NODE_ENV === 'production'
exports.isProduction = isProduction


exports.isLocal = process.env.NODE_ENV === 'local'

const serverHosts =
  MY_NODE_ENV === 'production'
    ? {
      overseaAPI: 'http://sgp.kwaipro-api.internal',
      // overseaAPI: 'https://kwai-pwa-pc.test.gifshow.com',
    }
    : {
      // overseaAPI: 'https://kwai-pwa-hzs.test.gifshow.com',
      overseaAPI: 'https://kwai-pwa-pc.test.gifshow.com',
      // mockServer: 'http://mock.corp.kuaishou.com/mock/4826',
      // overseaAPI: 'https://www.kwai.com',
    };

exports.serverHosts = serverHosts;

exports.swConfig = {
  swFileName: 'sw-pc.js',
  swScope: '/'
}