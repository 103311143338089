import { render, staticRenderFns } from "./rootComment.vue?vue&type=template&id=d708c29e&scoped=true"
import script from "./rootComment.vue?vue&type=script&lang=ts"
export * from "./rootComment.vue?vue&type=script&lang=ts"
import style0 from "./rootComment.vue?vue&type=style&index=0&id=d708c29e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d708c29e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentConvert: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/ContentConvert/index.vue').default})
