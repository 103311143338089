import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fdb79e2&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=4fdb79e2&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdb79e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {QRCodeLogin: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/QRCodeLogin/index.vue').default,PhoneLogin: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/PhoneLogin/index.vue').default})
