
import { defineComponent, getCurrentInstance, onUnmounted, computed, onMounted, ref } from 'vue';
import { openNewWindow } from '~/common/utils';

export default defineComponent({
    components: {},
    props: {
        pageType: {
            type: String,
            default: 'normal',
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;
        const iconShow = ref<string>(proxy.$store.state.isSnack ? 'snack' : 'kwai');

        const loading = ref(false);

        const inputValue = ref<string>('');

        const handleLogoClick = () => {
            openNewWindow('/');
        };

        onMounted(() => {
            inputValue.value = proxy.$store.state.searchValue;
        });

        return {
            loading,
            inputValue,
            iconShow,
            handleLogoClick,
        };
    },
});
