
// snack的社交链接
export const socialList:Record<string,any> = {
    snack:{
        en: [
            // 英语+其他兜底
            { name: 'fb', url: 'https://www.facebook.com/snackvideoglobal' },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_global' },
            // {
            //     name: 'youtube',
            //     url: 'https://www.youtube.com/channel/UCTQaaIXSGvAaiRGk6D9Z2BQ/featured',
            // },
            // { name: 'twitter', url: 'https://twitter.com/SnackVideoPK' },
        ],
        id: [
            // 印尼-印尼语
            { name: 'fb', url: 'https://www.facebook.com/Snackvideoindonesia' },
            { name: 'ins', url: 'https://www.instagram.com/snack_video_indonesia' },
            // {
            //     name: 'youtube',
            //     url: 'https://www.youtube.com/channel/UC59flOaAB9TpJcj2kgtIAJA',
            // },
            // { name: 'twitter', url: 'https://twitter.com/SnackVideo_IDN' },
        ],
        hi: [
            //印度 印地语
            { name: 'fb', url: 'https://www.facebook.com/OfficialSnackVideoIndia' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/official_snackvideo_india',
            },
        ],
        th: [
            //泰国-泰语
            {
                name: 'fb',
                url: 'https://www.facebook.com/SnackVideo-Thailand-106693024716374',
            },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_thailand' },
            // { name: 'twitter', url: 'https://twitter.com/SnackvideoTH' },
        ],
        bn: [
            //孟加拉-孟加拉语
            { name: 'fb', url: 'https://www.facebook.com/SnackVideoBangladesh' },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_bangladesh' },
        ],
        ne: [
            //尼泊尔-尼泊尔语
            {
                name: 'fb',
                url: 'https://www.facebook.com/SnackVideo-Nepal-100616692223419',
            },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_nepal' },
        ],
        si: [
            //斯里兰卡-僧伽罗语
            {
                name: 'fb',
                url: 'https://www.facebook.com/SnackVideo-Sri-Lanka-102523802030631',
            },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_srilanka' },
        ],
        ta: [
            //斯里兰卡-泰米尔语
            {
                name: 'fb',
                url: 'https://www.facebook.com/SnackVideo-Sri-Lanka-102523802030631',
            },
            { name: 'ins', url: 'https://www.instagram.com/snackvideo_srilanka' },
        ],
    },
    kwai:{
        en: [
            // { name: 'fb', url: 'https://www.facebook.com/pg/kwaiappofficial/' },
            // { name: 'ins', url: 'https://www.instagram.com/kwai_app_official/' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        hi: [
            // { name: 'fb', url: 'https://www.facebook.com/IndianKwai/' },
            // { name: 'ins', url: 'https://www.instagram.com/indiankwai/' },
            // { name: 'youtube', url: 'https://www.youtube.com/channel/UCqlZkIW1UfcWgpdpYP0ep3Q/featured' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        'pt-BR': [
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
            // { name: 'youtube', url: 'https://www.youtube.com/channel/UCQaU9uBqmjTDTfSm9tEHVXg' },
        ],
        ms: [
            // { name: 'fb', url: 'https://www.facebook.com/KwaiMalaysia/' },
            // { name: 'ins', url: 'https://www.instagram.com/kwaimalaysia/' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        id: [
            // { name: 'fb', url: 'https://www.facebook.com/Snackvideoindonesia/' },
            // { name: 'ins', url: 'https://www.instagram.com/snackvideo_idn/' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        es: [
            // { name: 'fb', url: 'https://www.facebook.com/KwaiMalaysia/' },
            // { name: 'ins', url: 'https://www.instagram.com/kwaimalaysia/' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        tr: [
            // { name: 'fb', url: 'https://www.facebook.com/KwaiMalaysia/' },
            // { name: 'ins', url: 'https://www.instagram.com/kwaimalaysia/' },
            { name: 'fb', url: 'https://www.facebook.com/kwaibrasil/' },
            {
                name: 'ins',
                url: 'https://www.instagram.com/kwaibrasil/',
            },
        ],
        ar:[{
            name:'fb',
            url:'https://www.facebook.com/Kwai-MENA-103564155214290'
        },{
            name:'ins',
            url:'https://www.instagram.com/kwai_mena/'
        }]
    }
}