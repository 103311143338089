var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.videoList.length)?_c('div',{staticClass:"video-detail",attrs:{"id":"video-detail"}},[_c('TopBar',{staticClass:"video-top-bar",attrs:{"page-type":"video"}}),_vm._v(" "),_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.videoList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"video-swiper"},[_c('div',{staticClass:"video-content",on:{"wheel":_vm.handleMousewheel}},[_c('div',{staticClass:"background-mask"},[(Math.abs(_vm.slideIndex - index) < 3)?_c('img',{staticClass:"backgroundImg",attrs:{"src":item?.cover_thumbnail_urls?.[0]?.url,"alt":""}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"video-box"},[(Math.abs(_vm.slideIndex - index) < 2 && !_vm.isError)?_c('VideoPlayer',{ref:el => (_vm.videoRefs[index] = el?.videoComp),refInFor:true,staticClass:"video-detail-player",attrs:{"item":item,"slide-index":_vm.slideIndex,"index":index,"biz-data":_vm.bizData,"loop":true,"volume":1,"plugins":{
                                ContorlPlugin: {
                                    muted: _vm.muted,
                                    duration: 0,
                                    playTime: 0,
                                    paused: false,
                                },
                            },"autoplay":index === 0,"muted":_vm.muted},on:{"canplay":_vm.onloadeddata}}):_vm._e()],1),_vm._v(" "),(_vm.isError && !_vm.videoList[_vm.slideIndex])?_c('div',{staticClass:"error"},[_c('NoNetwork')],1):_vm._e(),_vm._v(" "),_c('VideoAction',{directives:[{name:"show",rawName:"v-show",value:(_vm.slideIndex === index),expression:"slideIndex === index"}],staticClass:"action",attrs:{"head":item?.headurls?.[0]?.url,"like-count":item?.like_count,"comment-count":item?.comment_count,"share-count":item?.forward_count,"poster":item?.cover_first_frame_urls?.[0]?.url,"photo-id":item?.photo_id_str,"slide-index":_vm.slideIndex,"index":index,"is-end":_vm.slideIndex === _vm.videoList.length - 1,"item":item},on:{"showModal":_vm.handleShowModal,"slide":_vm.handleSlide,"comment":_vm.handleComment,"profile":_vm.handleProfile}}),_vm._v(" "),_c('AuthorInfo',{attrs:{"name":item?.user_name,"descript":item?.caption},on:{"profile":_vm.handleProfile,"showModal":_vm.handleShowModal}})],1),_vm._v(" "),_c('div',{class:`video-draw ${_vm.isShowComments ? 'comment-width' : 'comment-nowidth'}`},[(_vm.slideIndex === index)?_c('Comment',{staticClass:"drawer",attrs:{"photo-id":item?.photo_id_str,"slide-index":_vm.slideIndex,"index":index},on:{"showModal":_vm.handleShowModal}}):_vm._e()],1)])])}),1),_vm._v(" "),_c('div',{staticClass:"return",on:{"click":_vm.handleReturn}},[_c('img',{attrs:{"src":require("./img/angle.png"),"alt":"","srcset":""}})])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }