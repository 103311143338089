import { AxiosRequestConfig } from 'axios';
import { FeedApi, getPCFeedSearchRequest, DiscoverSearchListRequest } from '@/api/feed';
import { PWACrawlersReferer } from '@/api/base';

class CrawlersApiRequestService {
    getPCFeedSearch(
        data: getPCFeedSearchRequest & {
            crawler?: PWACrawlersReferer;
        },
        config?: AxiosRequestConfig
    ) {
        const { crawler, count = 3, ...rest } = data || {};
        /**
         * @zh 约定该接口为爬虫请求时请求数量为 3
         */
        const feedCount = crawler === PWACrawlersReferer.Googlebot ? 3 : count;
        const request = {
            ...rest,
            count: feedCount,
        };
        return FeedApi.getPCFeedSearch(request, config);
    }

    getDiscoverSearchFeedList(
        data: DiscoverSearchListRequest & {
            crawler?: PWACrawlersReferer;
        },
        config?: AxiosRequestConfig
    ) {
        const { crawler, count = 1, ...rest } = data || {};
        /**
         * @zh 约定该接口为爬虫请求时请求数量为 1
         */
        const feedCount = crawler === PWACrawlersReferer.Googlebot ? 1 : count;
        const request = {
            ...rest,
            count: feedCount,
        };
        return FeedApi.getDiscoverSearchFeedList(request, config);
    }
}

// eslint-disable-next-line import/prefer-default-export
export const CrawlersApiRequest = new CrawlersApiRequestService();
