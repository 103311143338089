/* eslint-disable import/named */
import { getCurrentInstance } from 'vue';
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { CdnWithUrl, RequestSource, ResponseBase } from './base';

import axiosInstance from './request';

export enum FeedLiked {
    UnLike = 0,
    Like = 1,
}

export enum AuthorFollowing {
    Unfollowed = 0,
    Following = 1,
}

interface ForwardStatsParams {
    fid?: string;
    et?: string;
}

export interface Feed {
    web_feed_type?: string;
    time?: string;
    timestamp?: string;
    liked?: FeedLiked;
    following?: AuthorFollowing;
    photo_id?: number;
    photo_id_str?: string;
    user_name?: string;
    user_sex?: string;
    kwai_id?: string;
    user_text?: string;
    comment_count?: number;
    forward_count?: number;
    headurls?: Array<CdnWithUrl>;
    main_mv_urls?: Array<CdnWithUrl>;
    cover_thumbnail_urls?: Array<CdnWithUrl>;
    cover_first_frame_urls?: Array<CdnWithUrl>;
    reco_reason?: string;
    exp_tag?: string;
    ext_params?: Record<string, any>;
    like_count?: number;
    view_count?: number;
    forward_stats_params?: ForwardStatsParams;
    caption?: string;
    user_id?: number | string;
}

export interface UserComment {
    commentId?: number;
    content?: string;
    commentTime?: number;
    userId?: number;
    userName?: string;
    userHead?: string;
    userHeads?: Array<CdnWithUrl>;
    commentLikeCount?: number;
    kwaiid?: string;
}

/** *** Api  *** */
interface FeedListRequest {
    photo_id_str?: string;
    deviceId?: string;
    request_source?: RequestSource;
    count?: number;
    mobile?: boolean;
}

interface FeedListResponse extends ResponseBase {
    feeds?: Array<Feed>;
}

export interface DiscoverSearchListRequest extends Pick<FeedListRequest, 'count' | 'mobile'> {
    requestSource: RequestSource;
    photoIdStr?: string;
    url: string;
    onlyTitle?: boolean;
    needLdJson?: boolean;
    needTdk?: boolean;
}

export interface DiscoverSearchListResponse extends FeedListResponse {
    tdk?: any[];
    ldJson?: any[];
}

interface UserCommentListRequest {
    photo_id?: string;
}

interface UserCommentListResponse extends ResponseBase {
    comments?: Array<UserComment>;
}

interface ChangeFeedLikedStatusRequest {
    fromPage?: string;
    cancel?: boolean;
    photoId?: string;
}

type ChangeFeedLikedStatusResponse = ResponseBase;

interface FollowActionRequest {
    fromPage?: string;
    toUid?: string | number; // 可以是 user_id, 也可以是 `@${kwai_id}`
    fType?: number;
    photoId?: string;
    noticeId?: string;
}

type FollowActionResponse = ResponseBase;

export type getPCFeedSearchRequest = Record<string, any>;

export class FeedApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getDiscoverSearchFeedList(data: DiscoverSearchListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<DiscoverSearchListResponse>('overseaAPI::/rest/o/w/pwa/v2/search/feed', data, config);

        return res;
    }

    async getUserCommentList(data: UserCommentListRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$get?.<UserCommentListResponse>('/rest/o/w/pwa/comment/list', {
            ...config,
            params: data,
        });

        return res;
    }

    async changeFeedLikedStatus(data: ChangeFeedLikedStatusRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<ChangeFeedLikedStatusResponse>('/rest/o/w/pwa/likeAction', data, config);

        return res;
    }

    async followAction(data: FollowActionRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<FollowActionResponse>('/rest/o/w/pwa/followAction', data, config);

        return res;
    }

    /**
     * @zh 历史逻辑，先 Record<string, any>
     */
    async getPCFeedForyou(data: Record<string, any>, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<Record<string, any>>('overseaAPI::/rest/o/w/pc/feed/forYou', data, config);

        return res;
    }

    /**
     * @zh 历史逻辑，先 Record<string, any>
     */
    async getPCFeedSearch(data: getPCFeedSearchRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<Record<string, any>>('overseaAPI::/rest/o/w/pc/feed/search', data, config);

        return res;
    }
}

export const FeedApi = new FeedApiService();
