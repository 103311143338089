
  import { defineComponent, getCurrentInstance, watch, ref } from "vue";
  import { copyToClipboard } from '@/common/utils'
  import { sendLog } from "@/common/radar";
  
  export default defineComponent({
    name: "downloadModal",
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        poster: {
            type: String,
            default: ''
        },
        photoId: {
            type: String,
            default: ''
        },
    },
    setup(props, ctx) {
      const { proxy } = getCurrentInstance() as { proxy: any }
      const isSnack = proxy.$store.state.isSnack
      const content = ref(`<iframe width="560" height="315" src="https://${window.location.host}/embed/${props.photoId}" title="${'video detail'}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`)

      const isVisible = ref(props.visible);

      const handleClose = () => {
        ctx.emit('close');
        sendLog({
            type: 'click',
            name: 'GET_KWAI_APP',
            value: {
                button_type: 'close'
            }
        })
      }

      const copyCode = () => {
          copyToClipboard(content.value);
          sendLog({
            type: 'click',
            name: 'GET_KWAI_APP',
            value: {
                button_type: 'copy_link'
            }
          })
          proxy.$message.open(
              {
              icon:null,
              duration:1,
              content:(h: any)=>h('div',{
                style:{
                  background: 'rgba(44, 44, 44, 0.72)',
                  width:'880px',
                  height:'44px',
                  borderRadius:'8px',
                  lineHeight:'44px',
                  textAlign:'center',
                  fontSize:'16px',
                  fontStyle:'normal',
                  fontWeight:400,
                  color:'#FFF',
                }
              },proxy.$root.$t('k_446023'))},
              
          );
      }

      const handleText = () => {
        return proxy.$root.$t('k_453590')
            .replace('${0}', `<a class='desc-a' target="_blank" href="/legal?type=1">${proxy.$root.$t('k_453591')}</a>`)
            .replace('${1}', `<a class='desc-a' target="_blank" href="/legal?type=0">${proxy.$root.$t('k_453592')}</a>`)
      }

      watch(() => props.visible, (val:boolean) => {
        isVisible.value = val
      })

      return {
        isSnack,
        handleClose,
        content,
        copyCode,
        handleText,
      }
    }
  });
