
import { defineComponent, getCurrentInstance, onMounted, ref, watch, nextTick, onActivated, onDeactivated } from 'vue';
import cookies from 'js-cookie';
import { header, extractTitleFromMetaInfo, getDefaultTitle } from '@/common/seo';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { immediateSendLog, sendLog } from '@/common/radar';
import { commonPvParams, FeedType } from '@/common/utils';
import VideoSwiper from '@/components/VideoSwiper/index.vue';
import { SEOApi } from '@/api/seo';
import { EmbedApi } from '@/api/embed';
import { CrawlersApiRequest } from '@/common/crawlers';

const PwaVideoDetailShare = defineComponent({
    components: {
        VideoSwiper,
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;

        const downloadModel = ref(false);
        const embedModalVisible = ref(false);

        const videoList = ref(proxy.$store.state.videoList || []);
        const cursorIndex = ref(0);
        const slideIndex = ref(0);
        const swiperRef = ref();
        const videoRefs = ref([]);

        const muted = ref(false);

        const isError = ref(false);

        const replaceTitle = async (path: string, index: number) => {
            const fullPath = window?.location?.href?.split('?')[0] || '';
            const res = await SEOApi.getMetaInfo({ url: fullPath, onlyTitle: true });
            if (res.status === 200) {
                document.getElementsByTagName('title')[0].innerText = extractTitleFromMetaInfo({ ctx, metaInfoData: res?.data || '' });
            }
        };

        const handleReturn = () => {
            window.history.back();
            sendLog({
                type: 'click',
                name: 'TOP_AREA',
                value: {
                    button_type: 'return',
                },
            });
        };

        const handleComment = () => {
            isShowComments.value = !isShowComments.value;
        };

        const getData = async () => {
            if (videoList.value.length === 0) {
                proxy.$message.open({
                    icon: null,
                    duration: 3,
                    content: (h: any) =>
                        h(
                            'div',
                            {
                                style: {
                                    background: 'rgba(255, 255, 255, 0.82)',
                                    width: '280px',
                                    height: '44px',
                                    borderRadius: '8px',
                                    lineHeight: '44px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    color: 'black',
                                    marginTop: '20%',
                                },
                            },
                            proxy.$root.$t('k_343236')
                        ),
                });
            }
            const res = await CrawlersApiRequest.getPCFeedSearch({
                tabName: '',
                searchWord: '',
                pcursor: cursorIndex.value,
                count: 10,
                fromUser: false,
                crawler: proxy?.$store?.state?.startup?.crawler,
            });
            const { feeds, result, pcursor } = res || {};
            if (result === 1) {
                // 上下滑组件展示还不支持图文类型，所以对PictureCategoryCard进行过滤
                const filteredFeeds = feeds.filter((item: { type?: number }) => item?.type !== FeedType.PICTURE_CATEGORY_CARD);
                filteredFeeds?.length && videoList.value.push(...filteredFeeds);
                cursorIndex.value = pcursor;
                isError.value = false;
            } else {
                isError.value = true;
            }
        };
        const isShowComments = ref<boolean>(true);

        const sendPv = (pvType: 'enter' | 'leave' | 'hidden' | 'visible' | undefined) => {
            const params = commonPvParams({ defaultPageSource: 'direct' });
            // 获取启动config
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');

            immediateSendLog({
                type: 'pv',
                pvType,
                name: '',
                value: {
                    country,
                    user_id,
                    ...params,
                },
            });
        };

        const videoPlayStatus = ref(false);

        onActivated(() => {
            if (videoPlayStatus.value) (videoRefs.value[slideIndex.value] as any)?.pause();
            else (videoRefs.value[slideIndex.value] as any)?.play();
        });

        onDeactivated(() => {
            videoPlayStatus.value = (videoRefs.value[slideIndex.value] as any)?.getPaused();
        });

        onMounted(() => {
            getData();

            sendPv('enter');

            document.addEventListener('visibilitychange', () => {
                const pageVisibility = document.visibilityState;
                // 页面变为不可见时触发
                if (pageVisibility === 'hidden') {
                    sendPv('hidden');
                }
            });
            window.onbeforeunload = window.onunload = function () {
                sendPv('leave');
            };

            proxy.$bus.on('video-muted', (val: boolean) => {
                muted.value = val;
            });
        });

        return {
            downloadModel,
            videoList,
            handleReturn,
            isShowComments,
            slideIndex,
            swiperRef,
            videoRefs,
            handleComment,
            embedModalVisible,
            muted,
            isError,
            getData,
            replaceTitle,
        };
    },
    async asyncData(ctx: any) {
        let photoList = [];
        let seoData = [];
        let metaInfoData = [];
        let title = getDefaultTitle(ctx);
        const showFeed = false;
        const { query } = ctx;
        if ((process as any).server) {
            setCommonParamsCookiesInServer({
                bucket: ctx.store.state.startup.bucket,
                host: ctx.req.headers.host,
                ctx,
                countryInfo: ctx.store.state.startup.countryInfo,
            });
            try {
                const { photoId } = ctx.route?.query;
                const realPhotoId = ctx?.route?.params?.photoId || photoId;
                const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || '';

                // 获取google ldjson 标签
                const seoPromise = SEOApi.getLdJson({ url });
                // 获取meta info信息
                const metaInfoPromise = SEOApi.getMetaInfo({ url, locale: ctx.req.headers['accept-language'] });

                const photoPromise = EmbedApi.getPCPhoto({ photo_id: realPhotoId });
                const [photoRes, seoRes, metaInfoRes] = await Promise.all([photoPromise, seoPromise, metaInfoPromise]);

                if (photoRes?.result === 1) {
                    photoList = photoRes.feeds;
                    ctx.store.commit('setVideoList', photoList);
                }

                if (seoRes.status === 200) {
                    seoData = seoRes.data;
                }

                if (metaInfoRes.status === 200) {
                    metaInfoData = metaInfoRes.data;
                    title = extractTitleFromMetaInfo({ ctx, metaInfoData });
                    ctx.store.commit('setMetaInfoData', metaInfoData);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            const feedsPromise = await EmbedApi.getPCPhoto({ photo_id: ctx.route.params.photoId });

            const metaInfoPromise = SEOApi.getMetaInfo({
                url: `https://${window?.location?.host}${ctx?.route?.path}` || '',
            });
            const [photoRes, metaInfoRes] = await Promise.all([feedsPromise, metaInfoPromise]);
            if (photoRes?.result === 1) {
                photoList = photoRes.feeds;
                ctx.store.commit('setVideoList', photoRes.feeds);
            }
            if (metaInfoRes.status === 200) {
                metaInfoData = metaInfoRes.data;
                title = extractTitleFromMetaInfo({
                    ctx,
                    metaInfoData,
                });
                ctx.store.commit('setMetaInfoData', metaInfoData);
            }
        }
        ctx.store.commit('setSource', '');

        return {
            photoList,
            showFeed,
            seoData,
            metaInfoData,
            title,
        };
    },
    head() {
        (this as any).metaInfoData = this.$store?.state?.metaInfoData;
        const head = header(this);
        return {
            ...head,
        };
    },
});

export default PwaVideoDetailShare;
