var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"video-boxs"},_vm._l((_vm.feedsData),function(item,index){return _c('div',{key:index,staticClass:"video-content"},[_c('div',{directives:[{name:"log",rawName:"v-log",value:({
                type: 'show',
                name: 'PHOTO_CARD',
                value: {
                    photo_id: item?.photo_id_str || item?.photo_id,
                },
            }),expression:"{\n                type: 'show',\n                name: 'PHOTO_CARD',\n                value: {\n                    photo_id: item?.photo_id_str || item?.photo_id,\n                },\n            }"}],staticClass:"videoPlayer",on:{"mouseenter":function($event){return _vm.mouseenter(index)},"mouseleave":function($event){return _vm.mouseleave(index)},"click":function($event){return _vm.handleVideoClick(index, item?.photo_id_str)}}},[(index === _vm.playVideo)?_c('video-player',{ref:item => (_vm.videoRefs[index] = item?.videoComp),refInFor:true,attrs:{"index":index,"item":item,"muted":_vm.muted,"biz-data":{ pageSource: 'pc-profile', page_code: 'PC_PROFILE_PAGE' },"plugins":{
                    ContorlPlugin: {
                        muted: _vm.muted,
                        paused: false,
                    },
                }},on:{"ended":_vm.onEnded}}):_vm._e(),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.playVideo),expression:"index !== playVideo"},{name:"lazy",rawName:"v-lazy",value:(item?.cover_first_frame_urls?.[0]?.url),expression:"item?.cover_first_frame_urls?.[0]?.url"}],staticClass:"poster",attrs:{"alt":""}}),_vm._v(" "),(item?.profile_user_top)?_c('div',{staticClass:"toptag"},[_vm._v(_vm._s(_vm.$t('k_343696')))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"playinfo"},[_c('img',{staticClass:"play-img",attrs:{"src":require("../imgs/play.png"),"alt":""}}),_vm._v(" "),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.formateNum(item?.view_count)))]),_vm._v(" "),(_vm.muted && _vm.playVideo === index)?_c('img',{staticClass:"mute-img",attrs:{"src":require("../imgs/mute.png")},on:{"click":function($event){$event.stopPropagation();return _vm.handleMuted.apply(null, arguments)}}}):(_vm.playVideo === index)?_c('img',{staticClass:"mute-img",attrs:{"src":require("../imgs/nomute.png")},on:{"click":function($event){$event.stopPropagation();return _vm.handleMuted.apply(null, arguments)}}}):_vm._e()])],1),_vm._v(" "),_c('ContentConvert',{staticClass:"text",attrs:{"content":item?.caption === '...' ? '' : item?.caption},on:{"element-click":function($event){return _vm.openTag($event, item?.photo_id_str)}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }