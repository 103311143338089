/* eslint-disable import/no-extraneous-dependencies */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';
/* eslint-disable import/named */

import axiosInstance from './request';

type GetABTestRequest = {
    /**
     * @zh 实验参数名称
     */
    parameter?: string;
    url?: string;
    countryInfo?: string;
};

interface GetABTestResponse extends ResponseBase {
    status?: number;
    data?: {
        /**
         * @zh 实验参数名称
         */
        parameter?: string;
        value?: string;
        [key: string]: any;
    };
}

export class ABTestApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getABTestConfig(data: GetABTestRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetABTestResponse>('overseaAPI::/rest/o/w/seo/ab/getByUrl', data, config);

        return res;
    }
}

export const ABTestApi = new ABTestApiService();
