const privateKeyBase64 =
    'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCcTh/xhxRUUoCVvKFL3NNIlbPIYP28W58TaZ7zij5R9b6mEsFeytHOM6lH3ms3d4FZj9cDfYAJwJREoN/bxbWMM7IuwYYsNG4/A/yJkX9eSGMC6yIa6ReDYU36izH22E8s8chlMFJcvhcZvr6LE6rNAHWgfx5gHr7th6YZTqthQLf/L4NshWaA97XwAK97HosE5lvR+sUhwqG8NhW+L4tCaqrOpzWOViElj9aGAPKvysfSRHhxWdbOWv7jAuR7qtajVgMZ9spjjPyhwEN9xqwKboULVIAwgid+r5AonSyBXleTLYgQ5sUZuuafXlw1sMEkf3N89JJWJP0YXHz8nZEHAgMBAAECggEBAIfgFj61CUQgEC2as7Cp/3WqxYWcYxa5oviSKZ5Wf8Rp11ixFy4bK/WPzUlvnU6zcVt1AhnDoGlZ4soh+1gmb6K4Ud35V3TYAzp+car5M3q3fHLOCnDQTiNklBAHruCBu5o7eNQWBJMgoB4+RY/LAgCBtL9XogU21QzRjd+UFsFLx57rbnU8yKTGi2yE1gHm9ocA8NlaeZdAR271MbQ2NQKfXp2rTvuqhsiQ9dnsS4Gudm3mpTn4fOdGM0GIGyb+ie2MFevtKiQ5ZLVAJvOsTZNnDNU4FhqCIzY+LdkBSdlkzbINZcvyNQZO80/4b/bYbEPkuokmHGInGiFC11Th0TkCgYEA+wIhEtBB2rs2YASSDvAL0XLL9h+QvsjoYJ068xJZvUWgV2TrD74HT+GKjgkhyQZMHP+Dj0Bm/xcn+XiUqOCtYrQ7UuEhyBAMFrt07prwH4YJUaPrECUA6UPPixM4KnDet1TOdKx870QjMUTX323yVqLC6lav+CDn/I6K9pD12+UCgYEAn2nd2rQp7Kwvr7sylRl1kj+E6qrtL6zXZwSsU0DXK29zGwvUb8yt6DxzQWuFiH5KL+EK57AGxsRkTJSrAlALSxHRC+qmJoGXUQWmj//B13c/vMBiQW5Y/Kiua4fGv0UQsts+edoQBgeK6Z9d+osf44wSUvC2HFNR97Kuv0KeonsCgYEA6i3fW2UOu5oELeq1tdgAvqTiYzqp2UUpf9HRCKwrKymFYWPW5jX9LmqW0NL2QU1tv3QncoO8U+tvsRDtaO1M9hR+cGF4GeXW0XKMsnu84Tw0/4T1rndHdVl9jRweP4JUVyh5AWNXoAaq6rF20Y4LFQb+4JCF1fIlXXxDa6xspH0CgYAM1pvVZzkRJHTatDrIozpCwvbTZ1yewE7ArzGpqskpaFpezT8YmeVPlL67gAtLCNC0tm0IZ7bD/0EModR75TJjo1Wwnf8vjrQ/BDN72V8lGSd67gt7tYVZI8SYSGELOmaz4NBRQ+ySb70SP5F0AgxZaQh4wYYJFfS9/Bfys0zPTQKBgQCkesh/hZyPoadRFS4wx+GCOofPZhu03MDCy/hMvsg0MqgTwrfTuIfeoB+JEc+MpoVVUJhLq50UrpHMMtpD9jqrHwumu2e6AJTNyrEMthpm/GMGGsKWXibDJ+lqKirxHREgfUeTy13AK2+Ro3SvXhrBwKBB9K6QISuVbz/W8UQbsA==';

function getKey() {
    let pemFormat = '-----BEGIN PRIVATE KEY-----\n';

    // 将 Base64 字符串每64个字符一行
    const maxLineLength = 64;
    for (let i = 0; i < privateKeyBase64.length; i += maxLineLength) {
        pemFormat += `${privateKeyBase64.substring(i, i + maxLineLength)}\n`;
    }

    pemFormat += '-----END PRIVATE KEY-----';
    return pemFormat;
}

export const key = getKey();
