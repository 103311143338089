
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref,reactive } from 'vue';
import { formateNum, formateTime, handleCommentReg, openNewWindow } from '@/common/utils'
import Load from './loading.vue'
import { sendLog } from '~/common/radar';

export default defineComponent({
  components: {
    Load
  },
  props: {
    commentsList: {
        type: Array,
        default: []
    },
    cursorSubComment: {
        type: Object,
        default: {}
    },
  },
  setup(props, ctx: SetupContext) {
    const { proxy } = getCurrentInstance() as any
    const isSnack = proxy.$store.state.isSnack


    //保存所有主评论的子评论个数
    const subCommentCountInRoot=ref<any>({})
    const isHasMoreComments=ref<boolean>(true)

    const getMoreSubComments = (id: string) => {
        sendLog({
            type: 'click',
            name: 'COMMENT_AREA',
            value: {
                button_type: 'expand'
            }
        })
        ctx.emit('getMoreSubComments', id)
    }
    
    const handleClick = (type: string, kwaiId?: string) => {
        if(type=='photo' || type === 'name') {
            openNewWindow(`/@${kwaiId}?page_source=detail`)
        } else ctx.emit('showModal', type)
    }
    onMounted( () => {
    })

    return {
        isSnack,
        formateNum,
        handleClick,
        formateTime,
        isHasMoreComments,
        subCommentCountInRoot,
        getMoreSubComments,
        handleCommentReg,
    }
  },
})
