
import { defineComponent, ref, getCurrentInstance, watch, onMounted, onUnmounted } from 'vue';
import { formatUrls, formatTime } from '@/common/utils';
import { sendLog } from '~/common/radar';

export default defineComponent({
  components: {
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 0,
    },
    playTime: {
      type: Number,
      default: 0,
    },
    volume: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: 'video-detail',
    },
    kwaiId: {
      type: String,
      default: '',
    },
    showControl: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as { proxy: any }
    const muted = ref<boolean>(props.muted);
    const paused = ref(props.paused);

    const duration = ref(props.duration);

    const isMouseProgressDown = ref(false);
    const isMouseVolumeDown = ref(false);

    const progressBar = ref();
    const volumeBar = ref();

    const progressPercent = ref(props.playTime / props.duration);

    const volumePercent = ref(props.volume);

    const showVolume = ref(false);

    const volumeClientY = ref(0)
    const progressClientX = ref(0)
    const tempPercent = ref(0)

    const handleMuted = () => {
      // muted.value = !muted.value
      sendLog({
        type: 'click',
        name: 'BUTTOM_AREA',
        value: {
          button_type: 'silence'
        }
      })
      ctx.emit('mute', !muted.value, volumePercent.value);
      proxy.$bus.emit("video-muted", !muted.value);

    }

    const handlePaused = () => {
      if (props.type === 'foryou') {
        sendLog({
          type: 'click',
          name: 'INTERACT_AREA',
          value: {
            button_type: !props.paused ? 'suspend' : 'play',
            author_id: props.kwaiId
          }
        });
      } else {
        sendLog({
          type: 'click',
          name: 'BUTTOM_AREA',
          value: {
            button_type: 'play'
          }
        });
      }
      ctx.emit('play');
    }

    const handleProgressClick = (e: any) => {
      if (e.srcElement?.className === 'progress-ball') return;
      ctx.emit('progressClick', e?.offsetX / progressBar.value.clientWidth || 0);
    }

    const handleMouseDownProgressBall = (e: any) => {
      isMouseProgressDown.value = true
      progressClientX.value = e.clientX
      tempPercent.value = progressPercent.value
    }

    const handleVolumeClick = (e: any) => {
      if (e.srcElement?.className === 'volume-ball') return;
      volumePercent.value = e?.offsetY / volumeBar.value.clientHeight || 0
      ctx.emit('volumeClick', volumePercent.value);
      proxy.$bus.emit("video-volume", volumePercent.value);
      // console.log('volumeClick', e)
    }

    const handleMouseDownVolumeBall = (e: any) => {
      isMouseVolumeDown.value = true
      volumeClientY.value = e.clientY
      tempPercent.value = volumePercent.value
      // console.log('handleMouseDownVolumeBall', e, volumePercent.value)
    }

    const handleMouseUpBall = (e: any) => {
      if (isMouseProgressDown.value) {
        ctx.emit('progressClick', progressPercent.value);
        isMouseProgressDown.value = false
      }
      if (isMouseVolumeDown.value) {
        ctx.emit('volumeClick', volumePercent.value);
        proxy.$bus.emit("video-volume", volumePercent.value);
        isMouseVolumeDown.value = false;
        handleVolumeMouseout(e)
        // console.log('handleMouseUpBall', e, volumePercent.value)
      }
    };

    const handleMouseMove = (e: any) => {
      if (isMouseProgressDown.value) {
        // if(e.srcElement?.className==='progress-ball') return;
        //  控制条总长度
        const width = progressBar.value.clientWidth
        // progressPercent.value = (e.offsetX) / width;
        //  每次移动距离
        const moveWidth = e.clientX - progressClientX.value
        //  更新基准位置
        progressClientX.value = e.clientX
        //  计算新控制条长度
        tempPercent.value = (moveWidth / width) + tempPercent.value
        //  更新值
        progressPercent.value = tempPercent.value > 1 ? 1 : tempPercent.value < 0 ? 0 : tempPercent.value
      }
      if (isMouseVolumeDown.value) {
        // if(e.srcElement?.className ==='volume-ball') return;
        const height = volumeBar.value.clientHeight
        const moveHeight = volumeClientY.value - e.clientY
        volumeClientY.value = e.clientY
        tempPercent.value = (moveHeight / height) + tempPercent.value
        volumePercent.value = tempPercent.value > 1 ? 1 : tempPercent.value < 0 ? 0 : tempPercent.value
        ctx.emit('volumeClick', volumePercent.value);
      }
    }

    const timemout = ref<any>(null)
    const handleVolumeMouseout = (e: any) => {
      // console.log('handleVolumeMouseout', e)
      if (e.toElement?.className.indexOf('volume') !== -1) return
      if (isMouseVolumeDown.value) return
      timemout.value = setTimeout(() => {
        showVolume.value = false
      }, 300)
    }

    const handleVolumeMouseenter = (e: any) => {
      // console.log('111handleVolumeMouseenter', e)
      clearTimeout(timemout.value);
      showVolume.value = true
    }


    watch(() => props.muted, (val: boolean) => {
      muted.value = val
    })

    watch(() => props.volume, (val: number) => {
      volumePercent.value = val
    })

    watch(() => props.paused, (val: boolean) => {
      paused.value = val
    })

    watch(() => props.duration, (val: number) => {
      duration.value = val;
      progressPercent.value = props.playTime / val
    })

    watch(() => props.playTime, (val: number) => {
      if (!isMouseProgressDown.value) progressPercent.value = val / props.duration
    })

    onMounted(() => {
      document?.addEventListener('mouseup', handleMouseUpBall);
      document?.addEventListener('mousemove', handleMouseMove)
    })

    onUnmounted(() => {
      document?.removeEventListener('mouseup', handleMouseUpBall);
      document?.removeEventListener('mousemove', handleMouseMove)
    })

    return {
      formatUrls,
      muted,
      handleMuted,
      paused,
      handlePaused,
      formatTime,
      duration,
      handleProgressClick,
      progressBar,
      volumeBar,
      handleMouseDownProgressBall,
      handleMouseUpBall,
      handleMouseMove,
      progressPercent,
      showVolume,
      volumePercent,
      handleVolumeClick,
      handleMouseDownVolumeBall,
      handleVolumeMouseout,
      handleVolumeMouseenter
    };
  },
});
