
import { defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { sendLog } from '@/common/radar';
import { getQuery, repalacePath } from '@/common/utils';
import { extractTitleFromMetaInfo } from '@/common/seo';
import { SEOApi } from '~/api/seo';

export default defineComponent({
    components: {},
    props: {
        pageType: {
            type: String,
            default: 'normal',
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;
        const query = getQuery();

        const inputValue = ref<string>(proxy.$store.state.searchValue);
        const onSearch = (val: string, event: any) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('search', val);
            if (inputValue.value === '' || inputValue.value === undefined) {
                proxy.$message.open({
                    icon: null,
                    duration: 1,
                    content: (h: any) =>
                        h(
                            'div',
                            {
                                style: {
                                    background: 'rgba(44, 44, 44, 0.72)',
                                    width: '880px',
                                    height: '44px',
                                    borderRadius: '8px',
                                    lineHeight: '44px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    color: '#FFF',
                                },
                            },
                            proxy.$root.$t('k_436042')
                        ),
                });
                return;
            }
            if (props.pageType === 'video') {
                proxy.$router.push({ path: `/discover/${inputValue.value}`, query: { ...query, page_source: 'detail', from_user: true } });
            } else {
                proxy.$store.commit('setSearchValue', inputValue.value);
                if (location.pathname.indexOf('discover') !== -1) {
                    repalacePath(`/discover/${inputValue.value}`, { tabName: '', from_user: true });
                } else {
                    proxy.$router.push({ path: `/discover/${inputValue.value}`, query: { ...query, page_source: 'discover', from_user: true } });
                }
                replaceTitle(`https://${window?.location?.host}/discover/${inputValue.value}` || '');
            }
            if (event?.type === 'click') {
                sendLog({
                    type: 'click',
                    name: 'TOP_CLICK_AREA',
                    value: {
                        button_type: 'search_icon',
                    },
                });
            }
        };

        const replaceTitle = async (fullPath: string) => {
            const res = await SEOApi.getMetaInfo({ url: fullPath });
            if (res.status === 200) {
                document.getElementsByTagName('title')[0].innerText = extractTitleFromMetaInfo({ ctx, metaInfoData: res?.data || '' });
            }
        };

        const handleInputClick = () => {
            if (props.pageType === 'video') {
                sendLog({
                    type: 'click',
                    name: 'TOP_AREA',
                    value: {
                        button_type: 'search',
                    },
                });
            } else {
                sendLog({
                    type: 'click',
                    name: 'TOP_CLICK_AREA',
                    value: {
                        button_type: 'search_input',
                    },
                });
            }
        };
        watch(
            () => proxy.$store.state.searchValue,
            (val: string) => {
                inputValue.value = val;
            },
            { deep: true }
        );

        onMounted(() => {
            inputValue.value = proxy.$store.state.searchValue;
        });

        return {
            inputValue,
            onSearch,
            handleInputClick,
        };
    },
});
