
import { computed, defineComponent, ref, onMounted, getCurrentInstance, watch } from 'vue';
import HotSvg from './components/hotSvg.vue';
import ForYouSvg from './components/forYou.vue';
import ComedySvg from './components/comedySvg.vue';
import FilmSvg from './components/filmSvg.vue';
import LiferecordSvg from './components/liferecordSvg.vue';
import MusicSvg from './components/musicSvg.vue';
import NewsSvg from './components/newsSvg.vue';
import SportsSvg from './components/sportsSvg.vue';
import TrendingStylesSvg from './components/trendingStylesSvg.vue';
import { sendLog, getKwaiLog } from '@/common/radar';
import { socialList } from '@/common/social';

export default defineComponent({
    name: 'inner-page',
    components: {
        HotSvg,
        ForYouSvg,
        ComedySvg,
        FilmSvg,
        LiferecordSvg,
        MusicSvg,
        NewsSvg,
        SportsSvg,
        TrendingStylesSvg,
    },
    props: {
        selectedSlide: {
            type: String,
            require: true,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const origin = ref('');
        const selectedKeys = ref<Array<string>>([]);
        const isSnack = computed(() => {
            return proxy.$store.state.isSnack;
        });
        const menuClick = (element: string, key: string) => {
            sendLog({
                type: 'click',
                name: 'LEFT_CLICK_AREA',
                value: {
                    button_type: key || 'For You',
                },
            });
            ctx.emit('menuClick', key);
        };

        const leftTablist = proxy.$store.state.leftTablist;
        const currentLang = computed(() => proxy.$root.$i18n.locale);
        const creatormarketplaceUrl = computed(() => {
            if (isSnack.value) {
                return 'https://creatormarketplace.snackvideo.com';
            }
            return 'https://creatormarketplace.kwai.com';
        });
        const emailUrl = computed(() => {
            if (isSnack.value) {
                return 'mailto:user-service@snackvideo.com';
            }
            return 'mailto:customer-service@kwai.com';
        });
        const realitySocialList = computed(() => {
            if (isSnack.value) {
                return socialList.snack[currentLang.value] || socialList.snack.en;
            }
            return socialList.kwai[currentLang.value] || socialList.snack.en;
        });
        const clickGo = (url: string) => {
            window.open(url, '_blank');
        };

        onMounted(() => {
            origin.value = window.location.origin;
            if (proxy.$route.params && !proxy.$route.params.content && proxy.$route.path.indexOf('discover') !== -1) {
                selectedKeys.value = ['For You'];
            }
        });

        const handleClick = (str: string, logKey: string) => {
            sendLog({
                type: 'click',
                name: 'KWAI_AGREEMENT',
                value: {
                    key: logKey,
                },
            });
            let url = `${origin.value}${str}`;
            if (/^http/.test(str)) {
                url = str;
            }

            window.open(url, '_blank');
        };

        watch(
            () => props.selectedSlide,
            val => {
                selectedKeys.value = [(val as string) || 'For You'];
            },
            { immediate: true }
        );

        return {
            menuClick,
            handleClick,
            origin,
            isSnack,
            defaultColor: '#000000',
            activiteColor: '#FF4906',
            selectedKeys,
            leftTablist,
            realitySocialList,
            currentLang,
            clickGo,
            emailUrl,
            creatormarketplaceUrl,
        };
    },
});
