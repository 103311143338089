
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref,reactive } from 'vue';
import { formateNum,formateTime, checkSnack } from '@/common/utils'
import Load from './loading.vue'
import RootComment from './rootComment.vue'
import { sendLog } from '~/common/radar';

export default defineComponent({
  components: {
    Load,
    RootComment,
  },
  props: {
    photoId: {
        type: String,
        default: ''
    },
  },
  setup(props, ctx: SetupContext) {
    const { proxy } = getCurrentInstance() as any
    const isSnack = proxy.$store.state.isSnack
    const commentsList=ref<any>([])
    const isSearchComments=ref<boolean>(false)

    const cursorComment=ref<string>('')
    const cursorSubComment=reactive<any>({})
    const cursorRootComment=ref<string>('')

    //保存所有主评论的子评论个数
    const subCommentCountInRoot=ref<any>({})
    const isHasMoreComments=ref<boolean>(true)

    const isError = ref(false);
    
    //获取根评论
    const getRootComments = async ({rootCommentCount=10,subCommentCount=2})=>{
        if(isSearchComments.value || cursorRootComment.value === 'no_more') return;
        isError.value = false;
        try {
            isSearchComments.value = true
                
            let {result, rootComments, cursor='no_more'} = await proxy.$axios.$$post(`overseaAPI::/rest/o/w/pc/getComments/list`,{
                photo_id: props.photoId,
                cursor: cursorRootComment.value,
                rootCommentCount: rootCommentCount,
                subCommentCount: subCommentCount
            })
            
            if(result==1){                
                isSearchComments.value = false
                cursorRootComment.value = cursor
                rootComments && rootComments.forEach((item:any)=>{
                    subCommentCountInRoot[item.commentId]=item.subCommentCount?item.subCommentCount:0
                    cursorSubComment[item.commentId]=''
                        
                })
                rootComments&&commentsList.value.push(...rootComments)
                                
            }else{
                isSearchComments.value=false;
                isError.value = true;
            }
            if(cursor==="no_more"||!rootComments){
                isHasMoreComments.value=false
            }
        } catch (error) {
            isSearchComments.value=false
        }
      
    }
    
    //获取更多子评论
    const getMoreSubComments=async ({rootCommentId}:{rootCommentId:string})=>{
            
        if(isSearchComments.value||cursorSubComment[rootCommentId]==="no_more") return;
        try {
            isSearchComments.value=true
            let {result,subComments,cursor}= await proxy.$axios.$$post(`overseaAPI::/rest/o/w/pc/getSubComments/list`,{
                    photo_id: props.photoId,
                    cursor:cursorSubComment[rootCommentId],
                    rootCommentId,
                    subCommentCount:cursorSubComment[rootCommentId]?3:5
                })    
                if(result===1){
                    isSearchComments.value=false
                    commentsList.value.forEach((item:any)=>{
                        if(item.commentId===rootCommentId){                
                            if(cursorSubComment[rootCommentId]===''){
                                item.subComments.push(...subComments.slice(2))
                            } else {
                                item.subComments.push(...subComments)
                            }
                        }
                    })
                    cursorSubComment[rootCommentId] = cursor

                }else{
                    isSearchComments.value=false
                }
            
        } catch (error) {
            isSearchComments.value=false
        }
        // 46503831485285150

    }
    const handleClick = (type: string) => {
        sendLog({
            type: 'click',
            name: 'COMMENT_AREA',
            value: {
                button_type: type
            }
        })
        ctx.emit('showModal')
    }
    const getMoreComments=()=>{
        getRootComments({rootCommentCount:20,subCommentCount:2})
    }
    onMounted( () => {
        getRootComments({})

        const comment:HTMLElement = document.getElementById('comment') as HTMLElement;
        comment.onscroll=function(){
            //滚动条滚动时，距离顶部的距离
                const scrollTop = comment.scrollTop||document.body.scrollTop;
                //windowHeight是可视区的高度
                const windowHeight = comment.clientHeight || document.body.clientHeight;
                //scrollHeight是滚动条的总高度
                const scrollHeight = comment.scrollHeight||document.body.scrollHeight;
                // if(scrollTop > windowHeight) isShowToTop.value = true
                // else isShowToTop.value = false
                if (windowHeight >= scrollHeight) {
                    // ctx.emit('refresh')
                    // console.log('hahahahahhaahh-------到底了');
                    // getMoreComments()
                }
                //滚动条到底部的条件
                if(scrollTop+windowHeight+50 >= scrollHeight){
                    getMoreComments()
                    console.log('hahahahahhaahh-------到底了');
                    //  到底请求
                    // ctx.emit('refresh');/
                    // window.onscroll = '' 用于解除绑定
                }   
        }
    })

    return {
        isSnack,
        formateNum,
        handleClick,
        commentsList,
        formateTime,
        isHasMoreComments,
        getMoreSubComments,
        isSearchComments,
        subCommentCountInRoot,
        cursorSubComment,
        isError,
    }
  },
})
