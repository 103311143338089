
import { defineComponent, ref, getCurrentInstance, onMounted } from 'vue';
import cookies from 'js-cookie';
import ArticleDetail from './components/article-detail/index.vue';
import { checkSnack, commonPvParams } from '@/common/utils';
import { header, getDefaultTitle } from '@/common/seo';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { immediateSendLog, getKwaiLog } from '@/common/radar';
import { ArticleInfo } from './models/main';
import sendPageLogger from '@/common/page-logger';
import { ArticleApi } from '@/api/article';

export default defineComponent<any>({
    components: {
        ArticleDetail,
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };
        const fmp_time = Date.now();
        const showEmptyImg = ref(false);
        const articleInfo = ref<ArticleInfo>();
        sendPageLogger('article');

        onMounted(() => {
            const webLogger = getKwaiLog();
            try {
                webLogger?.plugins?.radar?.fmp(fmp_time);
            } catch (error) {
                console.log('error: ', error);
            }

            articleInfo.value = proxy.articleDetail;

            sendPv();
            if (!articleInfo.value?.article_id_str) {
                showEmptyImg.value = true;
            }
        });

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'article' });
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');
            const article_id = articleInfo.value?.article_id_str || '';
            immediateSendLog({
                type: 'pv',
                name: 'PC_ARTICLE_PAGE',
                value: {
                    country,
                    user_id,
                    article_id,
                    ...params,
                },
            });
        };

        return {
            showEmptyImg,
            articleInfo,
        };
    },
    async asyncData(ctx: any) {
        const metaInfoData = [];
        let articleDetail = {};
        let title = getDefaultTitle(ctx);
        let isServerFetched = false;

        const articleId = ctx?.route?.params?.content || '';
        if (!articleId) return ctx.redirect('/foryou');
        const { isSnack } = checkSnack(ctx);

        if (process.server) {
            try {
                isServerFetched = true;
                setCommonParamsCookiesInServer({
                    bucket: ctx.store.state.startup.bucket,
                    host: ctx.req.headers.host,
                    ctx,
                    countryInfo: ctx.store.state.startup.countryInfo,
                });

                const articleRes = await ArticleApi.getArticleDetail({ article_id: articleId });

                if (articleRes?.result === 1) {
                    articleDetail = articleRes.data;
                    title = articleRes.data?.title;
                    metaInfoData.push({
                        name: 'description',
                        hid: 'description',
                        content: articleRes.data?.description || (isSnack ? 'Snack' : 'Kwai'),
                    });
                    metaInfoData.push({
                        name: 'keywords',
                        content: articleRes.data?.keywords || (isSnack ? 'Snack' : 'Kwai'),
                    });
                    metaInfoData?.push({
                        property: 'og:title',
                        hid: 'og:title',
                        content: articleRes.data?.title || (isSnack ? 'Snack' : 'Kwai'),
                    });
                    metaInfoData?.push({
                        property: 'og:description',
                        hid: 'og:description',
                        content: articleRes.data?.description || (isSnack ? 'Snack' : 'Kwai'),
                    });
                    metaInfoData?.push({
                        hid: 'og:site_name',
                        property: 'og:site_name',
                        content: isSnack ? 'Snack' : 'Kwai',
                    });
                }
            } catch (error) {
                console.log('error: ', error);
            }
        }

        return {
            isServerFetched,
            metaInfoData,
            title,
            articleDetail,
        };
    },
    head() {
        const head = header(this) as any;

        return {
            ...head,
        };
    },
});
