
import { defineComponent, SetupContext, getCurrentInstance, PropType, computed } from 'vue';
import { formateNum, roundingNum } from '@/common/utils';
import Like from '@/components/VideoCard/components/svgs/like.vue';
import Comment from '@/components/VideoCard/components/svgs/comment.vue';
import Share from '@/components/VideoCard/components/svgs/share.vue';
import { ArticleActionType, ArticleInfo } from '../../models/main';
import { sendLog } from '~/common/radar';

export default defineComponent({
    components: {
        Like,
        Comment,
        Share,
    },
    props: {
        articleInfo: {
            type: Object as PropType<ArticleInfo>,
            default: () => ({}),
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const handleItemClick = (item: ArticleActionType) => {
            sendLog({
                type: 'click',
                name: 'ARTICLE_BUTTON',
                value: {
                    source: 'pc',
                    button_type: item,
                },
            });
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('handleItemClick', item);
        };

        const likeCount = computed(() => {
            const count = props.articleInfo?.like_count;
            return count ? roundingNum(count) : proxy.$root.$t('k_335878');
        });

        const commentCount = computed(() => {
            const count = props.articleInfo?.comment_count;
            return count ? roundingNum(count) : proxy.$root.$t('Comment');
        });

        const shareCount = computed(() => {
            const count = props.articleInfo?.forward_count;
            return count ? roundingNum(count) : proxy.$root.$t('k_467899');
        });

        return {
            handleItemClick,
            formateNum,
            likeCount,
            commentCount,
            shareCount,
            ArticleActionType,
        };
    },
});
