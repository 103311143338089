
import { defineComponent, ref, watch } from 'vue';
import { codeList } from './countrycode';

type CountryCode = {
    cn: string;
    en: string;
    phone_code: string;
};

export default defineComponent({
    name: 'user-info',
    components: {},
    props: {},
    emits: ['updatedPhoneNumber'],
    setup(props, ctx) {
        const showOptionList = ref(false);
        const selectedOption = ref<string | null>(null);
        const defaultOption = ref('Brazil');
        const inputValue = ref('');
        const showClearButton = ref(false);
        const numberError = ref(false);
        const chinaPhoneRegex = /^(?:\d{3}-?\d{8}|\d{4}-?\d{7}|1[3-9]\d{9})$/;

        const toggleOpacity = () => {
            showOptionList.value = !showOptionList.value;
        };

        const setSelectedOption = (options: string) => {
            selectedOption.value = options;
            toggleOpacity();
        };

        const toggleClearButton = () => {
            const cleanedInput = inputValue.value.replace(/\D/g, '').trim().slice(0, 25);
            showClearButton.value = cleanedInput.length > 0;
            inputValue.value = cleanedInput;
        };

        const clearInput = () => {
            inputValue.value = '';
            showClearButton.value = false;
        };

        const validatePhoneNumber = () => {
            if (!chinaPhoneRegex.test(inputValue.value)) {
                numberError.value = true;
            } else {
                numberError.value = false;
            }
        };

        const findCountry = () => {
            const target = selectedOption.value === null ? defaultOption.value : selectedOption.value;
            const obj = codeList.find(v => v.en === target) as CountryCode;
            return `${obj.phone_code} `;
        };

        watch(
            [() => inputValue.value, () => selectedOption.value],
            ([newValue]) => {
                const code =
                    codeList.find(v => (selectedOption.value ? v.en === selectedOption.value : v.en === defaultOption.value))?.phone_code || '';
                const phone = newValue ? code + newValue : '';
                ctx.emit('updatedPhoneNumber', phone);
            },
            {
                immediate: true,
            }
        );

        return {
            codeList,
            inputValue,
            numberError,
            defaultOption,
            showOptionList,
            selectedOption,
            showClearButton,
            clearInput,
            findCountry,
            toggleOpacity,
            toggleClearButton,
            setSelectedOption,
            validatePhoneNumber,
        };
    },
});
