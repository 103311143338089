import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2c1d45cc&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=2c1d45cc&prod&lang=stylus&scoped=true"
import style1 from "./index.vue?vue&type=style&index=1&id=2c1d45cc&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1d45cc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentConvert: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/ContentConvert/index.vue').default,VideoPlayer: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/VideoPlayer/index.vue').default,Loading: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/Loading/index.vue').default})
