var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"mod",staticClass:"embedModal"},[_c('a-modal',{directives:[{name:"log",rawName:"v-log",value:({
      type: 'show',
      name: 'GET_KWAI_APP',
    }),expression:"{\n      type: 'show',\n      name: 'GET_KWAI_APP',\n    }"}],staticClass:"model",attrs:{"bodyStyle":{
      // height: '450px',
      width: '605px',
      padding:'0px',
      display: 'flex',
      flexDirection: 'columns ',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },"width":"605px","footer":null,"visible":_vm.visible,"getContainer":() => _vm.$refs.mod},on:{"ok":_vm.handleClose}},[_c('template',{slot:"title"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('k_430654').replace('${0}',_vm.isSnack ?'Snack':'Kwai')))])]),_vm._v(" "),_c('template',{slot:"closeIcon"},[_c('div',{staticClass:"closeIcon",on:{"click":_vm.handleClose}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M17.2278 17.2273C17.5907 16.8644 17.5907 16.2761 17.2278 15.9132L11.3147 10.0001L17.2284 4.08646C17.5913 3.72357 17.5913 3.1352 17.2284 2.77231C16.8655 2.40942 16.2771 2.40942 15.9142 2.77231L10.0006 8.68594L4.08632 2.77168C3.72343 2.40879 3.13506 2.40879 2.77217 2.77168C2.40928 3.13457 2.40928 3.72294 2.77217 4.08583L8.68643 10.0001L2.7727 15.9138C2.40981 16.2767 2.40981 16.8651 2.7727 17.228C3.1356 17.5909 3.72396 17.5909 4.08685 17.228L10.0006 11.3142L15.9137 17.2273C16.2766 17.5902 16.8649 17.5902 17.2278 17.2273Z","fill":"#999999"}})])])]),_vm._v(" "),_c('div',{staticClass:"model-content",style:({ display: 'block' })},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-img"},[_c('img',{attrs:{"src":_vm.poster,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('k_446017')))]),_vm._v(" "),_c('div',{staticClass:"copy-content"},[_vm._v(_vm._s(_vm.content))]),_vm._v(" "),_c('button',{staticClass:"copy-btn",on:{"click":_vm.copyCode}},[_vm._v(_vm._s(_vm.$t('k_446018')))]),_vm._v(" "),_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.handleText())}})])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }