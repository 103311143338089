var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-container"},[(_vm.tymlItemList?.terms?.length)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"list"},_vm._l((_vm.tymlItemList?.terms),function(item,index){return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
        type: 'show',
        name: 'RELATED_SEARCH',
        value: {
          source: 'pc',
          keyword: item?.text ?? ''
        }
      }),expression:"{\n        type: 'show',\n        name: 'RELATED_SEARCH',\n        value: {\n          source: 'pc',\n          keyword: item?.text ?? ''\n        }\n      }"}],key:`${item?.text ?? ''}${index}`,staticClass:"item",on:{"click":function($event){return _vm.handleClickItem(item)}}},[_c('a',{staticClass:"link",attrs:{"href":`${_vm.origin}${_vm.jumpLink(item)}`}},[_c('img',{staticClass:"picture",attrs:{"src":item?.image_url,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"picture-cover"},[_c('div',{staticClass:"card-text"},[_vm._v(_vm._s(item?.text))])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }