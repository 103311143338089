class ClassSafetyLocalStorage {
    setItem(...args: Parameters<typeof localStorage.setItem>) {
        try {
            window.localStorage.setItem(...args);
        } catch {
            // do nothing
        }
    }

    getItem(...args: Parameters<typeof localStorage.getItem>) {
        try {
            return window.localStorage.getItem(...args);
        } catch {
            return null;
        }
    }

    removeItem(...args: Parameters<typeof localStorage.removeItem>) {
        try {
            return window.localStorage.removeItem(...args);
        } catch {
            // do nothing
        }
    }
}

export const SafetyLocalStorage = new ClassSafetyLocalStorage();
