/* eslint-disable import/named */
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';

import axiosInstance from './request';

/** *** Api  *** */
interface GetQrCodeRequest {
    appId: string;
    scope: string;
    nonceStr: string;
    timeStamp: number;
    signature: string;
}

interface GetQrCodeResponse extends ResponseBase {
    qrCodePassport?: string;
    qrImageUrl?: string;
    expireTime?: number;
}

interface CheckStateRequest {
    appId: string;
    nonceStr: string;
    timeStamp: number;
    signature: string;
    qrCodePassport: string;
    expireTime: number;
}

interface CheckStateResponse extends ResponseBase {
    code?: number;
    is_new: boolean;
    user_name?: string;
    headurl?: string;
    userId?: string;
    authTokenValue?: string;
    authTokenName?: string;
}

export class ScanApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getQrCode(data: GetQrCodeRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GetQrCodeResponse>('/rest/o/w/login/passport/onAuthGotQrCode', data, config);
        return res;
    }

    async checkState(data: CheckStateRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$get?.<CheckStateResponse>('/rest/o/w/login/passport/check', {
            ...config,
            params: data,
        });
        return res;
    }
}
