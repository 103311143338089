
import { defineComponent, getCurrentInstance, onMounted, PropType } from 'vue';
import { sendLog } from '@/common/radar';
import { formateNum, openNewWindow } from '@/common/utils';

export default defineComponent({
    name: 'search-content',
    components: {},
    props: {
        users: {
            type: Array as PropType<Array<Record<string, any> | undefined>>,
            default: () => [],
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const userClick = (kwaiID: string) => {
            sendLog({
                type: 'click',
                name: 'USER_INFORMATION',
                value: {
                    user_id: kwaiID,
                },
            });
            openNewWindow(`/@${kwaiID}?page_source=discover`);
        };

        onMounted(() => {
            // const webLogger = getKwaiLog();
        });

        return {
            // item,
            userClick,
            formateNum,
        };
    },
});
