import cookie from 'cookie'
import { serverHosts, isProduction } from '../utils/constants';
import { getKpn, paramsMap } from '@/common/common-params';
import { objectToCookieString } from '@/common/utils';

export default function (ctx) {
  const { $axios, req, store } = ctx
  let apiLogger
  if (process.server && isProduction) {
    apiLogger = require('../server/log').api;
    $axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        apiLogger(req, response.config, response);
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        apiLogger(req, error.config, error.response);
        return Promise.reject(error);
      }
    );
  }
  // 服务端发请求
  // // Add a request interceptor
  $axios.interceptors.request.use(
    function (config) {
      if (process.server) {
        const startUp = ctx.store.state.startup
        let kpn = getKpn({ bucket: startUp.bucket, host: ctx.req.headers.host });
        let commonParams = paramsMap[kpn]

        config.myStartTime = Date.now();
        config.withCredentials = true
        let matched = false;

        config.url = config.url.replace(/^(\w+)::/, (all, hostName) => {
          const host = serverHosts[hostName];
          if (host) {
            matched = true;
            return host;
          }
        });
        if (!matched) {
          throw new Error('axios url error', config.url);
        }
        const reqHeaders = req.headers;
        const serverHeaders = {};
        for (const key in reqHeaders) {
          if (key.startsWith('x-') || key.startsWith('ks-') || key === 'user-agent' || key === 'accept-language') {
            serverHeaders[key] = reqHeaders[key];
          }
        }
        config.headers = {
          ...serverHeaders,
          ...config.headers,
        };
        let configCookie = cookie.parse(config.headers.common.cookie || '')
        // 首次请求cookie 设置一下公共参数 加一下

        configCookie = { ...configCookie, ...commonParams }

        const cookieStr = objectToCookieString(configCookie)

        config.headers.common.cookie = cookieStr
      } else {
        config.url = config.url.split("::").slice(-1)[0];
      }

      return config;
    },
    function (error) {
      if (process.server && isProduction) {
        apiLogger(req, error.config);
      }
      // Do something with request error
      return Promise.reject(error);
    }
  );

  $axios.$$get = async (...args) => {
    try {
      console.log('get args', args, 'isServerSide', process.server)
      const res = await $axios.get(...args);
      return res.data;
    } catch (e) {
      return {
        result: 0 - (e.response?.status || 1),
        error_msg: '404',
      };
    }
  };
  $axios.$$post = async (...args) => {
    try {
      console.log('post args', args, 'isServerSide', process.server)
      const res = await $axios.post(...args);
      return res.data;
    } catch (e) {
      console.log('错误args', args)
      console.log('post 错误', e, 'isServerSide', process.server)
      return {
        result: 0 - (e.response?.status || 1),
        error_msg: '404',
      };
    }
  };
}

