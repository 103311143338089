
  import {
    defineComponent,
    getCurrentInstance,
    PropType,
  } from "vue";
  import {kwaiOrSnack} from '@/common/utils'
  import { sendLog } from "@/common/radar";
  
  export default defineComponent({
    name: "downloadModal",
    components: {},
    props: {
      downloadModel: {
        type: Boolean,
        default: false
      },
      hideDownloadModel:{
          type: Function as PropType<(event: MouseEvent) => void>,
          default:()=>{}
      },
      iconShow:{
        type:String,
        default:'kwai'
      }
    },
    setup(props, ctx) {
      const { proxy } = getCurrentInstance() as { proxy: any }
      const isKwaiOrSnack=kwaiOrSnack(proxy)

      const hideDownloadModel = () => {
        ctx.emit('close');
        sendLog({
            type: 'click',
            name: 'PC_SEO_SEARCH_DOWNLOAD_POPUP',
            value: {
                button_type: 'close'
            }
        });
        sendLog({
                type: 'click',
                name: 'DOWNLOAD_POP',
                value: {
                }
        })
      }

      return {
        isKwaiOrSnack,
        hideDownloadModel
      }
    }
  });
