var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
    type: 'show',
    name: 'PHOTO_TEXT_CARD',
    value: {
      ..._vm.cardCommonParams
    }
  }),expression:"{\n    type: 'show',\n    name: 'PHOTO_TEXT_CARD',\n    value: {\n      ...cardCommonParams\n    }\n  }"}],staticClass:"picture-card",on:{"click":_vm.handlePictureClick,"mouseover":function($event){_vm.isHovered=true},"mouseleave":function($event){_vm.isHovered=false}}},[_c('a',{staticClass:"link",attrs:{"href":`${_vm.origin}${_vm.jumpLink}`}},[_c('img',{staticClass:"picture-item",attrs:{"src":_vm.pictureUrl,"alt":""}})]),_vm._v(" "),(_vm.isHovered)?_c('div',{staticClass:"picture-item-mask"},[_c('div',{staticClass:"operation"},[_c('button',{staticClass:"share",on:{"click":function($event){$event.stopPropagation();return _vm.clickShareBtn.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('k_467899')))]),_vm._v(" "),_c('button',{staticClass:"download",on:{"click":function($event){$event.stopPropagation();return _vm.clickDownloadBtn.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('k_1214196')))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }