import { render, staticRenderFns } from "./video.vue?vue&type=template&id=2bd0c99e&scoped=true"
import script from "./video.vue?vue&type=script&lang=ts"
export * from "./video.vue?vue&type=script&lang=ts"
import style0 from "./video.vue?vue&type=style&index=0&id=2bd0c99e&prod&lang=stylus&scoped=true"
import style1 from "./video.vue?vue&type=style&index=1&id=2bd0c99e&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd0c99e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/VideoPlayer/index.vue').default,ContentConvert: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/ContentConvert/index.vue').default})
