
import { defineComponent, ref, computed, onMounted, watch, onUnmounted, PropType, getCurrentInstance } from 'vue'
import { calcItemHeight, debounce } from '../utils'

interface SpaceOption {
  index: number
  item: any
  column: number
  top: number
  left: number
  bottom: number
  height: number
}

export default defineComponent({
  props: {
    // item间隔
    gap: {
      type: Number,
      default: 5,
    },
    // 容器内边距
    padding: {
      type: Number,
      default: 20,
    },
    // item最小宽度
    itemMinWidth: {
      type: Number,
      default: 220,
    },
    // 最大列数
    maxColumnCount: {
      type: Number,
      default: 10,
    },
    // 最小列数
    minColumnCount: {
      type: Number,
      default: 2,
    },
    // 数据
    items: {
      type: Array as PropType<Record<string, any>>,
      default: () => [],
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() || {}
    const contentHeight = ref(0)
    const contentWidth = ref(0)
    const contentRef = ref()
    const itemSpaces = ref<SpaceOption[]>([])

    const isNumber = (value: any) => {
      return Object.prototype.toString.call(value) === '[object Number]'
    }

    const handleResize = debounce(() => {
      if (contentRef.value?.clientWidth <= 0) {
        return
      }
      contentWidth.value = (contentRef.value?.clientWidth ?? 2 * props.padding) - 2 * props.padding
      computedItemSpaces()
    })

    // 计算列数
    const columnCount = computed(() => {
      if (!contentWidth.value) {
        return 0
      }
      const cWidth = contentWidth.value
      if (cWidth >= props.itemMinWidth * 2) {
        const count = Math.floor(cWidth / props.itemMinWidth)
        if (props.maxColumnCount && count > props.maxColumnCount) {
          return props.maxColumnCount
        }
        return count
      }
      return props.minColumnCount
    })

    // 每列距离顶部的距离
    const columnsTop = ref(new Array(columnCount.value).fill(0))

    // 计算每个item占据的宽度：（容器宽度 - 间隔）/列数
    const itemWidth = computed(() => {
      if (!contentWidth.value || columnCount.value <= 0) {
        return 0
      }
      // 列之间的间隔
      const gap = (columnCount.value - 1) * props.gap
      return Math.ceil((contentWidth.value - gap) / columnCount.value)
    })

    watch(() => props.items, () => {
      computedItemSpaces()
    }, { deep: true });

    onMounted(() => {
      contentWidth.value = (contentRef.value?.clientWidth ?? 2 * props.padding) - 2 * props.padding
      computedItemSpaces()
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    const computedItemSpaces = () => {
      // 如果列数为0，则不计算
      if (!columnCount.value) {
        itemSpaces.value = []
        return
      }

      const length = props.items.length
      const spaces = new Array(length)

      let start = 0
      // 是否启用缓存：只有当新增元素时，只需要计算新增元素的信息
      const cache = itemSpaces.value.length && length > itemSpaces.value.length
      if (cache) {
        start = itemSpaces.value.length
      } else {
        columnsTop.value = new Array(columnCount.value).fill(0)
      }

      // 为了高性能采用for-i
      for (let i = 0; i < length; i++) {
        if (cache && i < start) {
          spaces[i] = itemSpaces.value[i]
          continue
        }

        const columnIndex = getColumnIndex()
        // 计算元素的高度

        const h = calcItemHeight(proxy, props.items?.[i], itemWidth.value)
        const top = columnsTop.value[columnIndex]
        const left = (itemWidth.value + props.gap) * columnIndex

        const space = {
          index: i,
          item: props.items[i],
          column: columnIndex,
          top,
          left,
          bottom: top + h,
          height: h,
        }

        // 累加当前列的高度
        columnsTop.value[columnIndex] += h + props.gap
        spaces[i] = space
      }
      itemSpaces.value = spaces
    }

    // 获取当前元素应该处于哪一列
    const getColumnIndex = (): number => {
      return columnsTop.value.indexOf(Math.min(...columnsTop.value))
    }

    return {
      contentHeight,
      columnsTop,
      contentRef,
      isNumber,
      itemSpaces,
      itemWidth,
      computedItemSpaces,
    }
  },
})
