import locale9ec66352 from '../../locales/en.json'
import localeae97e51a from '../../locales/pt-BR.json'
import locale8db5ed1c from '../../locales/es.json'
import locale38517862 from '../../locales/ar.json'
import locale73254525 from '../../locales/id.json'
import locale057ad21c from '../../locales/zh.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.json","dir":"ltr"},{"code":"pt-BR","iso":"pt-BR","file":"pt-BR.json","dir":"ltr"},{"code":"es","iso":"es","file":"es.json","dir":"ltr"},{"code":"ar","iso":"ar","file":"ar.json","dir":"rtl"},{"code":"id","iso":"id","file":"id.json","dir":"ltr"},{"code":"pt-BR","iso":"pt-BR","file":"pt-BR.json","dir":"ltr"},{"code":"zh","iso":"zh","file":"zh.json","dir":"ltr"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.json","dir":"ltr"},{"code":"pt-BR","iso":"pt-BR","file":"pt-BR.json","dir":"ltr"},{"code":"es","iso":"es","file":"es.json","dir":"ltr"},{"code":"ar","iso":"ar","file":"ar.json","dir":"rtl"},{"code":"id","iso":"id","file":"id.json","dir":"ltr"},{"code":"pt-BR","iso":"pt-BR","file":"pt-BR.json","dir":"ltr"},{"code":"zh","iso":"zh","file":"zh.json","dir":"ltr"}],
  localeCodes: ["en","pt-BR","es","ar","id","pt-BR","zh"],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'pt-BR.json': () => Promise.resolve(localeae97e51a),
  'es.json': () => Promise.resolve(locale8db5ed1c),
  'ar.json': () => Promise.resolve(locale38517862),
  'id.json': () => Promise.resolve(locale73254525),
  'zh.json': () => Promise.resolve(locale057ad21c),
}
