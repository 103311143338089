// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/no_data.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article-container[data-v-723c4bcc] {\n  width: 100%;\n  height: 100%;\n}\n.article-container .article-info[data-v-723c4bcc] {\n  width: 100%;\n  height: 100%;\n}\n.article-container .no-article[data-v-723c4bcc] {\n  height: 160lrpx;\n  width: 160lrpx;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.article-container .no-article .icon[data-v-723c4bcc] {\n  width: 6.25vw;\n  height: 6.25vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;\n  margin: 0 auto;\n}\n.article-container .no-article .text[data-v-723c4bcc] {\n  font-size: 14lrpx;\n  line-height: 21lrpx;\n  text-align: center;\n  color: rgba(0,0,0,0.5);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
