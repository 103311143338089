import { IKwaiPlayer, KwaiPlayerEvent, PluginModule, } from '@ks-video/kwai-player-web/vue2-mini';
import PlcInfo from '@/components/PlcInfo/index.vue'
import Vue from 'vue'

// 新插件替换已有的logger插件
// export default class ExamplePlugin extends LoggerPluginModule {

// 新插件与已有插件共存
export default class PlcPlugin extends PluginModule {

    private _player: IKwaiPlayer | undefined;
    public container: HTMLDivElement | undefined;
    public static label = 'PlcPlugin';
    private static BOTTOM = 30;
    public isShowPlc: Boolean = false;
    public mouseDown = false;
    public mouseIsInVideo = false;
    public vueComponent = '';
    private options: any;
    private bottom = PlcPlugin.BOTTOM;

    /**
     * 插件id，获取插件，传入参数等操作的标识
     * 同名插件只会同时存在一个
     */
    public getLabel(): string {
        // LoggerPluginLabel
        return PlcPlugin.label;
    }

    private handlePlcShow = (isMouseIn: boolean, e: any): void => {
        
        //this.mouseIsInVideo = isShow;
        if(this.mouseDown) return
        if(isMouseIn) {
            this.bottom = 70;
        } else {
            this.bottom = PlcPlugin.BOTTOM;
        }
        //this.options.showPlc = isShow;
        (this.vueComponent as any)?.$forceUpdate();
    }

    /**
     * 播放器实例化前钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public beforeInstall(player: IKwaiPlayer, options?: any): void {
        this._player = player;
        this.container = player.createSubContainer();
        this.options = options;

        this.vueComponent = new Vue({
            el: this.container,
            render: (h)=> h(PlcInfo, {
                props: this.options,
                style: {
                    position: 'absolute',
                    bottom: `${this.bottom}px`,
                    left: '24px',
                }
            }),
        });

        (this._player as any)._mainDom?.addEventListener('mouseover', this.handlePlcShow.bind(this, true));
        (this._player as any)._mainDom?.addEventListener('mouseleave', this.handlePlcShow.bind(this, false));
    }

    /**
     * 播放器实例化后钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public afterInstall(_player: IKwaiPlayer, _options?: any): void {
        // console.log('debug afterInstall');
    }

    /**
     * 播放器销毁前钩子
     * @param player 播放器实例
     */
    public beforeUninstall(_player: IKwaiPlayer): void {
        // console.log('debug beforeUninstall');
    }

    /**
     * 播放器销毁后钩子
     * @param player 播放器实例
     */
    public afterUninstall(_player: IKwaiPlayer): void {
        // console.log('debug afterUninstall');
        (this._player as any)._mainDom?.removeEventListener('mouseover', this.handlePlcShow.bind(this, true));
        (this._player as any)._mainDom?.removeEventListener('mouseleave', this.handlePlcShow.bind(this, false));
    }
}
