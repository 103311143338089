var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
        type: 'show',
        name: 'PHOTO_CARD',
        value: {
            author_id: _vm.item.kwai_id,
            photo_id: _vm.item.photo_id_str,
            is_ad: _vm.isAd,
            card_position: _vm.index,
        },
    }),expression:"{\n        type: 'show',\n        name: 'PHOTO_CARD',\n        value: {\n            author_id: item.kwai_id,\n            photo_id: item.photo_id_str,\n            is_ad: isAd,\n            card_position: index,\n        },\n    }"}],staticClass:"video-card"},[_c('div',{staticClass:"header"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.item.headurls[0].url),expression:"item.headurls[0].url"}],staticClass:"avatar",attrs:{"alt":""},on:{"click":function($event){return _vm.userClick(_vm.item.kwai_id, 'photo')}}}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text",on:{"click":_vm.textClick}},[_c('span',{staticClass:"name",on:{"click":function($event){return _vm.userClick(_vm.item.kwai_id, 'name')}}},[_vm._v(_vm._s(_vm.item.user_name))]),_vm._v(" "),_c('FoldContent',{staticClass:"descript",attrs:{"show-ad":_vm.isAd}},[_c('ContentConvert',{attrs:{"is-ad":_vm.isAd,"content":_vm.item.caption,"page-source":'foryou'}})],1)],1),_vm._v(" "),_c('button',{staticClass:"follow-btn",on:{"click":_vm.handleFollowClick}},[_vm._v(_vm._s(_vm.$t('Follow')))])])]),_vm._v(" "),_c('div',{staticClass:"video-item"},[(_vm.index === _vm.playVideo)?_c('div',{on:{"click":_vm.handleVideoClick}},[_c('video-player',{directives:[{name:"show",rawName:"v-show",value:(_vm.canplay),expression:"canplay"}],ref:el => _vm.$emit('videoRef', el?.videoComp),staticClass:"video-player",style:({ width: _vm.getVideoWidth() + 'px' }),attrs:{"index":_vm.index,"item":_vm.item,"muted":_vm.muted,"loop":true,"volume":_vm.volume,"plugins":{
                    ContorlPlugin: {
                        muted: _vm.muted,
                        duration: 0,
                        playTime: 0,
                        paused: false,
                        type: 'foryou',
                        volume: _vm.volume,
                        kwaiId: _vm.item.kwai_id,
                    },
                    PlcPlugin: {
                        showPlc: _vm.isAd,
                        icon: _vm.item?.plcIcon,
                        desc: _vm.item?.plcText,
                        link: _vm.item?.feedLink,
                    },
                },"biz-data":_vm.bizData},on:{"loadeddata":_vm.handleCanplay,"ended":_vm.onEnded}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cover-container"},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.index === _vm.playVideo),expression:"index === playVideo"}],staticClass:"load"}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.index !== _vm.playVideo || !_vm.canplay),expression:"index !== playVideo || !canplay"},{name:"lazy",rawName:"v-lazy",value:(_vm.item?.cover_thumbnail_urls?.[0].url || _vm.item?.cover_first_frame_urls?.[0].url),expression:"item?.cover_thumbnail_urls?.[0].url || item?.cover_first_frame_urls?.[0].url"}],ref:"imgRef",staticClass:"cover",style:({ width: _vm.getVideoWidth() + 'px' }),attrs:{"id":`cover-${_vm.item?.photo_id_str}`,"alt":""},on:{"click":_vm.handleVideoClick}})],1),_vm._v(" "),_c('VideoAction',{staticClass:"action",attrs:{"item":_vm.item},on:{"action":_vm.handleVideoActionClick}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }