
import { defineComponent, computed, ref, getCurrentInstance, onActivated, onMounted, PropType } from 'vue'
import { copyToClipboard, displayToastMessage, getQuery } from '@/common/utils'
import { sendLog } from '~/common/radar';
import { Picture } from '~/api/topic';

export default defineComponent({
  props: {
    pictureItem: {
      type: Object as PropType<Picture>,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as any;

    const isHovered = ref(false)
    
    const origin = window?.location?.origin?? ''

    const jumpLink = computed(() => {
      return `/@${props.pictureItem?.kwai_id}/picture/${props.pictureItem?.pic_id_str}`
    })

    const pictureUrl = computed(() => {
      return props?.pictureItem?.pic_thumb_url
    })

    const cardCommonParams = computed(() => {
      return {
        pictrue_id: props?.pictureItem?.pic_id_str,
        card_position: props.index,
        type: 'picture',
        isMobile: 'false'
      }
    })

    const handlePictureClick = () => {
      sendLog({
        type: 'click',
        name: 'PHOTO_TEXT_CARD',
        value: {
          ...cardCommonParams.value,
          button_type: 'card'
        }
      })

      const query = getQuery() || {}
      proxy?.$store.commit('setClickedPicture', props.pictureItem);
      proxy?.$router.push({ path: jumpLink.value, query: { ...query, page_source: 'topic' } })
    }

    const clickShareBtn = () => {
      copyToClipboard(`https://${window.location.host}/@${(props.pictureItem as any)?.kwai_id}/picture/${(props.pictureItem as any)?.pic_id_str}`)
      displayToastMessage(proxy, proxy.$root.$t('k_446636'))
      
      sendLog({
        type: 'click',
        name: 'PHOTO_TEXT_CARD',
        value: {
          button_type: 'share',
          ...cardCommonParams.value
        }
      })
    }

    async function toDataURL(url: string) {
      const blob = await fetch(url).then((res) => res.blob())
      return URL.createObjectURL(blob)
    }

    async function downloadImage() {
      if (props.pictureItem?.pic_origin_url) {
        const a = document.createElement('a')
        a.href = await toDataURL(props.pictureItem.pic_origin_url)
        console.log('download clicked', props.pictureItem.pic_origin_url)
        a.download = `image_${props.pictureItem?.pic_id_str}.png`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }

    const clickDownloadBtn = () => {
      downloadImage()
      sendLog({
        type: 'click',
        name: 'PHOTO_TEXT_CARD',
        value: {
          button_type: 'download',
          ...cardCommonParams.value
        }
      })
    }

    onActivated(() => {
      isHovered.value = false
    })

    return { 
      pictureUrl,
      handlePictureClick, 
      isHovered,
      clickShareBtn,
      clickDownloadBtn,
      cardCommonParams,
      origin,
      jumpLink
    }
  }
})
