
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import { formateNum, copyToClipboard, getQuery } from '@/common/utils';

export default defineComponent({
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const handleClick = (type: string) => {};

        return {
            handleClick,
        };
    },
    head() {
        return {};
    },
});
