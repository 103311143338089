var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
    type: 'show',
    name: 'PHOTO_COVER',
    value: {
      photo_id: _vm.photoIdStr
    }
  }),expression:"{\n    type: 'show',\n    name: 'PHOTO_COVER',\n    value: {\n      photo_id: photoIdStr\n    }\n  }"}],staticClass:"card",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"images-warp"},[_c('img',{attrs:{"src":_vm.imgSrc,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"content-warp"},[_c('div',{staticClass:"content"},[_vm._v("\n      "+_vm._s(_vm.titleContent)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"info-warp"},[_c('div',{staticClass:"time"},[_vm._v("\n        "+_vm._s(_vm.secondsToMinutesAndSeconds(_vm.duration))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"play-count"},[_c('div',{staticClass:"img"}),_vm._v(" "),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.roundingNum(_vm.viewCount)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }