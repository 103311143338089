
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import { sendLog } from '@/common/radar';
import { formateNum } from '@/common/utils';

export default defineComponent({
    components: {},
    props: {
        userProfile: {
            type: Object,
            default: () => {},
        },
        profile: {
            type: Object,
            default: () => {},
        },
        ownerCount: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const showShare = ref(false);
        const isClickShare = ref(false);

        const handleShare = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('share');
            sendLog({
                type: 'click',
                name: 'USER_CARD',
                value: {
                    button_type: 'share',
                },
            });
        };

        const handleFollow = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('follow');
        };

        const onMouseOver = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('shareMouseover');
        };

        const onMouseOut = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('shareMouseout');
        };

        return {
            showShare,
            isClickShare,
            handleShare,
            handleFollow,
            onMouseOver,
            onMouseOut,
            formateNum,
        };
    },
});
