
import { ref, getCurrentInstance, computed, Ref } from 'vue';
import { getUrlKwaiId } from '@/common/utils'

export const useList = (
  {
    feeds,
    ownerCount,
    isBlockedByOwner,
    isBlocked,
  }:
    {
      feeds: Ref<Array<Record<string, any>>>,
      ownerCount: Ref<Record<string, any>>,
      isBlockedByOwner: Ref<boolean>,
      isBlocked: Ref<boolean>,
    }
) => {
  const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }
  const loading = ref(false)
  const finished = computed(() => cursor.value === 'no_more')
  const cursor = ref('')
  const onLoad = async () => {
    let kwaiId = getUrlKwaiId()
    const feedRes = await proxy.$axios.$$post('overseaAPI::/rest/o/w/pc/feed/profile', {
      userId: kwaiId,
      fromPage: 'PC_PROFILE',
      beforePage: '',
      pcursor: cursor.value,
      count: 20 // 单次请求的post数量
    });
    if (feedRes.result === 1) {
      cursor.value = feedRes.cursor
      feeds.value = [...feeds.value, ...feedRes.feeds]
    };
    loading.value = false;
  }

  const loadMorePost = () => {
    if(loading.value || cursor.value === 'no_more') return;
    loading.value = true;
    onLoad();
  }

  const getUserFeed = async () => {
    let kwaiId = getUrlKwaiId()
    return await proxy.$axios.$$post('overseaAPI::/rest/o/w/pc/feed/profile', {
      userId: kwaiId,
      fromPage: 'PWA_PROFILE',
      beforePage: '',
      count: 20 // 单次请求的post数量
    })
  }

  const postNum = computed(() => {
    if (isBlockedByOwner.value || isBlocked.value) {
      return 'X'
    }
    return ownerCount.value.photo_public || 0
  })

  const postText = computed(() => {
    if (postNum.value < 2) {
      return proxy.$root.$t('k_344305')
    }
    return proxy.$root.$t('k_335991')
  })

  return {
    cursor,
    finished,
    loading,
    postNum,
    loadMorePost,
    onLoad,
    getUserFeed,
    postText,
  };
};

