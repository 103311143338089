
import { defineComponent, ref, computed, getCurrentInstance, onMounted, watch } from 'vue';
import { formatUrls } from '@/common/utils';
import { radarLog } from '@/common/radar';

export default defineComponent({
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
                ('');
            },
        },
        plugins: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: 0,
        },
        muted: {
            type: Boolean,
            default: false,
        },
        volume: {
            type: [Number],
            default: 0,
        },
        slideIndex: {
            type: Number,
            default: 0,
        },
        bizData: {
            type: Object,
            default: () => {
                'pc-video-detail';
            },
        },
        loop: {
            type: Boolean,
            default: false,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const videoComp = ref<any>(null);
        const slideIndex = ref(props.slideIndex);
        const onClickVideo = (e: Event, refKey: string | number) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('video-click', e, refKey);
        };

        const onWaiting = (e: Event, refKey: string | number) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('video-waiting', e, refKey);
        };

        const onPlaying = (e: Event, refKey: string | number) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('video-playing', props.index, refKey);
        };

        const onError = (e: any) => {
            radarLog({
                name: 'PWA_PC_VIDEO_DETAIL_ERROR',
                event_type: 'click',
                category: 'KWAI_SHARE',
                message: `error url: ${props.item?.main_mv_urls[0]?.url}`,
                src: window?.location.pathname,
            });
        };

        const onEnded = (e: Event, key: string) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('ended', key);
        };

        const onPause = () => {
            console.log('onPause');
        };

        const onloadeddata = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('loadeddata', props.index);
        };

        const onCanPlay = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('canplay', props.index);
        };

        const newBizData = computed(() => {
            return {
                ...props.bizData,
                manifestH265AbTest: proxy.$store.state.startup.manifestH265AbTest,
                author_id: props.item?.user_id,
                photo_id: props.item?.photo_id_str,
                url: window.location.href,
            };
        });

        const manifest264 = computed(() => {
            return props.item.transcode_manifest_info;
        });

        const manifest265 = computed(() => {
            return props.item.transcode_manifest_info_h265;
        });

        const is264 = computed(() => {
            return proxy.$store.state.startup.manifestH265AbTest === 'h264';
        });
        const is265 = computed(() => {
            return proxy.$store.state.startup.manifestH265AbTest === 'h265';
        });

        watch(
            () => props.slideIndex,
            (val: number) => {
                slideIndex.value = val;
            }
        );

        return {
            videoComp,
            onClickVideo,
            onWaiting,
            onPlaying,
            formatUrls,
            onError,
            onEnded,
            onPause,
            onloadeddata,
            onCanPlay,
            manifest264,
            manifest265,
            is264,
            is265,
            newBizData,
        };
    },
});
