
import { defineComponent, ref, getCurrentInstance, onMounted, watch, PropType, nextTick } from 'vue'
import { formateNum, checkSnack } from '@/common/utils'
import VideoBoxs from '../video/index.vue'
import Load from './loading.vue'
import NotFound from './notFound.vue'
import Users from './user.vue'

export default defineComponent({
  name: 'search-content',
  components: {
    Load,
    VideoBoxs,
    NotFound,
    Users
  },
  props: {
    feeds: {
        type: Array,
        default: () => []
    },
    users: {
        type: Array as PropType<
        Array<{
            authorHeadUrl: string;
            authorName: string;
            kwaiId: string;
            followers: string;
            videos:string
        }>
      >,
      require: true,
        default: () => []
    },
    isSearching: {
        type: Boolean,
        default: () => false
    },
    serachWord: {
        type: String,
        default: ''
    },
    isShowNotFound:{
        type:Boolean,
        default:() => false
    },
    selectedSlide:{
        type: String,
        default: 'For You'
    },
    isHasMore:{
        type: String,
        default: 'has_more'
    },
    isPause: {
        type: Boolean,
        default: false
    },
  },
  setup(props, ctx) {            
        const { proxy } = getCurrentInstance() as { proxy: any }
        const playVideo = ref(0)
        const videoNum = ref(4)
        const userNum = ref(4)
        const feedsInfo = ref<any>(props.feeds)
        const isShowToTop = ref(false)
        const viewSumCount = ref(0)

        const videoRefs = ref<any>([])

        const { isSnack } = checkSnack(proxy)

        const showTitle=ref<String>(proxy.$root.$t('For You'))

        const bottomIcon=ref('Kwai')

        const getVideoPlayer = (index: number) => {
            const videoComp = videoRefs.value[index] as any
            return videoComp
        }

        const setVideoPlayerEnter = () => {
            nextTick(() => {
                const newPlayer = getVideoPlayer(playVideo.value);
                newPlayer?.onEnter();
            })
        }

        const mouseenter = (index: number) => {
            if(playVideo.value === index) return;
            const oldPlayer = getVideoPlayer(playVideo.value);
            oldPlayer?.onLeave()
            playVideo.value = index
            setVideoPlayerEnter()
        }


        const onEnded = () => {
            console.log('ended', playVideo.value)
            // const oldPlayer = getVideoPlayer(playVideo.value);
            // oldPlayer?.onLeave({})
            playVideo.value += 1
            setVideoPlayerEnter()
        }

        const toTop = () => {
            const searchContent:HTMLElement = document.getElementById('search-content') as any

            searchContent.scrollTo({behavior: "smooth", top: 0})
        }

        const handleVideoRef = (e:any, index:number) => {
            if(!e) return;
            videoRefs.value[index] = e;
        }

        const viewWidth = ref(0)

        const onVideoClick = (item: any, index: number) => {
            ctx.emit('videoClick', item, index)
        }

        
        onMounted(() => {
            const searchContent:HTMLElement = document.getElementById('search-content') as any
            viewWidth.value = searchContent.clientWidth - 128 > 1642 ? 1642 : searchContent.clientWidth - 128
            const num: any = Math.floor(viewWidth.value/216) *2
            videoNum.value = num > 14 ? 14 : num;
            const user_num: any = Math.floor(viewWidth.value/268) *2
            userNum.value = user_num > 12 ? 12: user_num
            
            if (proxy.$route.params&&proxy.$route.params.content) {
                showTitle.value=proxy.$route.params.content
            }else{
                showTitle.value= proxy.$root.$t(props.selectedSlide)
            }

            if(window.location.hostname.includes('snack')){
                bottomIcon.value='Snack'
            }else{
                bottomIcon.value='Kwai'
            }
            setVideoPlayerEnter()

            searchContent.onscroll = function(){
                //滚动条滚动时，距离顶部的距离
                const scrollTop = searchContent.scrollTop||document.body.scrollTop;
                //windowHeight是可视区的高度
                const windowHeight = searchContent.clientHeight || document.body.clientHeight;
                //scrollHeight是滚动条的总高度
                const scrollHeight = searchContent.scrollHeight||document.body.scrollHeight;
                if(scrollTop > windowHeight) isShowToTop.value = true
                else isShowToTop.value = false
                if (windowHeight >= scrollHeight) {
                    ctx.emit('refresh')
                }
                //滚动条到底部的条件
                if(scrollTop+windowHeight+50 >= scrollHeight){
                    //  到底请求
                    ctx.emit('refresh');
                    // window.onscroll = '' 用于解除绑定
                }   
            }
        })
        watch(()=>props.selectedSlide,(newValue)=>{
            showTitle.value=newValue
        })
        watch(() => props.feeds, (newValue) => {
            feedsInfo.value = newValue;
            viewSumCount.value = feedsInfo.value.map((el:any) => el.view_count).reduce((a:number,b:number) => {return a + b}, 0)
        },{deep: true, immediate: true})

        watch(() => props.isPause, (val: boolean) => {
            const player = getVideoPlayer(playVideo.value);
            if(val) player?.pause();
            else player?.play();
        })
        
        return {
            mouseenter,
            onEnded,
            playVideo,
            videoNum,
            userNum,
            feedsInfo,
            toTop,
            isShowToTop,
            viewSumCount,
            formateNum,
            showTitle,
            bottomIcon,
            isSnack,
            handleVideoRef,
            viewWidth,
            onVideoClick,
        }
  }
})
