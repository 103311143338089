
import { defineComponent, getCurrentInstance, watch, ref } from 'vue';
import { kwaiOrSnack } from '@/common/utils';
import { sendLog } from '@/common/radar';
import { UserApiService } from '~/api/user';
import { renderGoogleLoginButton, initGoogleAccountSDK } from './google';
import { initFBAccountSDK, onFacebookSign } from './facebook';

// 跳转url
const serviceUrl = 'https://www.kwai.com/legal?id=terms_service';
const privacyUrl = 'https://www.kwai.com/legal?id=privacy_policy';
type LoginOption = {
    icon: string;
    label: string;
    id?: string;
    onClick?: () => void;
};

export default defineComponent({
    name: 'login-modal',
    components: {},
    props: {
        loginModel: {
            type: Boolean,
            default: false,
        },
        iconShow: {
            type: String,
            default: 'kwai',
        },
        containerId: {
            type: String,
            default: 'login-popup-google-button',
        },
    },
    emits: ['close', 'scan'],
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };
        const isKwaiOrSnack = kwaiOrSnack(proxy);
        const timer = ref<number | null>(null);
        const phoneLogin = ref<boolean>(false);
        const mode = ref<string>('default');
        const UserApi = new UserApiService();

        const jump = (url: string) => {
            window.open(url);
        };

        const updateInterval = (id: number) => {
            if (timer.value) clearInterval(timer.value);
            timer.value = id;
        };

        // 关闭模态框
        const closeModal = () => {
            if (timer.value) clearInterval(timer.value);
            ctx.emit('close');
        };

        // 切换为扫码状态, 点击返回后会再次触发这个函数的逻辑
        const toggleScanLogin = () => {
            if (mode.value == 'scan') mode.value = 'default';
            else mode.value = 'scan';
        };

        // 切换为手机号登录组件
        const switchToMobileLogin = () => {
            mode.value = 'mobile';
        };

        const loginConfig: Record<string, LoginOption> = {
            google: {
                icon: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/pc/google_icon.png',
                label: 'k_1241455',
                id: props.containerId,
            },
            qrCode: {
                icon: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/pc/scan_icon.png',
                label: 'k_1241456',
                onClick: toggleScanLogin,
            },
            facebook: {
                icon: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/pc/facebook_icon.png',
                label: 'k_1245814',
                onClick: () => onFacebookSign(proxy, closeModal, UserApi),
            },
            mobile: {
                icon: 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/pc/phone_login.png',
                label: 'k_1259736',
                onClick: switchToMobileLogin,
            },
        };

        watch(
            [() => mode.value],
            () => {
                if (mode.value !== 'scan') {
                    const isLoadedGoogleScript = !!(window as any)?.google;
                    const isLoadedFBScript = !!(window as any)?.FB;
                    if (!isLoadedFBScript) initFBAccountSDK();
                    if (isLoadedGoogleScript) {
                        setTimeout(() => {
                            renderGoogleLoginButton(closeModal, props.containerId, proxy, UserApi);
                        }, 0);
                        return;
                    }
                    initGoogleAccountSDK(closeModal, props.containerId, proxy, UserApi);
                }
            },
            { immediate: true }
        );

        return {
            mode,
            proxy,
            serviceUrl,
            privacyUrl,
            loginConfig,
            phoneLogin,
            isKwaiOrSnack,
            jump,
            closeModal,
            updateInterval,
            onFacebookSign,
            toggleScanLogin,
            switchToMobileLogin,
        };
    },
});
