
import { defineComponent, ref, getCurrentInstance, onMounted, nextTick } from 'vue';
import VClamp from 'vue-clamp';

export default defineComponent({
    components: {
        VClamp,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        descript: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'normal',
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };

        const handleProfile = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('showModal');
        };

        onMounted(() => {});

        return {
            handleProfile,
        };
    },
});
