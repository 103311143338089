
import { defineComponent, ref, getCurrentInstance } from 'vue';
import { handleTagReg, handleCommentReg, handleUserReg, openNewWindow } from '@/common/utils';

export default defineComponent({
    components: {
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        pageSource: {
            type: String,
            default: 'profile',
        },
        isAd: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: any };

        const openTag = (event: any) => {
            if(props.isAd) return;
            if(event.target.className === 'tag') {
                        // proxy.$router.push({
                        //     path: `/discover/${event.target.innerText.split('#')[1]}`,
                        //     query: {
                        //         ...proxy.$route.query,
                        //         page_source: 'profile'
                        //     }
                        // });
                ctx.emit('element-click', {type: 'tag', name: event.target.innerText.split('#')[1]})
                openNewWindow(`/discover/${event.target.innerText.split('#')[1]}?page_source=${props.pageSource}`)
            } else if(event.target.className === 'user') {
                openNewWindow(`/${event.target.innerText}`)
            }
        }

        const handleContent = (content: string) => {
            let result = handleCommentReg(content);
            result = handleUserReg(result);
            result = handleTagReg(result);
            return result;
        }

        return {
            openTag,
            handleTagReg,
            handleContent
        };
    },
});
