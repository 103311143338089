
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import { formateNum, copyToClipboard, FeedType } from '@/common/utils';
import { sendLog } from '~/common/radar';
import EmbedModal from './embedModal.vue';

export default defineComponent({
  components: {
    EmbedModal,
  },
  props: {
    type: {
        type: String,
        default: 'normal',
    },
    item: {
        type: Object,
        default: () => {}
    },
    isShareEmbedDisabled:{
        type: Boolean,
        default: false
    }
  },
  setup(props, ctx: SetupContext) {
    const { proxy } = getCurrentInstance() as any

    const embedModalVisible = ref(false);

    const handleClick = (type: string) => {
        if(type === 'embed') {
            embedModalVisible.value = true
        }
        if (type === 'copylink') {
            let copyUrl = `https://${window.location.host}/@${(props.item as any)?.kwai_id}/video/${(props.item as any)?.photo_id_str}`

            if (props.type === 'profile') {
                copyUrl = `https://${window.location.host}/@${(props.item as any)?.kwai_id}`
            } else if (props.item.type === FeedType.PICTURE_CATEGORY_CARD) {
                // 如果是图文样式卡片分享，直接copy当前页面的url的剪切板
                copyUrl = `${window.location.href}`
            } else if (props.type === 'picture') {
                copyUrl = `https://${window.location.host}/@${(props.item as any)?.kwai_id}/picture/${(props.item as any)?.pic_id_str}`
            }

            copyToClipboard(copyUrl)
            proxy.$message.open(
                        {
                        icon:null,
                        duration:1,
                        content:(h: any)=>h('div',{
                            style:{
                            background: 'rgba(44, 44, 44, 0.72)',
                            width: '880px',
                            height:'44px',
                            borderRadius:'8px',
                            lineHeight:'44px',
                            textAlign:'center',
                            fontSize:'16px',
                            fontStyle:'normal',
                            fontWeight:400,
                            color:'#FFF',
                            }
                        },proxy.$root.$t('k_446636'))},
                        
            );
        }
        // ctx.emit('share', type);
        sendLog({
            type: 'click',
            name: 'SHARE_BUBBLE',
            value: {
                button_type: type === 'embed' ? 'embed' : 'copy_link'
            }
        })
    }

    const handleClose = () => {
      ctx.emit('close')
    }

    onMounted(() => {
    })

    return {
        formateNum,
        handleClick,
        handleClose,
        embedModalVisible,
        FeedType
    }
  },
})
