
import { defineComponent, computed, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import VideoAction from './components/videoAction/index.vue';
import FoldContent from './components/foldContent/index.vue';
import { sendLog } from '~/common/radar';

export default defineComponent({
    components: {
        VideoAction,
        FoldContent,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: 0,
        },
        playVideo: {
            type: Number,
            default: 0,
        },
        bizData: {
            type: Object,
            default: () => ({ pageSource: 'pc-foryou', page_code: 'PC_FOR_YOU_PAGE' }),
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;
        const isShowVideo = ref(false);
        const isAd = computed(() => {
            return props.item?.feedType === 'AD';
        });

        // const muted = ref(true);
        const muted = computed(() => {
            return proxy.$store.state.globalMuted;
        });

        const volume = ref(1);

        const videoRef = ref();

        const imgRef = ref();

        const onEnded = () => {};

        const handleAdClick = () => {
            if (isAd.value && props.item?.feedLink) {
                videoRef.value?.pause();
                window.open(props.item?.feedLink, '_blank');
            }
        };

        const textClick = () => {
            handleAdClick();
        };

        const userClick = (kwaiID: string, type: string) => {
            if (type === 'name' && isAd.value) return;
            sendLog({
                type: 'click',
                name: 'USER_INFORMATION',
                value: {
                    author_id: props.item.kwai_id,
                    button_type: type,
                },
            });
            window.open(`/@${kwaiID}`);
        };

        const handleFollowClick = () => {
            proxy.$bus.emit('show-model', true);
            sendLog({
                type: 'click',
                name: 'USER_INFORMATION',
                value: {
                    author_id: props.item.kwai_id,
                    button_type: 'follow',
                },
            });
        };

        const handleVideoActionClick = (type: string) => {
            if (type === 'like') {
                proxy.$bus.emit('show-model', true);
            } else {
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('video-action', type, props.index);
            }
        };

        const getVideoWidth = () => {
            const width = props.item?.ext_params.w;
            const height = props.item?.ext_params.h;
            if (!width || !height) return document.getElementById(`cover-${props?.item.photo_id_str}`)?.clientWidth || 377;
            const result = (width / height) * 672;
            return result > 597 ? 597 : result;
        };

        const handleVideoClick = (e: any) => {
            sendLog({
                type: 'click',
                name: 'PHOTO_CARD',
                value: {
                    author_id: props.item.kwai_id,
                    photo_id: props.item.photo_id_str,
                    is_ad: isAd.value,
                    card_position: props.index,
                },
            });
            if (isAd.value) {
                handleAdClick();
                return;
            }
            if (e?.srcElement?.className !== '_kwai-player-video-interactive_uxc1a_11' && e?.srcElement?.className !== 'cover') return;
            videoRef.value?.pause();
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('video-click', props.index);
        };

        const canplay = ref(false);
        const handleCanplay = (index: number) => {
            canplay.value = true;
        };

        onMounted(() => {
            proxy.$bus.on('video-muted', (val: boolean) => {
                // muted.value = val
                proxy.$store.commit('setGlobalMuted', val);
            });
            proxy.$bus.on('video-volume', (val: number) => {
                volume.value = val;
                // if (val === 0) muted.value = true
                // else muted.value = false
                if (val === 0) proxy.$store.commit('setGlobalMuted', true);
                else proxy.$store.commit('setGlobalMuted', false);
            });
        });

        watch(
            () => props.playVideo,
            (val: number) => {
                canplay.value = false;
            }
        );

        return {
            onEnded,
            userClick,
            handleFollowClick,
            handleVideoActionClick,
            handleVideoClick,
            isShowVideo,
            getVideoWidth,
            muted,
            volume,
            videoRef,
            handleCanplay,
            canplay,
            imgRef,
            isAd,
            textClick,
        };
    },
});
