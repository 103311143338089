import { IKwaiPlayer, KwaiPlayerEvent, PluginModule, } from '@ks-video/kwai-player-web/vue2-mini';
import Loading from '@/components/Loading/index.vue'
import Vue from 'vue'

// 新插件替换已有的logger插件
// export default class ExamplePlugin extends LoggerPluginModule {

// 新插件与已有插件共存
export default class LoadingPlugin extends PluginModule {

    private _player: IKwaiPlayer | undefined;
    public container: HTMLDivElement | undefined;

    public static label = 'LoadingPlugin';

    public isAddLoading: Boolean = false;

    /**
     * 播放器实例化前钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public beforeInstall(player: IKwaiPlayer, options?: any): void {
        this._player = player;
        // console.log('_player', player?._videoHeight,player?._videoWidth)

        this.container = player.createSubContainer();

        // console.log('debug beforeInstall', options, player, KwaiPlayerEvent);
        

        this._player.on(KwaiPlayerEvent.CANPLAY, this._onCanPlay);

        this._player.on(KwaiPlayerEvent.WAITING, this.addLoading);

    }

    private addLoading = (): void => {
        // if (document.querySelector('#loading')) this._player?.mainContainer?.appendChild(document.querySelector('#loading') as HTMLElement);
        if(this.isAddLoading) return;
        const app = new Vue({
          el: this.container?.appendChild(document.createElement('div')),
          render: (h)=> h(Loading),
        });
        this.isAddLoading = true;
    }

    private removeLoading = (): void => {
        // if (document.querySelector('#loading')) this._player?.mainContainer?.removeChild(document.querySelector('#loading') as HTMLElement)
        
        this._player?.removeSubContainer(this.container as HTMLDivElement)
        this.isAddLoading = false;
    }

    /**
     * 播放器实例化后钩子
     * @param player 播放器实例
     * @param options 实例化播放器时指定的当前插件的参数
     */
    public afterInstall(_player: IKwaiPlayer, _options?: any): void {
        console.log('debug afterInstall');
       this.addLoading();
    }

    /**
     * 播放器销毁前钩子
     * @param player 播放器实例
     */
    public beforeUninstall(_player: IKwaiPlayer): void {
        // console.log('debug beforeUninstall');
    }

    /**
     * 播放器销毁后钩子
     * @param player 播放器实例
     */
    public afterUninstall(_player: IKwaiPlayer): void {
        // console.log('debug afterUninstall');
        // this._player?.off(KwaiPlayerEvent.REPORT, this._onReport);
        // this._player?.off(KwaiPlayerEvent.ATTRIBUTE_CHANGE_SRC, this._onSrcChange);

        this._player?.off(KwaiPlayerEvent.CANPLAY, this._onCanPlay);

        this._player?.off(KwaiPlayerEvent.WAITING, this.addLoading);
    }

    /**
     * 插件id，获取插件，传入参数等操作的标识
     * 同名插件只会同时存在一个
     */
    public getLabel(): string {
        // LoggerPluginLabel
        return LoadingPlugin.label;
    }

    private _onReport = (e: { type: string, value: any }): void => {
        if (e.type === 'heartbeat') {
            // console.log('debug 实时上报：', e.value);
        }
        // console.log('example', e)
    }

    private _onSrcChange = (): void => {

    }

    private _onCanPlay = (): void => {
        // console.log('_onCanPlay', this._player, this._player?._videoHeight, this._player?._videoWidth)
        this.removeLoading();
    }
}
