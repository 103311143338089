
import { defineComponent, SetupContext, getCurrentInstance, nextTick, watch, onMounted, ref, PropType } from 'vue';
import { formateNum } from '@/common/utils';
import { sendLog } from '@/common/radar';

export default defineComponent({
    components: {},
    props: {
        feedsData: {
            type: Array as PropType<Array<Record<string, any> | undefined>>,
            default: () => [],
        },
        isPause: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const muted = ref(true);

        const playVideo = ref(0);

        const videoRefs = ref<any>([]);

        const openTag = (params: any, photo_id: string) => {
            sendLog({
                type: 'click',
                name: 'PHOTO_CARD',
                value: {
                    photo_id,
                    click_area: 'tag',
                    tag: params.name,
                },
            });
        };

        const handleVideoClick = (index: number, photo_id: string) => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('video-detail', index);
            sendLog({
                type: 'click',
                name: 'PHOTO_CARD',
                value: {
                    photo_id,
                    click_area: 'photo',
                    tag: 'photo',
                },
            });
        };

        const getVideoPlayer = (index: number) => {
            const videoComp = videoRefs.value[index] as any;
            return videoComp;
        };

        const setVideoPlayerEnter = () => {
            nextTick(() => {
                const newPlayer = getVideoPlayer(playVideo.value);
                newPlayer?.onEnter();
            });
        };

        const mouseenter = (index: number) => {
            if (playVideo.value === index) return;
            const oldPlayer = getVideoPlayer(playVideo.value);
            oldPlayer?.onLeave();
            playVideo.value = index;
            setVideoPlayerEnter();
        };

        const mouseleave = (index: number) => {};

        const handleMuted = () => {
            muted.value = !muted.value;
        };

        const onEnded = () => {
            playVideo.value += 1;
            setVideoPlayerEnter();
        };

        watch(
            () => props.isPause,
            (val: boolean) => {
                const player = getVideoPlayer(playVideo.value);
                if (val) player?.pause();
                else player?.play();
            }
        );

        return {
            formateNum,
            handleVideoClick,
            openTag,
            muted,
            onEnded,
            mouseenter,
            mouseleave,
            playVideo,
            handleMuted,
            videoRefs,
        };
    },
});
