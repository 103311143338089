
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref, nextTick } from 'vue';
import { formateNum, copyToClipboard, getQuery, FeedType } from '@/common/utils';
import Like from '../svgs/like.vue';
import Comment from '../svgs/comment.vue';
import Share from '../svgs/share.vue';
import ShareDialog from '@/components/Share/index.vue';
import { sendLog } from '~/common/radar';

export default defineComponent({
    components: {
        Like,
        Comment,
        Share,
        ShareDialog,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;

        const showShare = ref<boolean>(false);

        const handleClick = (type: string) => {
            sendLog({
                type: 'click',
                name: 'INTERACT_AREA',
                value: {
                    author_id: props.item.kwai_id,
                    button_type: type,
                    card_type: props.item?.type === FeedType.PICTURE_CATEGORY_CARD ? 'photo_text' : '',
                },
            });
            if (type === 'share') {
                showShare.value = true;
            } else {
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('action', type);
            }
        };

        const handleShare = () => {
            showShare.value = false;
        };

        const getCommentText = () => {
            // item.type为图文大卡，只展示评论文字，不展示评论数量
            if (props.item.type === FeedType.PICTURE_CATEGORY_CARD) {
                return proxy.$root.$t('Comment');
            }
            return formateNum(props?.item?.comment_count || 0);
        };

        return {
            handleClick,
            formateNum,
            handleShare,
            showShare,
            getCommentText,
        };
    },
    head() {
        return {};
    },
});
