var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"log",rawName:"v-log",value:({
    type: 'show',
    name: 'PHOTO_TEXT_CARD',
    value: {
      ..._vm.cardCommonParams
    }
  }),expression:"{\n    type: 'show',\n    name: 'PHOTO_TEXT_CARD',\n    value: {\n      ...cardCommonParams\n    }\n  }"}],staticClass:"card-container",on:{"mouseover":function($event){_vm.isHovered=true},"mouseleave":function($event){_vm.isHovered=false},"click":_vm.handleCardClick}},[_c('video-player',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered),expression:"isHovered"}],ref:"videoRef",staticClass:"video-player",attrs:{"index":_vm.index,"item":_vm.item,"muted":true,"loop":true,"autoplay":false,"bizData":{ 
      pageSource: 'pc-topic', 
      page_code: 'PC_TOPIC_PAGE' 
    },"plugins":{
      ContorlPlugin: {
        muted: true,
        paused: false
      }
    }}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered),expression:"isHovered"}],staticClass:"video-mask"}),_vm._v(" "),_c('a',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHovered),expression:"!isHovered"}],staticClass:"link",attrs:{"href":`${_vm.origin}${_vm.jumpLink}`}},[_c('img',{staticClass:"picture",attrs:{"src":_vm.pictureUrl,"alt":""}})]),_vm._v(" "),_c('div',{staticClass:"photo-duration"},[_vm._v(" "+_vm._s(_vm.videoDuration)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }