/* eslint-disable import/named */
import { getCurrentInstance } from 'vue';
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { ResponseBase } from './base';

import axiosInstance from './request';

/** *** Api  *** */
interface SendMobileCodeByBussinessRequest {
    phone: string;
    language?: string;
    platform: number;
    businessType: number;
    ztIdVerifyType?: number;
    ztIdVerifyCheckToken?: string;
}

interface SendMobileCodeByBussinessResponse extends ResponseBase {
    ztIdVerifyType?: number;
    ztUnionVerifyUrl?: string;
}

interface MobileCodeRequest {
    phone: string;
    smsCode: string;
    businessType: number;
}

interface MobileCodeResponse extends ResponseBase {
    sid: string;
    userId: number;
    authTokenValue: string;
    authTokenName: string;
}

export class PhoneApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        this.$axios = axiosInstance;
    }

    async getMobileCode(data: SendMobileCodeByBussinessRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<SendMobileCodeByBussinessResponse>('/rest/o/w/mobile/sendMobileCodeByBusiness', data, config);
        return res as SendMobileCodeByBussinessResponse;
    }

    async smsLogin(data: MobileCodeRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<MobileCodeResponse>('/rest/o/w/user/login/mobileCode', data, config);
        return res as MobileCodeResponse;
    }
}
