import Vue, { getCurrentInstance, inject, onMounted, onUnmounted } from 'vue'
import { tpLogger } from '@/common/tpLogger'
import Foryou from '@/pages/foryou/index.vue'
import Article from '@/pages/article/_content.vue'
import User from '@/pages/user/_userId.vue'
import Topic from '@/pages/topic/_content.vue'
import Discover from '@/pages/discover/_content.vue'
import Picture from '@/pages/picture/_photoId.vue'
import Video from '@/pages/video/_photoId.vue'
import Playlist from '@/pages/playlist/_albumId.vue'

const pagesNeedTOP = [Foryou, Article, User, Topic, Discover, Picture, Video, Playlist]
/*
* plugin
*/
const extend = (app, mixin) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push(mixin)
}

const extendComponents = (apps, mixin) => {
  apps.forEach(app => {
    extend(app, mixin)
  });
}

export default ({ app }, inject ) => {
  const {registerPageVisibleChange, unregisterPageVisibleChange,handleBeforeUnload, start10sTimer, start5sTimer, pauseTimers, clearTimers, resumeTimers} = tpLogger()
  // hook main app
  extend(app, {
    mounted() {
      registerPageVisibleChange()
      start5sTimer('global')
      start10sTimer('global')
    },

    beforeDestroy() {
      unregisterPageVisibleChange()
      clearTimers('global')
      handleBeforeUnload()
    },
  })
  
  // hook page component
  extendComponents(pagesNeedTOP, {
    
    mounted() {  
      this.routeName = this.getRouteBaseName()
      start5sTimer(this.routeName)
      start10sTimer(this.routeName)
      console.log('TOP', 'onMounted', this.routeName)
    },

    unmounted() {
      clearTimers(this.routeName)
      console.log('TOP', 'onUnmounted', this.routeName)
    },

    activated() {
      resumeTimers(this.routeName)
      console.log('TOP', 'onActivated', this.routeName)
    },

    deactivated() {
      pauseTimers(this.routeName)
      console.log('TOP', 'onDeactivated', this.routeName)
    },
  })
}